import { GetPropertiesRequestFilter } from "./get-properties-request-filter.model";

export class GetPropertiesRequest {

    public filter: GetPropertiesRequestFilter;

    constructor(init?: Partial<GetPropertiesRequest>) {
        Object.assign(this, init);
    }
}
