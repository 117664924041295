import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ViewRefHelperService {

    constructor(
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
    ) {}

    public dynamicallyInsertComponent<TComponent>(component: Type<TComponent>)
    : ComponentRef<TComponent> {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const wrapperRef = componentFactory.create(this.injector);

        this.applicationRef.attachView(wrapperRef.hostView);

        const domElem = (wrapperRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        return wrapperRef;
    }
}

