import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementPaymentCardModule } from "../user-settlement-payment-card/user-settlement-payment-card.module";
import { UserSettlementPaymentEditorModule } from "../user-settlement-payment-editor/user-settlement-payment-editor.module";
import { UserSettlementPaymentsComponent } from "./user-settlement-payments.component";

@NgModule({
    imports: [
        SharedModule,
        UserSettlementPaymentCardModule,
        UserSettlementPaymentEditorModule,
    ],
    declarations: [
        UserSettlementPaymentsComponent,
    ],
    exports: [
        UserSettlementPaymentsComponent,
    ],
})
export class UserSettlementPaymentsModule {}
