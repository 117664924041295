import { SortedResponse } from "./sorted-response.model";
import { PagedResponse } from "./paged-response.model";
import { SortingModel } from "./sorting-model.model";

export class PagedSortedResponse<T> implements PagedResponse, SortedResponse {

    public content: T[];
    public skip: number;
    public limit: number;
    public totalElements: number;
    public sortingModels: SortingModel[];

    constructor(init?: Partial<PagedSortedResponse<T>>) {
        Object.assign(this, init);
    }
}
