import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";

import { NavigationTab } from "../../../../shared/components/navigation-tabs/models/navigation-tab.model";
import { UserRolesStateService } from "../../../../core/roles/services/user-roles-state.service";
import { OptionsHttpService } from "../../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { RoomControlsFormService } from "./services/room-controls-form.service";
import { RoomControlsService } from "./services/room-controls.service";
import { Option } from "../../../../core/models/option.model";
import { RoomControlsApi } from "./models/room-controls-api.model";
import { RolePasses } from "../../../../core/roles/rules/roles-passes.class";

@Component({
    selector: "app-room-controls",
    templateUrl: "./room-controls.component.html",
    styleUrls: [ "./room-controls.component.scss" ],
    providers: [
        RoomControlsService,
    ]
})
export class RoomControlsComponent implements OnInit {

    @Input()
    public dormitories: string[];

    @Output()
    public addNew: EventEmitter<void>;
    @Output()
    public controlsReady: EventEmitter<RoomControlsApi>;
    @Output()
    public filterSubmit: EventEmitter<void>;
    @Output()
    public activeSubmit: EventEmitter<boolean>;

    public readonly genderOptions: Option<boolean>[];
    public readonly maxResidentsOptions: Option<number>[];
    public readonly sortByOptions: Option<string>[];

    public currentTab: NavigationTab;
    public form: FormGroup;
    public hasPassToAddRoom: boolean;
    public userHasPassToSeeBuildings: boolean;
    public stateOptions: LocalizedMessage[];
    public availabilityStatusOptions: LocalizedMessage[];
    public selectedAvailabilityStatusOption: string;
    public minDate: string;

    constructor(
        private roomControlsFormService: RoomControlsFormService,
        private roomControlsService: RoomControlsService,
        private userRolesStateService: UserRolesStateService,
        private optionsHttpService: OptionsHttpService,
        private fb: FormBuilder
    ) {
        this.addNew = new EventEmitter<void>();
        this.controlsReady = new EventEmitter<RoomControlsApi>();
        this.filterSubmit = new EventEmitter<void>();
        this.activeSubmit = new EventEmitter<boolean>();

        this.genderOptions = this.roomControlsService.genderOptions;
        this.maxResidentsOptions = this.roomControlsService.maxResidentsOptions;
        this.sortByOptions = this.roomControlsService.sortByOptions;

        this.minDate = new Date().toISOString().split("T")[0];

        this.initForm();
        this.subscribeOnHasPass();
        this.loadStateOptions();
        this.loadAvailabilityStatusOptions();
    }

    public ngOnInit()
    : void {
        this.onControlsReady();
    }

    private get api()
    : RoomControlsApi {
        return new RoomControlsApi({
           getFilter: this.getFilter.bind(this)
        });
    }

    public onAddNew()
    : void {
        this.addNew.emit();
    }

    public onFilterSubmit()
    : void {
        return this.filterSubmit.emit();
    }

    private getFilter()
    : any {
        const filter = {
            filters: { }
        };

        const { filterDate, dormitory, maxResidents, state, status, sortBy } = this.form.value;

        if (filterDate !== "null") {
            filter.filters["availabilityDate"] = filterDate;
        }

        if (maxResidents && maxResidents !== "null") {
            filter.filters["maxResidents"] = +maxResidents;
        }

        if (dormitory && dormitory !== "null") {
            filter.filters["dormitory"] = dormitory;
        }

        if (state !== "null") {
            filter.filters["status"] = state;
        }

        if (status !== "null") {
            filter.filters["availability"] = status;
        }

        if (sortBy && sortBy !== "null") {
            filter["sort"] = sortBy;
        }

        return filter;
    }

    private onControlsReady()
    : void {
        this.controlsReady.emit(this.api);
    }

    private initForm()
    : void {
        this.form = this.roomControlsFormService.buildForm();
    }

    private subscribeOnHasPass()
    : void {
        this.userRolesStateService.getIsInRole(RolePasses.standardAdministrationPass)
            .subscribe((value) => this.userHasPassToSeeBuildings = value);

        this.userRolesStateService.getIsInRole(RolePasses.canEditRoomsPass)
            .subscribe(value => this.hasPassToAddRoom = value);
    }

    private loadStateOptions()
    : void {
        this.optionsHttpService.getRoomStatusOptions()
            .subscribe(value => this.stateOptions = value);
    }

    private loadAvailabilityStatusOptions()
        : void {
        this.optionsHttpService.getRoomAvailabilityStatusOptions()
            .subscribe(value => this.availabilityStatusOptions = value);
    }

    public changeActive($event: boolean)
    : void {
        this.activeSubmit.emit($event);
    }
}
