import { NgModule } from "@angular/core";
import { MatTabsModule } from "@angular/material";

import { RoomRequestModalViewComponent } from "./components/room-request-modal/components/room-request-modal-view.component";
import { RoomRequestBoardContainerComponent } from "./containers/room-request-board-container.component";
import { RoomRequestCardComponent } from "./components/room-request-card/room-request-card.component";
import { PayNowModalComponent } from "./components/pay-now-modal/pay-now-modal.component";
import { RoomRequestBoardComponent } from "./components/room-request-board.component";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../core/pipes/pipes.module";
import { RoomRequestModalComponent } from "./components/room-request-modal/containers/room-request-modal.component";
import { MigrationRequestModalComponent } from "./components/migration-request-modal/migration-request-modal.component";
import { SwitchRoomRequestComponent } from "./components/switch-room-request/containers/switch-room-request.component";
import { SwitchRoomRequestViewComponent } from "./components/switch-room-request/components/switch-room-request-view.component";
import { FreeRoomRequestComponent } from "./components/free-room-request/containers/free-room-request.component";
import { FreeRoomRequestViewComponent } from "./components/free-room-request/components/free-room-request-view.component";
import { RoomThumbModule } from "../room-thumb/room-thumb.module";
import { MigrationRequestCardModule } from "../migration-request-card/migration-request-card.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";

@NgModule({
    declarations: [
        FreeRoomRequestComponent,
        FreeRoomRequestViewComponent,
        MigrationRequestModalComponent,
        PayNowModalComponent,
        RoomRequestCardComponent,
        RoomRequestBoardComponent,
        RoomRequestBoardContainerComponent,
        RoomRequestModalComponent,
        RoomRequestModalViewComponent,
        SwitchRoomRequestComponent,
        SwitchRoomRequestViewComponent,
    ],
    imports: [
        MigrationRequestCardModule,
        PipesModule,
        RoomThumbModule,
        SharedModule,
        MatTabsModule,
        PageLoaderModule,
    ],
    exports: [
        RoomRequestBoardContainerComponent,
        PayNowModalComponent,
    ],
})
export class RoomRequestBoardModule { }
