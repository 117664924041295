import { Injectable } from "@angular/core";

import { Building } from "../../../../../shared/models/building.model";
import { IFactory } from "../../../../../core/models/factory.interface";

@Injectable()
export class DefaultBuildingFactoryService implements IFactory<Building> {

    public create()
    : Building {
        return new Building({
            active: true,
            name: "",
        });
    }
}
