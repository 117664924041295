import { Component, EventEmitter, Input, Output } from "@angular/core";

import { User } from "../../../../shared/models/user.model";

@Component({
    selector: "app-resident-card",
    templateUrl: "./resident-card.component.html",
    styleUrls: [ "./resident-card.component.scss" ],
})
export class ResidentCardComponent {

    @Input()
    public removable: boolean;
    @Input()
    public resident: User;

    @Output()
    public remove: EventEmitter<void>;

    constructor() {
        this.remove = new EventEmitter<void>();
    }

    public onRemove()
    : void {
        this.remove.emit();
    }
}
