import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Component, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";

import { LogsRequestFilter } from "../../../../shared/http-services/logs/models/logs-request-filter.model";
import { OptionsHttpService } from "../../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { LogsFilterFormService } from "./services/logs-filter-form.service";
import { NamedValue } from "../../../../shared/models/named-value.model";
import { LogsFilterConstants } from "./classes/logs-filter-constants.class";

@Component({
    selector: "app-logs-filter",
    styleUrls: [ "./logs-filter.component.scss", ],
    templateUrl: "./logs-filter.component.html",
    providers: [
        LogsFilterFormService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LogsFilterComponent,
            multi: true,
        }
    ]
})
export class LogsFilterComponent implements OnDestroy, ControlValueAccessor {

    public readonly maxDate: string;

    public rolesOptions$: Observable<LocalizedMessage[]>;

    public form: FormGroup;
    public timeOptions: NamedValue<number>[];
    public value: LogsRequestFilter;

    private onChange: Function;
    private onTouched: Function;

    constructor(
        private logsFilterFormService: LogsFilterFormService,
        private optionsHttpService: OptionsHttpService,
    ) {
        this.maxDate = new Date().toISOString();

        this.initOptions();
    }

    public ngOnDestroy()
    : void { }

    public registerOnChange(fn: any)
    : void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any)
    : void {
        this.onTouched = fn;
    }

    public writeValue(value: LogsRequestFilter)
    : void {
        if (!value) {
            return ;
        }

        this.value = value;

        this.initForm(value);
        this.subscribeOnFormChange();
    }

    private initForm(value: LogsRequestFilter)
    : void {
        this.form = this.logsFilterFormService.buildForm(value);
    }

    private initOptions()
    : void {
        this.rolesOptions$ = this.optionsHttpService.getRoleOptions();

        this.timeOptions = LogsFilterConstants.timeOptions;
    }

    private subscribeOnFormChange()
    : void {
        this.form.valueChanges
            .pipe(
                untilDestroyed(this),
            )
            .subscribe(() => {
                const value = this.logsFilterFormService.mapToModel(this.form);
                this.onChange(value);
            });

        this.subscribeOnLoggedAfterControlChanges();
        this.subscribeOnLoggedBeforeControlChanges();
    }

    private subscribeOnLoggedBeforeControlChanges()
    : void {
        this.form.controls.loggedBefore.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(val => {
                const timeControl = this.form.controls.loggedBeforeTime;

                if (val && !timeControl.value) {
                    timeControl.patchValue(LogsFilterConstants.timeOptions[LogsFilterConstants.timeOptions.length - 1].value);
                }
            });
    }

    private subscribeOnLoggedAfterControlChanges()
    : void {
        this.form.controls.loggedAfter.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(val => {
                const timeControl = this.form.controls.loggedAfterTime;

                if (val && !timeControl.value) {
                    timeControl.patchValue(LogsFilterConstants.timeOptions[0].value);
                }
            });
    }
}
