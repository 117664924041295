import { Component, Input } from "@angular/core";

import { NamedValue } from "../../../../shared/models/named-value.model";

@Component({
    selector: "app-price-list",
    templateUrl: "./price-list.component.html",
    styleUrls: ["./price-list.component.scss"],
})
export class PriceListComponent {

    @Input()
    public prices: NamedValue<string>[];
}
