import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RoomRequest } from "../../shared/http-services/models/room-request.model";
import { Payment } from "../../shared/http-services/payments/models/payment.model";
import { UserSettlementPaymentCardService } from "./services/user-settlement-payment-card.service";

@Component({
    selector: "app-user-settlement-payment-card",
    templateUrl: "user-settlement-payment-card.component.html",
    styleUrls: ["user-settlement-payment-card.component.scss"],
    providers: [
        UserSettlementPaymentCardService,
    ]
})
export class UserSettlementPaymentCardComponent {

    @Input()
    public payment: Payment;
    @Input()
    public roomRequest: RoomRequest;

    @Output()
    public onEdit: EventEmitter<Payment>;
    @Output()
    public onCanceled: EventEmitter<void>;
    @Output()
    public onModified: EventEmitter<void>;

    constructor(
        private userSettlementPaymentCardService: UserSettlementPaymentCardService,
    ) {
        this.onEdit = new EventEmitter<Payment>();
        this.onCanceled = new EventEmitter<void>();
        this.onModified = new EventEmitter<void>();
    }

    public async edit()
    : Promise<void> {
        await this.onEdit.emit(this.payment);
    }

    public async cancel()
    : Promise<void> {
        await this.userSettlementPaymentCardService.cancelPayment(this.payment.id);
        this.onCanceled.emit();
    }

    public async onMarkAsPaid()
    : Promise<void> {
        await this.userSettlementPaymentCardService.markAsPaid(this.payment.id);
        this.onModified.emit();
    }

    public async onMarkAsNotPaid()
    : Promise<void> {
        await this.userSettlementPaymentCardService.markAsNotPaid(this.payment.id);
        this.onModified.emit();
    }
}
