import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PrivilegesEditorApi } from "../../pages/dashboard/components/privileges-editor/models/privileges-editor-api.model";
import { ExtendedPrivilege } from "../../shared/http-services/client-privileges/models/extended-privilege.model";
import { WrappedError } from "../../shared/http-services/models/wrapped-error.model";
import { OptionsHttpService } from "../../shared/http-services/options/options-http.service";
import { Privilege } from "../../shared/http-services/privileges/models/privilege.model";
import { PrivilegesHttpService } from "../../shared/http-services/privileges/privileges-http.service";
import { LocalizedMessage } from "../../shared/models/privilege-status.model";
import { PrivilegeFactoryService } from "../../shared/services/privileges/privilege-factory.service";

@Component({
    selector: "app-user-privilege-card",
    templateUrl: "user-privilege-card.component.html",
    styleUrls: [
        "user-privilege-card.component.scss",
    ]
})
export class UserPrivilegeCardComponent {

    @Input()
    public email: string;
    @Input()
    public privileges: ExtendedPrivilege[];

    @Output()
    public updated: EventEmitter<void>;

    private editorApi: PrivilegesEditorApi;
    private privilegeOptions: LocalizedMessage[];

    constructor(
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
        private privilegeFactoryService: PrivilegeFactoryService,
        private privilegesHttpService: PrivilegesHttpService,
    ) {
        this.updated = new EventEmitter<void>();

        this.loadPrivilegeOptions();
    }

    public onAdd()
    : void {
        this.editorApi.open({
            edit: false,
            nameOptions: this.privilegeOptions,
            initialValue: this.privilegeFactoryService.create(),
            onSave: this.addNewPrivilege.bind(this),
        });
    }

    public onEditorReady(api: PrivilegesEditorApi)
    : void {
        this.editorApi = api;
    }

    public removePrivilege(privilege: ExtendedPrivilege)
    : void {
        const confirmation = confirm(`Ви впевнені що хочете видалити пільгу "${privilege.privilegeName}"?`);

        if (!confirmation) {
            return;
        }

        this.privilegesHttpService.delete(this.email, privilege.id)
            .subscribe(
                () => {
                    this.toastrService.success("Пільгу успішно видалено");
                    this.editorApi.close();
                    this.updated.emit();
                },
                (error: WrappedError) => this.toastrService.error(error.message),
            );
    }

    private loadPrivilegeOptions()
    : void {
        this.optionsHttpService.getPrivilegeOptions()
            .subscribe(
                res => this.privilegeOptions = res,
                error => this.toastrService.error(error.message)
            );
    }

    private addNewPrivilege(privilege: Privilege)
    : void {
        this.privilegesHttpService.add(this.email, privilege)
            .subscribe(
                () => {
                    this.toastrService.success("Пільгу успішно додано");
                    this.editorApi.close();
                    this.updated.emit();
                },
                (error: WrappedError) => this.toastrService.error(error.message),
            );
    }
}

