import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
export class DateHelperService {

    public static addHours(date: string | Date, hours: number)
    : string {
        const dateTime = moment(date).add(hours, "hours").toISOString(true);
        return this.formatDateTimeIso(dateTime);
    }

    public static getHours(date: string | Date)
    : number {
        return moment(date).get("hours");
    }

    public static formatDate(date: string | Date)
    : string {
        return moment(date).format("DD/MM/YYYY");
    }

    public static formatDateIso(date: string | Date)
    : string {
        return moment(date).format("YYYY-MM-DD");
    }

    public static formatDateTimeIso(date: string | Date)
    : string {
        return moment(date).format("YYYY-MM-DDTHH:mm:ss");
    }

    public static formatLocalDateToIso(date: string)
    : string {
        const parsedDate = moment(date, "DD/MM/YYYY").toDate();
        return this.formatDateIso(parsedDate);
    }

    public static formatTime(date: string | Date)
    : string {
        return moment(date).format("HH:mm:ss");
    }
}
