import { ColDef, GridOptions } from "ag-grid-community";
import { Injectable } from "@angular/core";

import { AgGridHelperService } from "../../../shared/services/helpers/ag-grid/ag-grid-helper.service";
import { AgGridOptionsService } from "../../../shared/services/tables/ag-grid-options.service";
import { ValueFormatterService } from "../../../shared/services/helpers/ag-grid/value-formatter.service";

@Injectable()
export class LogsTableService {

    constructor(
        private agGridHelperService: AgGridHelperService,
        private agGridOptionsService: AgGridOptionsService,
        private valueFormatterService: ValueFormatterService,
    ) {}

    public getGridOptions()
    : GridOptions {
        return this.agGridOptionsService.getGridOptions({
            columnDefs: this.getColumnDefs(),
            onGridSizeChanged: this.agGridHelperService.sizeColumnsToFit,
            rowSelection: "none",
            pagination: false,
            suppressHorizontalScroll: false,
            suppressNoRowsOverlay: true,
        });
    }

    private getColumnDefs()
    : ColDef[] {
        return <ColDef[]>[
            {
                headerName: "ID",
                field: "id",
                width: 50,
                sortable: false,
            },
            {
                field: "logLevel",
                headerName: "Рівень",
                sortable: true,
            },
            {
                field: "logTime",
                headerName: "Час",
                valueFormatter: this.valueFormatterService.dateTimeFormatter(),
                sortable: true,
            },
            {
                field: "systemType",
                headerName: "Системa",
                width: 120,
                sortable: true,
            },
            {
                field: "text",
                headerName: "Текст",
                sortable: false,
            },
            {
                field: "userEmail",
                headerName: "Електронна пошта",
                sortable: true,
            },
            {
                field: "userRoles",
                headerName: "Ролі",
                sortable: false,
            },
        ];
    }
}
