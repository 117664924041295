import { GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { finalize } from "rxjs/operators";

import { DormitoryReportItem } from "../../../../shared/http-services/reports/models/dormitory-report-item.model";
import { ReportsHttpService } from "../../../../shared/http-services/reports/reports-http.service";
import { DormitoriesReportTableService } from "./services/dormitories-report-table.service";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";

@Component({
    selector: "app-dormitories-report",
    templateUrl: "./dormitories-report.component.html",
    styleUrls: [ "./dormitories-report.component.scss" ],
    providers: [
        DormitoriesReportTableService,
    ]
})
export class DormitoriesReportComponent implements OnChanges {

    @Input()
    public date: string | Date;

    public dormitoriesReport: DormitoryReportItem[];
    public gridOptions: GridOptions;
    public loading: boolean;

    private gridApi: GridApi;

    constructor(
        private dormitoriesReportTableService: DormitoriesReportTableService,
        private reportsHttpService: ReportsHttpService,
        private toastrService: ToastrService,
    ) {
        this.gridOptions = this.dormitoriesReportTableService.getGridOptions();
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.date && this.date) {
            this.onDateSelected();
        }
    }

    public onDateSelected()
    : void {
        this.loadReport();
    }

    public onDownload()
    : void {
        this.gridApi.exportDataAsCsv({
            customHeader: `Звіт Гуртожитки за ${DateHelperService.formatDate(this.date)}`,
            fileName: `Звіт. Гуртожитки ${DateHelperService.formatDate(this.date)}.csv`,
        });
    }

    public onGridReady(event: GridReadyEvent)
    : void {
        this.gridApi = event.api;
    }

    private loadReport()
    : void {
        this.onLoadingStart();

        this.reportsHttpService.getDormitories(DateHelperService.formatDateIso(this.date))
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(
                res => this.dormitoriesReport = res,
                err => this.toastrService.error(err.message),
            );
    }

    private onLoadingStart()
    : void {
        this.loading = true;

        if (!this.gridApi) {
            return;
        }

        this.gridApi.showLoadingOverlay();
    }
}
