import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserDetailsMode } from "../../features/user-details-modes-switch/models/user-details-mode.enum";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { UsersHttpService } from "../../shared/http-services/users/users-http.service";

@Component({
    selector: "app-user-details",
    templateUrl: "user-details.component.html",
    styleUrls: ["user-details.component.scss"],
})
export class UserDetailsComponent {

    public user: UserDetails;
    public mode: UserDetailsMode;
    public modes: typeof UserDetailsMode;

    private userId: number;

    constructor(
        private activatedRoute: ActivatedRoute,
        private usersHttpService: UsersHttpService,
        private toastrService: ToastrService,
    ) {
        this.modes = UserDetailsMode;

        this.initUser();
    }

    public onUpdated()
    : void {
        this.loadUser();
    }

    public async loadUser()
    : Promise<void> {
        try {
            this.user = await this.usersHttpService.getUser(this.userId).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
        }
    }

    private async initUser()
    : Promise<void> {
        this.userId = this.activatedRoute.snapshot.params.id;

        await this.loadUser();
    }
}
