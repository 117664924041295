import { FormBuilder, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import { LogsRequestFilter } from "../../../../../shared/http-services/logs/models/logs-request-filter.model";
import { DateHelperService } from "../../../../../shared/services/helpers/date-helper.service";
import { FilterHelperService } from "../../../../../shared/services/helpers/filter-helper.service";

@Injectable()
export class LogsFilterFormService {

    constructor(
        private filterHelperService: FilterHelperService,
        private formBuilder: FormBuilder,
    ) {}

    public buildForm(filter: LogsRequestFilter)
    : FormGroup {
        return this.formBuilder.group({
            logText: filter.logText,
            loggedAfter: filter.loggedAfter,
            loggedAfterTime: this.getHours(filter.loggedAfter),
            loggedBefore: filter.loggedBefore,
            loggedBeforeTime: this.getHours(filter.loggedBefore),
            userEmail: filter.userEmail,
            userRole: filter.userRole,
        });
    }

    public mapToModel(form: FormGroup)
    : LogsRequestFilter {
        const { logText, loggedAfter, loggedAfterTime, loggedBefore, loggedBeforeTime, userEmail, userRole } = form.value;

        const loggedAfterDateTime = this.formatDateTime(loggedAfter, loggedAfterTime);
        const loggedBeforeDateTime = this.formatDateTime(loggedBefore, loggedBeforeTime);

        const filter = new LogsRequestFilter({
            logText, userEmail, userRole,
            loggedAfter: loggedAfterDateTime,
            loggedBefore: loggedBeforeDateTime,
        });

        return this.filterHelperService.filterEmptyFields(filter);
    }

    private getHours(date: string | Date)
    : number {
        if (!date) {
            return null;
        }

        return DateHelperService.getHours(date);
    }

    private formatDateTime(date: string, hours = 0)
    : string {
        if (!date) {
            return null;
        }

        date = DateHelperService.formatDateIso(date);
        return DateHelperService.addHours(date, hours);
    }
}
