import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { MigrationsListComponent } from "./migrations-list.component";
import { MigrationRequestCardModule } from "../migration-request-card/migration-request-card.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";

@NgModule({
    imports: [
        SharedModule,
        MigrationRequestCardModule,
        PageLoaderModule,
    ],
    declarations: [
        MigrationsListComponent,
    ],
    exports: [
        MigrationsListComponent,
    ],
})
export class MigrationsListModule {}
