import { ModuleWithProviders, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { TokenAppenderInterceptor } from "./interceptors/token-appender.interceptor";
import { AuthenticationGuard } from "./guards/authentication.guard";

@NgModule()
export class AuthModule {

    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [
                AuthenticationGuard,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: TokenAppenderInterceptor,
                    multi: true
                },
            ],
        };
    }
}
