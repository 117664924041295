import { Component, EventEmitter, Output, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "app-room-request-modal-view",
    templateUrl: "./room-request-modal-view.component.html",
    styleUrls: [ "./room-request-modal-view.component.scss", ],
    encapsulation: ViewEncapsulation.None,
})
export class RoomRequestModalViewComponent {

    @Output()
    public save: EventEmitter<void>;

    public acceptConditions: boolean;

    constructor() {
        this.save = new EventEmitter<void>();
    }

    public onSave()
    : void {
        if (!this.acceptConditions) {
            return ;
        }

        this.save.emit();
    }
}
