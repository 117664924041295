import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";

import { Period } from "../../../../../shared/http-services/periods/models/period.model";
import { DateHelperService } from "../../../../../shared/services/helpers/date-helper.service";

@Injectable()
export class PeriodEditorFormService {

    constructor(
        private formBuilder: FormBuilder
    ) {}

    public buildForm(initialValue = new Period())
    : FormGroup {
        return this.formBuilder.group({
            id: initialValue.id,
            startDate: [ initialValue.startDate, Validators.required ],
            endDate: [ initialValue.endDate, Validators.required],
            masterStartDate: [ initialValue.masterStartDate, Validators.required ],
            migrationEnabled: initialValue.migrationEnabled || false,
            settlementEnabled: initialValue.settlementEnabled || false,
            settlementProlongationEnabled: initialValue.settlementProlongationEnabled || false,
        });
    }

    public mapToModel(form: FormGroup)
    : Period {
        const { startDate, endDate, masterStartDate, migrationEnabled, settlementEnabled, settlementProlongationEnabled, id } = form.value;

        return new Period({
            startDate: DateHelperService.formatDateIso(startDate),
            endDate: DateHelperService.formatDateIso(endDate),
            masterStartDate: DateHelperService.formatDateIso(masterStartDate),
            id,
            migrationEnabled,
            settlementEnabled,
            settlementProlongationEnabled
        });
    }
}
