import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";

import { FacultiesDegreeReport } from "../../../../shared/http-services/reports/models/faculties-degrees-report.model";
import { DegreesReport } from "../../../../shared/http-services/reports/models/degrees-report.model";
import { ColumnMeta } from "../../../../shared/http-services/reports/models/column-meta.model";
import { DegreesReportTableService } from "../degrees/services/degrees-report-table.service";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";

@Component({
    selector: "app-degree-report",
    templateUrl: "./degree-report.component.html",
    styleUrls: [ "./degree-report.component.scss" ],
    providers: [
        DegreesReportTableService
    ],
    encapsulation: ViewEncapsulation.None,
})
export class DegreeReportComponent implements OnChanges {

    @Input()
    public columnsMeta: ColumnMeta[];
    @Input()
    public date: string | Date;
    @Input()
    public loading: boolean;
    @Input()
    public report: FacultiesDegreeReport;

    public gridApi: GridApi;
    public gridOptions: GridOptions;

    constructor(
       private degreesReportTableService: DegreesReportTableService,
    ) {}

    public get reportName()
    : string {
        return this.report.dormitoryName;
    }

    public get tableData()
    : DegreesReport[] {
        return this.report.degrees;
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.columnsMeta && this.columnsMeta) {
            this.initTable();
        }

        if (changes.loading && this.loading) {
            this.showLoadingOverlay();
        }
    }

    public onDownload()
    : void {
        this.gridApi.exportDataAsCsv({
            customHeader: `Звіт. ${this.reportName} за ${DateHelperService.formatDate(this.date)}`,
            fileName: `Звіт. ${this.reportName} ${DateHelperService.formatDate(this.date)}.csv`,
        });
    }

    public onGridReady(event: GridReadyEvent)
    : void {
        this.gridApi = event.api;
    }

    private initTable()
    : void {
        this.gridOptions = this.degreesReportTableService.getGridOptions(this.columnsMeta);
    }

    private showLoadingOverlay()
    : void {
        if (!this.gridApi) {
            return ;
        }

        this.gridApi.showLoadingOverlay();
    }
}
