import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MigrationRequestHttpService } from "../../../shared/http-services/migration-request/migration-request-http.service";
import { MigrationRequestCase } from "../models/migration-request-case.model";

@Injectable({
    providedIn: "root"
})
export class MigrationStatusService {

    constructor(
        private migrationRequestHttpService: MigrationRequestHttpService,
        private toastrService: ToastrService,
    ) {}

    public async onApproveMigration(migrationCase: MigrationRequestCase)
    : Promise<void> {
        if (!confirm("Ви впевнені що хочете підтвердити запит на переселення користувача(-ки) " + migrationCase.initiator.username + "?")) {
            return;
        }

        try {
            await this.migrationRequestHttpService.approveMigrationRequest(migrationCase.id).toPromise();
            this.toastrService.success(`Запит на переселення користувача(-ки) ${migrationCase.initiator.username} успішно підтверджено`);
        } catch (error) {
            this.toastrService.error(error.message);
        }
    }

    public async onRejectMigration(migrationCase: MigrationRequestCase)
    : Promise<void> {
        if (!confirm("Ви впевнені що хочете відхилити запит на переселення користувача(-ки) " + migrationCase.initiator.username + "?")) {
            return;
        }

        try {
            await this.migrationRequestHttpService.rejectMigrationRequest(migrationCase.id).toPromise();
            this.toastrService.success(`Запит на переселення користувача(-ки) ${migrationCase.initiator.username} успішно відхилено`);
        } catch (error) {
            this.toastrService.error(error.message);
        }
    }
}
