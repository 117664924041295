import { PeriodEditorConfig } from "./period-editor-config.model";

export class PeriodEditorApi {

    public open: (config: PeriodEditorConfig) => void;

    constructor(init?: Partial<PeriodEditorApi>) {
        Object.assign(this, init);
    }
}
