import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DormitoriesReportComponent } from "./components/dormitories/dormitories-report.component";
import { DegreesReportComponent } from "./components/degrees/degrees-report.component";
import { ReportsComponent } from "./components/reports.component";
import { SharedModule } from "../../shared/shared.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { GeneralReportComponent } from "./components/general/general-report.component";
import { DegreeReportComponent } from "./components/degree-report/degree-report.component";

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        PageLoaderModule,
    ],
    declarations: [
        DegreesReportComponent,
        DegreeReportComponent,
        GeneralReportComponent,
        DormitoriesReportComponent,
        ReportsComponent,
    ],
    exports: [
        ReportsComponent,
    ]
})
export class ReportsModule { }
