import { AgGridEvent, ColDef, GridOptions } from "ag-grid-community";
import { Injectable } from "@angular/core";

import { AgGridHelperService } from "../../../../../shared/services/helpers/ag-grid/ag-grid-helper.service";
import { AgGridOptionsService } from "../../../../../shared/services/tables/ag-grid-options.service";
import { ValueFormatterService } from "../../../../../shared/services/helpers/ag-grid/value-formatter.service";

@Injectable()
export class GeneralReportTableService {

    constructor(
        private agGridHelperService: AgGridHelperService,
        private agGridOptionsService: AgGridOptionsService,
        private valueFormatterService: ValueFormatterService,
    ) {}

    public getGridOptions()
    : GridOptions {
        return this.agGridOptionsService.getGridOptions({
            columnDefs: this.getColumnDefs(),
            onGridSizeChanged: this.adjustGridColumns.bind(this),
            onModelUpdated: this.adjustGridColumns.bind(this),
            onGridReady: this.adjustGridColumns.bind(this),
            rowSelection: "none",
            enableSorting: false,
            pagination: true,
            suppressHorizontalScroll: false,
            enableCellTextSelection: true,
        });
    }

    private getColumnDefs()
    : ColDef[] {
        return <ColDef[]>[
            {
                minWidth: 150,
                field: "room_number",
                headerName: "№ кімнати",
            },
            {
                headerName: "ПІБ",
                field: "username",
            },
            {
                headerName: "Пошта",
                field: "email",
            },
            {
                field: "degree",
                headerName: "Рік навчання",
            },
            {
                field: "faculty",
                headerName: "Факультет",
            },
            {
                minWidth: 150,
                field: "paidPrice",
                headerName: "Сплачено",
                valueFormatter: this.valueFormatterService.coinsToUahWLabelFormatter(),
            },
            {
                minWidth: 150,
                field: "paidToDate",
                headerName: "Сплачено до",
                valueFormatter: (params) => {
                    if (params.value == null) {
                        return "Немає оплати";
                    } else {
                        this.valueFormatterService.dateFormatter();
                    }
                },
            },
            {
                minWidth: 150,
                field: "dormitory",
                headerName: "Гуртожиток",
            },
            {
                width: 300,
                field: "privileges",
                headerName: "Пільги",
                valueFormatter: (params) => params.value.join(", "),
            },
        ];
    }

    private adjustGridColumns(event: AgGridEvent)
    : void {
        setTimeout(() => this.agGridHelperService.fitColumnsSizeToContent(event), 100);
    }
}
