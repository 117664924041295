import { NgModule } from "@angular/core";
import { PaymentsModule } from "../../pages/payments/payments.module";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementPaymentEditorComponent } from "./user-settlement-payment-editor.component";

@NgModule({
    imports: [
        SharedModule,
        PaymentsModule,
    ],
    declarations: [
        UserSettlementPaymentEditorComponent,
    ],
    exports: [
        UserSettlementPaymentEditorComponent,
    ]
})
export class UserSettlementPaymentEditorModule {}
