import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { environment } from "../../../environments/environment";
import { ResponseError } from "./models/response-error.model";
import { WrappedError } from "./models/wrapped-error.model";

export class AbstractHttpService {

    public get apiUrl()
    : string {
        return environment.apiUrl;
    }

    public handleError(response: HttpErrorResponse)
    : Observable<never> {
        return throwError(this.wrapError(response));
    }

    public buildFormData(obj: { [key: string]: any } | null)
    : FormData {
        const formData = new FormData();

        if (obj !== null) {
            Object
                .keys(obj)
                .forEach((key) => formData.append(key, obj[key]));
        }

        return formData;
    }

    public buildHttpParams(obj: { [key: string]: any } | null)
    : HttpParams {
        let httpParams = new HttpParams();

        if (obj !== null) {
            Object
                .keys(obj)
                .filter((key) => !this.isEmpty(obj[key]))
                .forEach((key) => httpParams = httpParams.set(key, obj[key]));
        }

        return httpParams;
    }

    public buildFlatHttpParams(obj: { [key: string]: any })
    : HttpParams {
        const flattedObject = this.flatObject(obj);
        return this.buildHttpParams(flattedObject);
    }

    private flatObject(obj: { [key: string]: any })
    : { [key: string]: any } {
        const flattedObject = {};

        this.addToFlatObject(obj, flattedObject);

        return flattedObject;
    }

    private addToFlatObject(
        subObj: { [key: string]: any },
        flattedObj: { [key: string]: any },
        parentKey?: string
    )
    : void {
        if (!subObj) {
            return ;
        }

        Object.keys(subObj)
            .forEach((key) => {
                const newKey = parentKey ?
                    parentKey + "." + key
                    : key;

                if (typeof subObj[key] === "object") {
                    this.addToFlatObject(subObj[key], flattedObj, newKey);
                } else {
                    flattedObj[newKey] = subObj[key];
                }
            });
    }

    public wrapError(response: HttpErrorResponse)
    : WrappedError {
        const responseError: ResponseError = (response && response.error)
            ? response.error
            : null;

        return new WrappedError({ response, responseError });
    }

    private isEmpty(value: any)
    : boolean {
        return  value === null  || typeof value === "undefined";
    }
}
