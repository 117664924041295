import { NouisliderModule } from "ng2-nouislider";
import { NgModule } from "@angular/core";

import { PaymentsMapComponent } from "./components/payments-map/payments-map.component";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { PaymentListComponent } from "./components/payment-list/payment-list.component";
import { PriceListComponent } from "./components/price-list/price-list.component";
import { PaymentsViewComponent } from "./views/payments-view.component";
import { PaymentsComponent } from "./containers/payments.component";
import { SharedModule } from "../../shared/shared.module";
import { PaymentItemComponent } from "./components/payment-item/payment-item.component";
import { RoomRequestBoardModule } from "../../features/room-request-board/room-request-board.module";
import { PipesModule } from "../../core/pipes/pipes.module";

@NgModule({
    imports: [
        SharedModule,
        PageLoaderModule,
        NouisliderModule,
        RoomRequestBoardModule,
        PipesModule,
    ],
    declarations: [
        PaymentsComponent,
        PaymentListComponent,
        PaymentsMapComponent,
        PaymentsViewComponent,
        PriceListComponent,
        PaymentItemComponent,
    ],
    exports: [
        PriceListComponent
    ]
})
export class PaymentsModule {
}
