import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sanitizeHtml"
})
export class SanitizeHtmlPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {}

    public transform(html: string)
    : SafeHtml | null {
        if (!html) {
            return null;
        }

        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
