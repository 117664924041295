import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";

@Injectable()
export class UserRolesCardService {

    constructor(
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
    ) {}

    public async loadRoleOptions()
    : Promise<LocalizedMessage[]> {

        let roleOptions = [];

        try {
            roleOptions = await this.optionsHttpService.getRoleOptions().toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
        }

        return roleOptions;
    }
}
