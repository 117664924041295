import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DownloadButtonComponent } from "./components/download-button/download-button.component";
import { CrossTickComponent } from "./components/cross-tick/cross-tick.component";
import { RefreshButtonComponent } from "./components/refresh-button/refresh-button.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CrossTickComponent,
        DownloadButtonComponent,
        RefreshButtonComponent,
    ],
    exports: [
        CrossTickComponent,
        DownloadButtonComponent,
        RefreshButtonComponent,
    ]
})
export class LayoutElementsModule { }
