import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { FormGroup } from "@angular/forms";

import { Privilege } from "../../../../shared/http-services/privileges/models/privilege.model";
import { PrivilegesEditorConfig } from "./models/privileges-editor-config.model";
import { PrivilegesEditorApi } from "./models/privileges-editor-api.model";
import { PrivilegesEditorFormService } from "./services/privileges-editor-form.service";
import { AbstractFormBasedComponent } from "../../../../core/components/abstract-form-based.component";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";

@Component({
    selector: "app-privileges-editor",
    templateUrl: "./privileges-editor.component.html",
    styleUrls: [ "./privileges-editor.component.scss", ],
    providers: [
        PrivilegesEditorFormService,
    ],
})
export class PrivilegesEditorComponent extends AbstractFormBasedComponent implements OnInit, PrivilegesEditorConfig {

    @ViewChild("modal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public onEditorReady: EventEmitter<PrivilegesEditorApi>;

    public edit: boolean;
    public form: FormGroup;
    public initialValue: Privilege;
    public nameOptions: LocalizedMessage[];
    public onSave: (value: Privilege) => void;
    public currentYear: number;

    constructor(
        private privilegesEditorFormService: PrivilegesEditorFormService,
    ) {
        super();

        this.onEditorReady = new EventEmitter<PrivilegesEditorApi>();
        this.currentYear = new Date().getFullYear();
    }

    public ngOnInit()
    : void {
        this.onEditorReady.emit(this.api);
    }

    public get api()
    : PrivilegesEditorApi {
        return new PrivilegesEditorApi({
            close: this.onClose.bind(this),
            open: this.open.bind(this),
        });
    }

    public get isFormValid()
    : boolean {
        return this.form.valid;
    }

    public get title()
    : string {
        return this.edit
            ? "Редагувати пільгу"
            : "Створити пільгу";
    }

    public get updatedPrivilege()
    : Privilege {
        return this.privilegesEditorFormService.mapToModel(this.form);
    }

    public onClose()
    : void {
        this.modalComponent.close();
    }

    public open(config: PrivilegesEditorConfig)
    : void {
        Object.assign(this, config);

        this.initForm();
        this.modalComponent.open();
    }

    public save()
    : void {
        this.validateForm();

        if (!this.isFormValid) {
            return ;
        }

        this.onSave(this.updatedPrivilege);
    }

    private initForm()
    : void {
        this.form = this.privilegesEditorFormService.buildForm(this.initialValue);

        if (this.edit) {
            this.form.controls["privilege"].disable();
        }
    }

    private validateForm()
    : void {
        this.markAllFormControlsTouched(this.form);
    }
}
