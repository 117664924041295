import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserResettlementCardModule } from "../user-resettlement-card/user-resettlement-card.module";
import { UserResettlementInfoComponent } from "./user-resettlement-info.component";

@NgModule({
    imports: [
        SharedModule,
        UserResettlementCardModule,
    ],
    declarations: [
        UserResettlementInfoComponent,
    ],
    exports: [
        UserResettlementInfoComponent,
    ]
})
export class UserResettlementInfoModule {}
