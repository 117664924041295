import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FilterHelperService {

    public filterEmptyFields<T>(filter: T)
    : T {
        Object.keys(filter).forEach(key => {
           if (!filter[key] || filter[key] === "null") {
             delete filter[key];
           }
        });

        return filter;
    }
}
