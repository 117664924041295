import { NgModule } from "@angular/core";

import { RoomThumbComponent } from "./room-thumb.component";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../core/pipes/pipes.module";

@NgModule({
    declarations: [
        RoomThumbComponent,
    ],
    imports: [
        PipesModule,
        SharedModule,
    ],
    exports: [
        RoomThumbComponent,
    ]
})
export class RoomThumbModule { }
