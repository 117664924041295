import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { GeneralReportPayload } from "../../../../shared/http-services/reports/models/general-report-payload.model";
import { GeneralReport } from "../../../../shared/http-services/reports/models/general-report.model";
import { ReportsHttpService } from "../../../../shared/http-services/reports/reports-http.service";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";
import { GeneralReportTableService } from "./services/general-report-table.service";
import { GeneralReportFormService } from "./services/general-report-form.service";
import { GeneralReportSort } from "./models/general-report-sort.enum";
import { GeneralReportTableSortOption } from "./models/general-report-table-sort-option.model";
import { Entity } from "../../../../shared/models/entity.model";
import { OptionsHttpService } from "../../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";


@Component({
    selector: "app-general-report",
    templateUrl: "./general-report.component.html",
    styleUrls: [ "./general-report.component.scss" ],
    providers: [
        GeneralReportFormService,
        GeneralReportTableService,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class GeneralReportComponent implements OnChanges {

    @Input()
    public date: string | Date;

    public readonly sortOptions: GeneralReportTableSortOption[];

    public controlsForm: FormGroup;
    public dormitoryOptions$: Observable<Entity[]>;
    public facultyOptions$: Observable<LocalizedMessage[]>;
    public gridOptions: GridOptions;
    public loading: boolean;
    public report: GeneralReport[];

    private gridApi: GridApi;

    constructor(
        private generalReportFormService: GeneralReportFormService,
        private generalReportTableService: GeneralReportTableService,
        private optionsHttpService: OptionsHttpService,
        private reportsHttpService: ReportsHttpService,
        private toastrService: ToastrService,
    ) {
        this.initOptions();

        this.controlsForm = this.generalReportFormService.buildControlsForm();
        this.gridOptions = this.generalReportTableService.getGridOptions();

        this.sortOptions = this._sortOptions;
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.date && this.date) {
            this.onDateSelected();
        }
    }

    private get payload()
    : GeneralReportPayload {
        const controls = this.generalReportFormService.mapToModel(this.controlsForm);

        return new GeneralReportPayload({
            ...controls,
            forDate: DateHelperService.formatDateIso(this.date),
        });
    }

    private get _sortOptions()
    : GeneralReportTableSortOption[] {
        return [
            {
                name: "ПІБ",
                value: GeneralReportSort.Username,
            },
            {
                name: "Факультет",
                value: GeneralReportSort.Faculty,
            },
            {
                name: "Кімната",
                value: GeneralReportSort.Room,
            },
            {
                name: "Рік навчання",
                value: GeneralReportSort.Degree
            }
        ];
    }

    public onFilterChange()
    : void {
        this.loadReport();
    }

    public onSortChange()
    : void {
        this.loadReport();
    }

    public onDateSelected()
    : void {
        this.loadReport();
    }

    public onDownload()
    : void {
        this.gridApi.exportDataAsCsv({
            customHeader: `Загальний звіт за ${DateHelperService.formatDate(this.date)}`,
            fileName: `Загальний звіт ${DateHelperService.formatDate(this.date)}.csv`,
        });
    }

    public onGridReady(event: GridReadyEvent)
    : void {
        this.gridApi = event.api;
    }

    private initOptions()
    : void {
        this.dormitoryOptions$ = this.optionsHttpService.getDormitoriesOptions();
        this.facultyOptions$ = this.optionsHttpService.getFacultyOptions();
    }

    private loadReport()
    : void {
        this.onLoadingStart();

        this.reportsHttpService.getGeneral(this.payload)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(
                res => this.report = res,
                err => this.toastrService.error(err.message),
            );
    }

    private onLoadingStart()
    : void {
        this.loading = true;

        if (!this.gridApi) {
            return;
        }

        this.gridApi.showLoadingOverlay();
    }
}
