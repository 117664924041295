import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { AbstractHttpService } from "../abstract-http.service";
import { UserDetails } from "./models/user-details.model";
import { UserInfo } from "./models/user-info.model";
import { UserResponseModel } from "./models/user-response.model";
import { GetUsersRequest } from "./models/get-users-request.model";
import { User } from "../../models/user.model";
import {UserBanRequest} from "./models/user-ban-request.model";
import { Period } from "../periods/models/period.model";

@Injectable({
    providedIn: "root"
})
export class UsersHttpService extends  AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/users`;
    }

    public getUser(userId: number)
    : Observable<UserDetails> {
        return this.httpClient.get(`${this.path}/${userId}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getUsers(request: GetUsersRequest)
    : Observable<UserResponseModel> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient
            .get(`${this.path}`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public updateUserInfo(value: UserInfo, email: string)
    : Observable<void> {
        return this.httpClient.put(`${this.path}/${email}/info`, value)
            .pipe(catchError<any, never>(this.handleError.bind(this)));
    }

    public allowSettlementForPeriod(email: string, period: Period)
    : Observable<any> {
        return this.httpClient
            .patch(`${this.path}/${email}/period/${period.id}`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public closeActiveSettlementAllowance(email: string)
    : Observable<any> {
        return this.httpClient
            .delete(`${this.path}/${email}/period`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public banUser(request: UserBanRequest)
        : Observable<User> {
        return this.httpClient.put(`${this.path}/${request.email}/ban`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public unbanUser(request: UserBanRequest)
        : Observable<User> {
        return this.httpClient.put(`${this.path}/${request.email}/unban`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public updateUserRoles(userEmail: string, roles: string[])
    : Observable<void> {
        return this.httpClient.put(`${this.path}/${userEmail}/roles`, roles)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
