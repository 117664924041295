import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AdalService } from "adal-angular4";

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(
        private adalService: AdalService,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean {
        if (this.adalService.userInfo.authenticated) {
            return true;
        }

        this.adalService.clearCache();
        this.adalService.login();

        return false;
    }
}
