import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { RoomRequestHttpService } from "../../../shared/http-services/room-request/room-request-http.service";

@Injectable()
export class UserSettlementCardService {

    constructor(
        private roomRequestHttpService: RoomRequestHttpService,
        private toastrService: ToastrService,
    ) {}

    public async cancelRoomRequest(id: number)
    : Promise<void> {
        try {
            await this.roomRequestHttpService.cancel({
                id
            }).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Запит на поселення успішно скасовано.");
    }

    public async evictUser(roomRequestId: number)
    : Promise<void> {
        try {
            await this.roomRequestHttpService.evict({
                id: roomRequestId,
            }).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Користувача успішно виселено.");
    }

    public async reviveRoomRequest(id: number)
        : Promise<void> {
        try {
            await this.roomRequestHttpService.revive({
                id
            }).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Запит на поселення успішно відновлено.");
    }
}
