import { Routes } from "@angular/router";

import { BuildingsTabComponent } from "./components/buildings-tab/buildings-tab.component";
import { RoomTabComponent } from "./components/room-tab/room-tab.component";
import { DormitoriesComponent } from "./components/dormitories.component";
import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";

export const routes: Routes = [
    {
        path: "dormitories",
        component: DormitoriesComponent,
        canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
        children: [
            {
                path: "",
                redirectTo: "rooms",
                pathMatch: "full",
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.standardAdministrationPass,
                }
            },
            {
                path: "rooms",
                component: RoomTabComponent,
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.standardAdministrationPass,
                }
            },
            {
                path: "buildings",
                component: BuildingsTabComponent,
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.standardAdministrationPass,
                }
            }
        ]}
];
