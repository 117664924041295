import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExtendedProfile } from "../../../../shared/http-services/profile/models/extended-profile.model";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { AddPrivilegeConfig } from "../../../../shared/models/add-privilege-config.model";
import { PrivilegeFactoryService } from "../../../../shared/services/privileges/privilege-factory.service";
import { PrivilegesEditorApi } from "../privileges-editor/models/privileges-editor-api.model";
import { PrivilegesEditorConfig } from "../privileges-editor/models/privileges-editor-config.model";
import { ExtendedPrivilege } from "../../../../shared/http-services/client-privileges/models/extended-privilege.model";

@Component({
    selector: "app-profile-info",
    styleUrls: [ "./profile-info.component.scss" ],
    templateUrl: "./profile-info.component.html",
})
export class ProfileInfoComponent {

    @Input()
    public degreeOptions: LocalizedMessage[];
    @Input()
    public genderOptions: LocalizedMessage[];
    @Input()
    public educationTypeOptions: LocalizedMessage[];
    @Input()
    public facultyOptions: LocalizedMessage[];
    @Input()
    public privilegeOptions: LocalizedMessage[];
    @Input()
    public specialityOptions: LocalizedMessage[];
    @Input()
    public profile: ExtendedProfile;

    @Output()
    public addNewPrivilege: EventEmitter<AddPrivilegeConfig>;
    @Output()
    public deletePrivilege: EventEmitter<number>;

    private editorApi: PrivilegesEditorApi;

    constructor(
        private privilegeFactoryService: PrivilegeFactoryService,
    ) {
        this.addNewPrivilege = new EventEmitter<AddPrivilegeConfig>();
        this.deletePrivilege = new EventEmitter<number>();
    }

    public onAddNewPrivilege()
    : void {
        const onSave = (value) => {
            const conf = new AddPrivilegeConfig({
                callback: this.editorApi.close.bind(this),
                privilege: value,
            });

            this.addNewPrivilege.emit(conf);
        };

        const config = new PrivilegesEditorConfig({
            edit: false,
            initialValue: this.privilegeFactoryService.create(),
            nameOptions: this.privilegeOptions,
            onSave,
        });

        this.editorApi.open(config);
    }

    public onDeletePrivilege(privilege: ExtendedPrivilege)
    : void {
        const confirmation = confirm(`Ви справді хочете видалити пільгу ${ privilege.privilegeName }?`);

        if (confirmation) {
            this.deletePrivilege.emit(privilege.id);
        }
    }

    public onEditorReady(api: PrivilegesEditorApi)
    : void {
        this.editorApi = api;
    }
}
