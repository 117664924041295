import { MatAutocompleteModule } from "@angular/material";
import { NgModule } from "@angular/core";

import { UserSearchContainerComponent } from "./containers/user-search-container.component";
import { UserSearchComponent } from "./components/user-search.component";
import { SharedModule } from "../../../../shared/shared.module";

@NgModule({
    declarations: [
        UserSearchComponent,
        UserSearchContainerComponent,
    ],
    imports: [
        SharedModule,
        MatAutocompleteModule,
    ],
    exports: [
        UserSearchContainerComponent,
    ],
})
export class UserSearchModule { }
