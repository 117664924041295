import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AbstractFormBasedComponent } from "../../core/components/abstract-form-based.component";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { UsersHttpService } from "../../shared/http-services/users/users-http.service";
import { UserInfoCardOptions } from "../user-info-card/models/user-info-card-options.model";
import { UserInfoCardEditorApi } from "./models/user-info-card-editor-api.model";
import { UserInfoCardEditorFormService } from "./services/user-info-card-editor-form.service";
import { LocalizedMessage } from "../../shared/models/privilege-status.model";
import { finalize } from "rxjs/operators";
import { OptionsHttpService } from "../../shared/http-services/options/options-http.service";

@Component({
    selector: "app-user-info-card-editor",
    templateUrl: "user-info-card-editor.component.html",
    styleUrls: ["user-info-card-editor.component.scss"],
    providers: [
        UserInfoCardEditorFormService,
    ]
})
export class UserInfoCardEditorComponent extends AbstractFormBasedComponent implements OnInit {

    @ViewChild(NgxSmartModalComponent)
    public modal: NgxSmartModalComponent;

    @Input()
    public options: UserInfoCardOptions;
    @Input()
    public user: UserDetails;

    @Output()
    public ready: EventEmitter<UserInfoCardEditorApi>;
    @Output()
    public updated: EventEmitter<void>;

    public form: FormGroup;
    public readonly todayDate: string;
    public specialityOptions: LocalizedMessage[];

    constructor(
        private userInfoCardEditorFormService: UserInfoCardEditorFormService,
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
        private usersHttpService: UsersHttpService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
        super();

        this.updated = new EventEmitter<void>();
        this.ready = new EventEmitter<UserInfoCardEditorApi>();
        this.todayDate = new Date().toISOString();
    }

    public ngOnInit()
    : void {
        this.ready.emit({
            open: this.open.bind(this),
        });
    }

    public async onSave()
    : Promise<void> {
        this.ngxSpinnerService.show();

        try {
            await this.usersHttpService.updateUserInfo(
                this.userInfoCardEditorFormService.mapToModel(this.form),
                this.user.email
            ).toPromise();

            this.modal.close();
            this.updated.emit();
        } catch (error) {
            this.toastrService.error(error.message);
        }

        this.ngxSpinnerService.hide();
    }

    public onFacultyChange(faculty: string | null): void {
        this.resetSpeciality();
        this.loadSpecialityOptions(faculty);
    }

    private loadSpecialityOptions(faculty: string | null): void {
        const specialityControl = this.form.controls.speciality;
        specialityControl.disable();
        if (!faculty || faculty === "") {
            return;
        }

        this.optionsHttpService.getSpecialityOptionsByFaculty(faculty)
            .pipe(
                finalize(() => specialityControl.enable()),
            )
            .subscribe(
                specialities => this.specialityOptions = specialities,
                err => this.toastrService.error(err.message)
            );
    }

    private resetSpeciality(): void {
        const specialityControl = this.form.controls.speciality;
        specialityControl.reset(null);
    }

    private open()
    : void {
        this.form = this.userInfoCardEditorFormService.buildForm(this.user);
        const facultyControl = this.form.controls.faculty;
        this.loadSpecialityOptions(facultyControl.value);
        this.modal.open();
    }
}
