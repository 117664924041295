import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { MigrationRequestCase } from "../../../features/migration-approval/models/migration-request-case.model";
import { PagedSortedResponse } from "../models/paged-sorted-response.model";
import { AbstractHttpService } from "../abstract-http.service";
import { PagingParams } from "../models/paging-params";

@Injectable({
    providedIn: "root"
})
export class MigrationRequestHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/migrations`;
    }

    public getAllActiveMigrationRequests(paging: PagingParams, search: string)
    : Observable<PagedSortedResponse<MigrationRequestCase>> {
        const params = this.buildFlatHttpParams({
            ...paging,
            search
        });

        return this.httpClient.get(`${this.path}/active`, {params})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getActiveMigrationRequests(req: PagingParams)
    : Observable<PagedSortedResponse<MigrationRequestCase>> {
        const params = this.buildFlatHttpParams(req);
        return this.httpClient.get(`${this.path}`, {params})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public approveMigrationRequest(requestId: number)
    : Observable<any> {
        return this.httpClient.post(`${this.path}/${requestId}/approve`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public rejectMigrationRequest(requestId: number)
    : Observable<any> {
        return this.httpClient.post(`${this.path}/${requestId}/reject`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public createMigrationRequest(id: number)
    : Observable<any> {
        return this.httpClient.post(`${this.path}/${id}`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public createSwitchRequest(email: string)
        : Observable<any> {
        return this.httpClient.post(`${this.path}/switch/${email}`, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
