import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementPaymentsModule } from "../user-settlement-payments/user-settlement-payments.module";
import { UserResettlementCardComponent } from "./user-resettlement-card.component";

@NgModule({
    imports: [
        SharedModule,
        UserSettlementPaymentsModule,
    ],
    declarations: [
        UserResettlementCardComponent,
    ],
    exports: [
        UserResettlementCardComponent,
    ],
})
export class UserResettlementCardModule {}
