import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RoomRequest } from "../../shared/http-services/models/room-request.model";
import { UserSettlementCardService } from "./services/user-settlement-card.service";

@Component({
    selector: "app-user-settlement-card",
    templateUrl: "user-settlement-card.component.html",
    styleUrls: ["user-settlement-card.component.scss"],
    providers: [UserSettlementCardService]
})
export class UserSettlementCardComponent {

    @Input()
    public roomRequest: RoomRequest;

    @Output()
    public paymentCanceled: EventEmitter<void>;
    @Output()
    public updated: EventEmitter<void>;

    public showPayments: boolean;

    constructor(
        private userSettlementCardService: UserSettlementCardService,
    ) {
        this.updated = new EventEmitter<void>();
        this.paymentCanceled = new EventEmitter<void>();
    }

    public changePaymentsVisibility()
    : void {
        this.showPayments = !this.showPayments;
    }

    public onPaymentsUpdated()
    : void {
        this.updated.emit();
    }

    public onPaymentCanceled()
    : void {
        this.paymentCanceled.emit();
    }

    public async onMoveOut()
    : Promise<void> {
        await this.userSettlementCardService.evictUser(this.roomRequest.id);

        this.updated.emit();
    }

    public async onCancelSettlementRequest()
    : Promise<void> {
        await this.userSettlementCardService.cancelRoomRequest(this.roomRequest.id);

        this.updated.emit();
    }

    public async onReviveSettlementRequest()
        : Promise<void> {
        await this.userSettlementCardService.reviveRoomRequest(this.roomRequest.id);

        this.updated.emit();
    }


    public  get isCancellable()
        : boolean {
        return this.roomRequest.cancellable;
    }

    public  get isEvictable()
        : boolean {
        return this.roomRequest.evictable;
    }

    public  get isRevivable()
        : boolean {
        return this.roomRequest.revivable;
    }
}
