import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { finalize } from "rxjs/operators";

import { PeriodsHttpService } from "../../../shared/http-services/periods/periods-http.service";
import { Period } from "../../../shared/http-services/periods/models/period.model";
import { DateHelperService } from "../../../shared/services/helpers/date-helper.service";

@Component({
    selector: "app-periods",
    templateUrl: "./periods.component.html",
})
export class PeriodsComponent {

    public periods: Period[];
    public loading: boolean;

    constructor(
        private periodsHttpService: PeriodsHttpService,
        private toastrService: ToastrService
    ) {
        this.loadPeriods();
    }

    public onCreatePeriod(period: Period)
    : void {
        this.loading = true;

        this.periodsHttpService.create(period)
            .pipe( finalize(() => this.loadPeriods()) )
            .subscribe(
                () => this.toastrService.success(
                    `Період(${this.formatDate(period.startDate)} - ${this.formatDate(period.endDate)}) успішно створено`),
                err => this.toastrService.error(err.message),
            );
    }

    public onDeletePeriod(period: Period)
    : void {
        const formatDate = this.formatDate.bind(this);

        if (!getUserApproval()) {
            return;
        }

        this.periodsHttpService.delete(period.id)
            .pipe( finalize(() => this.loadPeriods()) )
            .subscribe(
                () => this.toastrService.success(
                    `Період(${this.formatDate(period.startDate)} - ${this.formatDate(period.endDate)}) успішно видалено`),
                err => this.toastrService.error(err.message),
            );

        // region Local Functions

        function getUserApproval()
        : boolean {
            return confirm(`Ви впевнені що хочете видалити Період(${formatDate(period.startDate)} - ${formatDate(period.endDate)})?`);
        }

        // endregion
    }

    public onReload()
    : void {
        this.loadPeriods();
    }

    public onUpdatePeriod(period: Period)
    : void {
        this.loading = true;

        this.periodsHttpService.update(period)
            .pipe( finalize(() => this.loadPeriods()) )
            .subscribe(
                () => this.toastrService.success(
                    `Період(${this.formatDate(period.startDate)} - ${this.formatDate(period.endDate)}) успішно змінено`),
                err => this.toastrService.error(err.message),
            );
    }

    private formatDate(date: string)
    : string {
        return DateHelperService.formatDate(date);
    }

    private loadPeriods()
    : void {
        this.loading = true;

        this.periodsHttpService.getAll()
            .pipe( finalize(() => this.loading = false) )
            .subscribe(
                res => this.periods = res,
                err => this.toastrService.error(err.message),
            );
    }
}

