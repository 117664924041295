import { GeneralReportPayloadFilter } from "./general-report-payload-filter.model";

export class GeneralReportPayload {

    public filters: GeneralReportPayloadFilter;
    public forDate: string;
    public sort: string;

    constructor(init?: Partial<GeneralReportPayload>) {
        Object.assign(this, init);
    }
}
