import {Component, OnInit} from "@angular/core";

import {GetOrdersConfig} from "../../../../shared/http-services/models/get-orders-config.model";
import {OrderHttpService} from "../../../../shared/http-services/orders/order-http.service";
import {Order} from "../../../../shared/models/order.model";
import {NavigationTab} from "../../../../shared/components/navigation-tabs/models/navigation-tab.model";
import {OrganizationNavService} from "../../services/organization-nav.service";
import {OrderTabFormService} from "../search-name-form/services/order-tab-form.service";
import {ToastrService} from "ngx-toastr";
import { PageEvent } from "@angular/material";

@Component({
    selector: "app-order-tab",
    templateUrl: "./order-tab.component.html",
    styleUrls: ["./order-tab.component.scss"],
    providers: [OrderTabFormService],
})
export class OrderTabComponent implements OnInit {

    public isLoaded: boolean;
    public orders: Order[];
    public totalPages: number;
    public currentPage: number;
    public currentTab: NavigationTab;

    private readonly pageSize: number;

    constructor(
        private orderHttpService: OrderHttpService,
        private organizationNavService: OrganizationNavService,
        private toastr: ToastrService
    ) {
        this.isLoaded = false;
        this.orders = [];
        this.pageSize = 1;

        this.currentTab = this.organizationNavService.orderNavTab;
    }

    public ngOnInit()
        : void {
        this.refreshSearch(0);
    }

    public cardTreatHandle($event)
        : void {
        this.isLoaded = false;
        let status = "DONE";

        if (!$event.accept) {
            status = "DECLINED";
        }
        this.orderHttpService.patchOrders($event.id, status)
            .subscribe(() => {
                this.refreshSearch(this.currentPage);
                this.toastr.success("Ордер успішно розглянуто");
            });
    }

    public changePage(value: PageEvent)
    : void {
        this.currentPage = value.pageIndex;

        this.isLoaded = false;

        const config = new GetOrdersConfig({
            size: this.pageSize,
            page: this.currentPage,
            sortOptions: {status: "PENDING"}
        });

        this.orderHttpService.getOrders(config)
            .subscribe(
                res => {
                    this.refreshOrders(res, this.currentPage + 1);
                },
                error => alert(error.message));
    }

    public onSubmit($event: string)
        : void {
        this.refreshSearch(0, {username: $event});
    }

    private refreshOrders(res, page)
        : void {
        this.orders = [...res.content];
        this.totalPages = res.totalRecords;
        this.currentPage = page;
        this.isLoaded = true;
    }

    public refreshSearch(page: number, options: { username?: string } = {})
        : void {
        this.isLoaded = false;

        const optionsAll = {
            status: "PENDING",
        };

        if (options.hasOwnProperty("username")) {
            optionsAll["username"] = options.username;
        }

        const config = new GetOrdersConfig({
            size: this.pageSize,
            page: page,
            sortOptions: optionsAll
        });

        this.orderHttpService
            .getOrders(config)
            .subscribe(res => {
                let neededPage = page;

                if (page > res.totalRecords) {

                    neededPage = res.totalRecords;
                    this.refreshSearch(neededPage - 1);
                } else {

                    this.refreshOrders(res, neededPage + 1);
                }
            }, error => this.toastr.error(error.message));
    }
}
