import { Component, Input } from "@angular/core";

import { NavigationTab } from "../../../../shared/components/navigation-tabs/models/navigation-tab.model";
import { OrganizationNavService } from "../../services/organization-nav.service";

@Component({
    selector: "app-organization-nav-tabs",
    templateUrl: "./organization-nav-tabs.component.html",
})
export class OrganizationNavTabsComponent {

    @Input()
    public currentTab: NavigationTab;

    public tabs: NavigationTab[];

    constructor(
        private organizationNavService: OrganizationNavService,
    ) {
        this.tabs = this.organizationNavService.navTabs;
    }
}
