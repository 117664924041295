import { ExtendedPrivilege } from "../../client-privileges/models/extended-privilege.model";
import { MigrationRequestCase } from "../../../../features/migration-approval/models/migration-request-case.model";
import { RoomRequest } from "../../models/room-request.model";
import { ProfileInfo } from "./profile-info.model";

export class Profile extends ProfileInfo {

    public requiredUpdate: boolean;
    public id: number;
    public privileges: ExtendedPrivilege[];
    public roomRequests: RoomRequest[];
    public username: string;
    public myMigrationRequests?: MigrationRequestCase[];
    public proposedMigrationRequests?: MigrationRequestCase[];

    constructor(init?: Partial<Profile>) {
        super();
        Object.assign(this, init);
    }
}
