import { Pipe, PipeTransform } from "@angular/core";

import { Dictionary } from "../models/dictionary.interface";

@Pipe({
    name: "renderValidity"
})
export class RenderValidityPipe implements PipeTransform {

    public transform(value: any[] | Dictionary<any>, maxLength: number)
    : null | any[] | Dictionary<any> {
        if (!value) {
            return null;
        }

        if (Array.isArray(value) && maxLength || maxLength === 0) {
                return value.slice(0, maxLength);
        }
    }
}
