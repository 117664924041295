import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import * as organizationRouting from "./pages/organization/organization-routing";
import * as dormitoriesRouting from "./pages/dormitories/dormitories-routing";
import * as migrationsRouting from "./pages/migrations/migrations.routing";
import * as propertiesRouting from "./pages/properties/properties-routing";
import * as dashboardRouting from "./pages/dashboard/dashboard-routing";
import * as paymentsRouting from "./pages/payments/payments-routing";
import * as reportsRouting from "./pages/reports/reports.routing";
import * as logsRouting from "./pages/logs/logs.routes";
import * as userDetailsRouting from "./pages/user-details/user-details.routing";

import { AuthenticationGuard } from "./core/auth/guards/authentication.guard";
import { NavigationComponent } from "./pages/navigation/components/navigation.component";
import { NotFoundComponent } from "./pages/not-found/components/not-found.component";

const routes: Routes = [
    {
        path: "",
        component: NavigationComponent,
        pathMatch: "full",
        canActivate: [AuthenticationGuard],
    },
    ...organizationRouting.routes,
    ...dormitoriesRouting.routes,
    ...dashboardRouting.routes,
    ...propertiesRouting.routes,
    ...migrationsRouting.routes,
    ...reportsRouting.routes,
    ...logsRouting.routes,
    ...paymentsRouting.routes,
    ...userDetailsRouting.routes,
    {
        path: "**",
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
