import { RoomEditorConfig } from "./room-editor-config.model";

export class RoomEditorApi {

    public open: (config: RoomEditorConfig) => void;

    constructor(init?: Partial<RoomEditorApi>) {
        Object.assign(this, init);
    }
}
