import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AdalService } from "adal-angular4";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileHttpService } from "../../../shared/http-services/profile/profile-http.service";
import { WrappedError } from "../../../shared/http-services/models/wrapped-error.model";
import { LoginProcessService } from "../../../shared/services/helpers/login-process.service";

@Injectable({
    providedIn: "root",
})
export class ProfileStateService {

    public profile$: Observable<ExtendedProfile | null>;

    private profileSubject: BehaviorSubject<ExtendedProfile | null>;

    constructor(
        private adalService: AdalService,
        private loginProcessService: LoginProcessService,
        private profileHttpService: ProfileHttpService,
        private toastrService: ToastrService,
    ) {
        this.profileSubject = new BehaviorSubject<ExtendedProfile | null>(null);
        this.profile$ = this.profileSubject.asObservable();

        this.loadProfileOnLogin();
    }

    public loadProfile()
    : Promise<void> {
        return this.profileHttpService.getProfile()
            .pipe(
                catchError((err: WrappedError) => {
                    this.toastrService.error(err.message);
                    return of();
                }),
                map((value: ExtendedProfile) => this.updateProfile(value)),
            ).toPromise();
    }

    private loadProfileOnLogin()
    : void {
        this.loginProcessService.whenTokenApplied$
            .subscribe(() => {
                if (!this.adalService.loginInProgress()) {
                    this.loadProfile();
                }
            });
    }

    private updateProfile(value: ExtendedProfile | null)
    : void {
        this.profileSubject.next(value);
    }
}
