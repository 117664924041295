export class FilterUserModel {

    public degree: string;
    public faculty: string;
    public speciality: string;
    public username:string;
    public role:string;

    constructor(init?: Partial<FilterUserModel>) {
        Object.assign(this, init);
    }
}
