import { Pipe, PipeTransform } from "@angular/core";

import { Link } from "../../../../models/link.model";

@Pipe({
    name: "isLinkActive"
})
export class HeaderDropDownIsLinkActivePipe implements PipeTransform {

    public transform(url: string, link: Link)
    : boolean {
        return url && !!link.children.find(child => url.includes(child.link));
    }
}
