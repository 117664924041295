import { Component, EventEmitter, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";

import { MigrationRequestHttpService } from "../../../../../shared/http-services/migration-request/migration-request-http.service";
import { GetFreeRoomsRequest } from "../../../../../shared/http-services/rooms/models/get-free-rooms-request.model";
import { OptionsHttpService } from "../../../../../shared/http-services/options/options-http.service";
import { RoomHttpService } from "../../../../../shared/http-services/rooms/room-http.service";
import { Entity } from "../../../../../shared/models/entity.model";
import { Room } from "../../../../../shared/models/rooms.model";

@Component({
    selector: "app-free-room-request",
    templateUrl: "./free-room-request.component.html",
})
export class FreeRoomRequestComponent {

    @Output()
    public savingFinished: EventEmitter<void>;
    @Output()
    public savingStarted: EventEmitter<void>;
    @Output()
    public requestAdded: EventEmitter<void>;
    @Output()
    public requestSaveFailed: EventEmitter<void>;

    public dormitories$: Observable<Entity[]>;
    public freeRooms: Room[];

    constructor(
        private migrationRequestHttpService: MigrationRequestHttpService,
        private optionsHttpService: OptionsHttpService,
        private roomHttpService: RoomHttpService,
        private toastrService: ToastrService,
    ) {
        this.savingFinished = new EventEmitter<void>();
        this.savingStarted = new EventEmitter<void>();
        this.requestAdded = new EventEmitter<void>();
        this.requestSaveFailed = new EventEmitter<void>();

        this.loadRooms();
        this.initDormitories();
    }

    public loadRooms(request?: GetFreeRoomsRequest)
    : void {
        this.roomHttpService.getFreeRooms(request)
            .subscribe(
                res => this.freeRooms = res,
                    err => {
                        this.toastrService.error(err.message);
                        this.requestSaveFailed.emit();
                    },
                );
    }

    public onRoomSelect(room: Room)
    : void {
        if (!confirm("Ви впевнені що хочете створити заявку на переселення в кімнату " + room.number +
            " в гуртожитку " + room.dormitory + "?")) {
            return;
        }

        this.savingStarted.emit();

        this.migrationRequestHttpService.createMigrationRequest(room.id)
            .pipe(
                finalize(() => this.savingFinished.emit())
            )
            .subscribe(() => {
                    this.toastrService.success(`Запит на переселення в кімнату номер ${room.number}, успішно створений.
                Слідкуйте за статусом заяви на сторінці поселення.`);
                    this.requestAdded.emit();
                },
                error => this.toastrService.error(error.message));
    }

    private initDormitories()
    : void {
        this.dormitories$ = this.optionsHttpService.getDormitoriesOptions();
    }
}
