import { NgModule } from "@angular/core";
import { DashboardModule } from "../../pages/dashboard/dashboard.module";
import { SharedModule } from "../../shared/shared.module";
import { UserPrivilegeCardComponent } from "./user-privilege-card.component";

@NgModule({
    imports: [
        SharedModule,
        DashboardModule,
    ],
    declarations: [
        UserPrivilegeCardComponent,
    ],
    exports: [
        UserPrivilegeCardComponent,
    ]
})
export class UserPrivilegeCardModule {}
