import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";

import { PayNowModalApi } from "./models/pay-now-modal.api";
import { Profile } from "../../../../shared/http-services/profile/models/profile.model";

@Component({
    selector: "app-pay-now-modal",
    templateUrl: "./pay-now-modal.component.html",
    styleUrls: [ "./pay-now-modal.component.scss", ],
})
export class PayNowModalComponent implements OnInit {

    @ViewChild("payNowModal")
    public modalComponent: NgxSmartModalComponent;

    @Input()
    public profile: Profile;
    @Output()
    public modalReady: EventEmitter<PayNowModalApi>;

    constructor() {
        this.modalReady = new EventEmitter<PayNowModalApi>();
    }

    public ngOnInit()
    : void {
        this.onModalReady();
    }

    private get api()
    : PayNowModalApi {
        return new PayNowModalApi({
            open: this.open.bind(this),
        });
    }

    private onModalReady()
    : void {
        this.modalReady.emit(this.api);
    }

    private open()
    : void {
        this.modalComponent.open();
    }
}

