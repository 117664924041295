import { Dictionary } from "../../core/models/dictionary.interface";

export enum UserRole {
    Student = "STUDENT",
    Employee = "EMPLOYEE",
    StudentBoardMember = "STUDENT_BOARD_MEMBER",
    DormitoryBoardMember = "DORMITORY_BOARD_MEMBER",
    HousingDepartmentMember = "HOUSING_DEPARTMENT_MEMBER",
    DormitoryManager = "DORMITORY_MANAGER",
    Dean = "DEAN",
    Admin = "ADMIN",
}

export const UserRoleIsAdminPanelAssignable: Dictionary<boolean> = {
    [UserRole.Student]: true,
    [UserRole.Employee]: true,
    [UserRole.StudentBoardMember]: true,
    [UserRole.DormitoryBoardMember]: false,
    [UserRole.HousingDepartmentMember]: true,
    [UserRole.DormitoryManager]: false,
    [UserRole.Dean]: true,
    [UserRole.Admin]: true,
};
