import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserDetailsMode } from "./models/user-details-mode.enum";

@Component({
    selector: "app-user-details-modes-switch",
    templateUrl: "user-details-modes-switch.component.html",
    styleUrls: ["user-details-modes-switch.component.scss"]
})
export class UserDetailsModesSwitchComponent implements OnChanges {

    private static defaultMode = UserDetailsMode.Settlement;

    @Input()
    public mode: UserDetailsMode;

    @Output()
    public modeChange: EventEmitter<UserDetailsMode>;

    public readonly userDetailsModes: typeof UserDetailsMode;

    constructor() {
        this.userDetailsModes = UserDetailsMode;

        this.modeChange = new EventEmitter<UserDetailsMode>();
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.mode) {
            this.updateModeIfInvalid();
        }
    }

    public selectMode(value: UserDetailsMode)
    : void {
        this.mode = value;
        this.modeChange.emit(this.mode);
    }

    private updateModeIfInvalid()
    : void {
        const value = this.mode;

        if (value && getDoesModeExist()) {
            return ;
        }

        this.selectMode(UserDetailsModesSwitchComponent.defaultMode);

        // region Local Function

        function getDoesModeExist()
        : boolean {
            return Object.values(UserDetailsMode).includes(value);
        }

        // endregion
    }
}
