import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { SwitchRoomRequestService } from "../services/switch-room-request.service";

@Component({
    selector: "app-switch-room-request-view",
    templateUrl: "./switch-room-request-view.component.html",
    styleUrls: [ "./switch-room-request-view.component.scss" ],
    providers: [ SwitchRoomRequestService ],
})
export class SwitchRoomRequestViewComponent {

    @Output()
    public send: EventEmitter<string>;

    public form: FormGroup;

    constructor(
        private switchRoomRequestService: SwitchRoomRequestService,
    ) {
        this.send = new EventEmitter<string>();
        this.form = this.switchRoomRequestService.buildForm();
    }

    public get buttonActive()
    : boolean {
        return this.form.valid;
    }

    private get email()
    : string {
        return this.form.value.email;
    }

    public onSave()
    : void {
        this.send.emit(this.email);
    }
}
