import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";

@Component({
    selector: "app-user-settlement-info",
    templateUrl: "user-settlement-info.component.html",
    styleUrls: [
        "user-settlement-info.component.scss",
    ]
})
export class UserSettlementInfoComponent {

    @Input()
    public user: UserDetails;

    @Output()
    public paymentCanceled: EventEmitter<void>;
    @Output()
    public updated: EventEmitter<void>;

    constructor() {
        this.paymentCanceled = new EventEmitter<void>();
        this.updated = new EventEmitter<void>();
    }

    public onUserInfoUpdated()
    : void {
        this.updated.emit();
    }

    public onPaymentCanceled()
    : void {
        this.paymentCanceled.emit();
    }
}
