import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { BuildingHttpService } from "../../../../shared/http-services/buildings/building-http.service";
import { User } from "../../../../shared/models/user.model";
import { Building } from "../../../../shared/models/building.model";
import { ManagerRequest } from "../../../../shared/http-services/buildings/models/manager-request.model";

@Component({
    selector: "app-members-of-management",
    templateUrl: "./members-of-management.component.html",
    styleUrls: [ "./members-of-management.component.scss", ],
})
export class MembersOfManagementComponent {

    @Input()
    public building: Building;
    @Input()
    public editable: boolean;
    @Input()
    public member: User;

    @Output()
    public updated: EventEmitter<void>;

    constructor(
        private buildingHttpService: BuildingHttpService,
        private toastrService: ToastrService,
    ) {
        this.updated = new EventEmitter<void>();
    }

    public onAdd(member: User)
    : void {
        if (!confirm(`Ви впевнені що хочете додати до комендантів "${this.building.name}"
            користувача ${member.username}?`)) {
            return;
        }

        this.buildingHttpService.addManager(this.getRequest(member))
            .subscribe(
            () => {
                this.onUpdated();
                this.toastrService.success(`Користувача ${member.username} успішно додано до комендантів`);
                },
            (err) => this.toastrService.error(err.message),
            );
    }

    public onRemove(member: User)
    : void {
        if (!confirm("Ви впевнені що хочете усунути з комендантів користувача " + member.username + "?")) {
            return;
        }

        this.buildingHttpService.removeManager(this.getRequest(member))
            .subscribe(
            () => {
                this.onUpdated();
                this.toastrService.success(`Користувач ${member.username} успішно усунутий з комендантів`) ;
            },
            (err) => this.toastrService.error(err.message),
        );
    }

    private getRequest(member: User)
    : ManagerRequest {
        return {
            dormitoryId: this.building.id,
            userEmail: member.email,
        };
    }

    private onUpdated()
    : void {
        this.updated.emit();
    }
}
