import { Component, Input, ViewEncapsulation } from "@angular/core";

import { Link } from "../../../../shared/models/link.model";

@Component({
    selector: "app-navigation-menu",
    templateUrl: "./navigation-menu.component.html",
    styleUrls: [ "./navigation-menu.component.scss" ],
    encapsulation: ViewEncapsulation.None,
})
export class NavigationMenuComponent {

    @Input()
    public menuLink: Link;
}
