import { RoomDetails } from "../../../../../shared/models/room-details.model";
import { Room } from "../../../../../shared/models/rooms.model";
import { EditRoom } from "../../../../../shared/models/edit-room.model";

export class RoomEditorConfig {

    public edit: boolean;
    public onSave: (value: EditRoom, id?: number) => void;
    public initialValue: RoomDetails;

    constructor(init?: Partial<RoomEditorConfig>) {
        Object.assign(this, init);
    }
}
