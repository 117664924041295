import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { DormitoryReportItem } from "./models/dormitory-report-item.model";
import { FacultiesDegreeReport } from "./models/faculties-degrees-report.model";
import { GeneralReportPayload } from "./models/general-report-payload.model";
import { GeneralReport } from "./models/general-report.model";
import { FacultiesDegreeReports } from "./models/faculties-degrees-reports.model";

@Injectable({
    providedIn: "root"
})
export class ReportsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/reports`;
    }

    public getDormitories(date: string)
    : Observable<DormitoryReportItem[]> {
        return this.httpClient.get(`${this.path}/dormitories`, { params: {forDate: date} })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getGeneral(payload: GeneralReportPayload)
    : Observable<GeneralReport[]> {
        const params = this.buildFlatHttpParams(payload);

        return this.httpClient.get(`${this.path}/dormitories/info`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getFacultiesAndDegrees(date: string)
    : Observable<FacultiesDegreeReports> {
        return this.httpClient.get(`${this.path}/faculties/degrees`, { params: {forDate: date} })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
