import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";

import { LogsHttpService } from "../../../shared/http-services/logs/logs-http.service";
import { LogsRequestModel } from "../../../shared/http-services/logs/models/logs-request.model";
import { LogsResponseModel } from "../../../shared/http-services/logs/models/logs-response.model";
import { Log } from "../models/log.model";

@Component({
    selector: "app-logs",
    templateUrl: "./logs.component.html",
})
export class LogsComponent {

    public data: LogsResponseModel;

    constructor(
        private logsHttpService: LogsHttpService,
        private toastrService: ToastrService,
    ) {}

    public get tableData()
    : Log[] {
        return this.data
            ? this.data.content
            : null;
    }

    public get totalRecords()
    : number {
        return this.data
            ? this.data.totalElements
            : 0;
    }

    public loadData(request: LogsRequestModel)
    : void {
        this.logsHttpService.get(request)
            .subscribe(
                res => this.data = res,
                err => this.toastrService.error(err.message),
            );
    }
}
