import { Component, EventEmitter, Output } from "@angular/core";

import { NavigationTab } from "../../../../shared/components/navigation-tabs/models/navigation-tab.model";
import { UserRolesStateService } from "../../../../core/roles/services/user-roles-state.service";
import { RolePasses } from "../../../../core/roles/rules/roles-passes.class";

@Component({
    selector: "app-buildings-controls",
    templateUrl: "./buildings-controls.component.html",
    styleUrls: [ "./buildings-controls.component.scss" ],
})
export class BuildingsControlsComponent {

    @Output()
    public activeEvent: EventEmitter<boolean>;

    @Output()
    public addNew: EventEmitter<void>;

    public canAddDorm: boolean;
    public currentTab: NavigationTab;

    constructor(
        private userRolesStateService: UserRolesStateService,
    ) {
        this.addNew = new EventEmitter<void>();
        this.activeEvent = new EventEmitter<boolean>();

        this.subscribeOnRolePass();
    }

    public onAddNew()
    : void {
        this.addNew.emit();
    }

    public changeActive($event: boolean)
    : void {
        this.activeEvent.emit($event);
    }

    private subscribeOnRolePass()
    : void {
        this.userRolesStateService.getIsInRole(RolePasses.canEditBuildingsPass)
            .subscribe(res => this.canAddDorm = res);
    }
}
