import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { AbstractHttpService } from "../abstract-http.service";
import { ActionDashboard } from "./models/action-dashboard.model";


@Injectable({
    providedIn: "root"
})
export class ActionsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}`;
    }

    public getDashboardActions()
    : Observable<ActionDashboard> {
        return this.httpClient
            .get(`${this.path}/actions/dashboard`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
