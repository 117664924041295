import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { ProfileModalService } from "../../../features/profile-modal/services/profile-modal.service";

@Injectable()
export class ProfileFormGuard implements CanActivate {

    constructor(
        private profileModalService: ProfileModalService,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean> {
        return this.profileModalService.profileRequiresUpdate
            .pipe(map(value => !value));
    }
}
