import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { AdalService, AdalGuard } from "adal-angular4";
import { LOCALE_ID, NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

import { OrganizationModule } from "./pages/organization/organization.module";
import { AppRoutingModule } from "./app-routing.module";
import { UserDetailsModule } from "./pages/user-details/user-details.module";
import { SharedModule } from "./shared/shared.module";
import { DormitoriesModule } from "./pages/dormitories/dormitories.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { ProfileModule } from "./features/profile-modal/profile.module";
import { DashboardModule } from "./pages/dashboard/dashboard.module";
import { PropertiesModule } from "./pages/properties/properties.module";
import { NavigationModule } from "./pages/navigation/navigation.module";
import { NotFoundModule } from "./pages/not-found/not-found.module";
import { ReportsModule } from "./pages/reports/reports.module";
import { LogsModule } from "./pages/logs/logs.module";
import { PeriodsModule } from "./pages/periods/periods.module";
import { PaymentsModule } from "./pages/payments/payments.module";
import { DD_MM_YYYY_FORMAT } from "./shared/constants/material-datepicker-formats";
import { MigrationsModule } from "./pages/migrations/migrations.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            closeButton: true
        }),
        BrowserModule,
        SharedModule,
        DashboardModule,
        DormitoriesModule,
        NavigationModule,
        OrganizationModule,
        LogsModule,
        MigrationsModule,
        PaymentsModule,
        PeriodsModule,
        ProfileModule,
        AppRoutingModule,
        PropertiesModule,
        ReportsModule,
        NotFoundModule,
        UserDetailsModule
    ],
    providers: [
        AdalService,
        AdalGuard,
        { provide: LOCALE_ID, useValue: "uk" },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [LOCALE_ID]
        },
        { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_FORMAT },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
