import { HttpErrorResponse } from "@angular/common/http";
import { ResponseError } from "./response-error.model";

export class WrappedError {

    public response: HttpErrorResponse = null;
    public responseError: ResponseError | null = null;

    constructor(init?: Partial<WrappedError>) {
        Object.assign(this, init);
    }

    public get message(): string | null {
        return this.responseError
            ? this.responseError.message
            : "A server error occurred.";
    }

    public get status(): string | null {
        return this.responseError ? this.responseError.status : null;
    }
}
