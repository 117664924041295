import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Link } from "../../../models/link.model";
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Component({
    selector: "app-header-drop-down-menu",
    templateUrl: "./header-drop-down-menu.component.html",
    styleUrls: ["./header-drop-down-menu.component.scss"]
})
export class HeaderDropDownMenuComponent {

    @Input()
    public headerMenu: boolean;
    @Input()
    public menuLink: Link;

    @Output()
    public onChosenLink: EventEmitter<void>;

    public pageUrl$: Observable<string>;

    constructor(
        private router: Router,
    ) {
        this.onChosenLink = new EventEmitter<void>();
        this.initPageUrl();
    }

    public onClick()
    : void {
      this.onChosenLink.emit();
    }

    private initPageUrl()
    : void {
        this.pageUrl$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url)
            );
    }
}
