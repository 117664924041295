import { FormBuilder, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import { GeneralReportPayload } from "../../../../../shared/http-services/reports/models/general-report-payload.model";
import { GeneralReportSort } from "../models/general-report-sort.enum";
import { FilterHelperService } from "../../../../../shared/services/helpers/filter-helper.service";

@Injectable()
export class GeneralReportFormService {

    constructor(
        private filterHelperService: FilterHelperService,
        private formBuilder: FormBuilder,
    ) {}

    public buildControlsForm()
    : FormGroup {
        return this.formBuilder.group({
            full: null,
            dormitory: null,
            faculty: null,
            sort: GeneralReportSort.Username,
        });
    }

    public mapToModel(form: FormGroup)
    : Partial<GeneralReportPayload> {
        const { full, dormitory, sort, faculty } = form.value;

        const filters = this.filterHelperService.filterEmptyFields({ full, dormitory, faculty});

        return new GeneralReportPayload({filters, sort});
    }
}
