import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { NouisliderComponent } from "ng2-nouislider";

import { PaymentOptionItem } from "../../../../shared/http-services/payments/models/payment-option-item.model";
import { PaymentMapService } from "./services/payment-map.service";
import { NouisliderConfig } from "../../../../shared/models/nouislider-config.model";

@Component({
    selector: "app-payments-map",
    templateUrl: "./payments-map.component.html",
    styleUrls: ["./payments-map.component.scss"],
    providers: [ PaymentMapService, ],
    encapsulation: ViewEncapsulation.None,
})
export class PaymentsMapComponent implements OnChanges {

    @ViewChild("slider")
    public slider: NouisliderComponent;

    @Input()
    public options: PaymentOptionItem[];

    @Output()
    public createPayment: EventEmitter<string>;

    public config: Partial<NouisliderConfig>;
    public defaultSliderValue: number;
    public sliderValue: number[] | string[];

    constructor(
       private paymentMapService: PaymentMapService,
    ) {
        this.createPayment = new EventEmitter<string>();
    }

    private static fixSliderValue(value: number)
    : string {
        return value.toFixed(0);
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.options && this.options) {
            this.initSliderConfig();
            this.initDefaultSliderValue();
        }
    }

    public get periodSelected()
    : boolean {
        return this.sliderValue && this.sliderValue[0] !== this.sliderValue[1];
    }

    public onCreatePeriod()
    : void {
        const toDate = this.options[PaymentsMapComponent.fixSliderValue(this.sliderValue[1] as number)].untilDate;

        this.createPayment.emit(toDate);
    }

    private initDefaultSliderValue()
    : void {
        this.defaultSliderValue = this.paymentMapService.getDefaultSliderValue(this.options);
        this.sliderValue = [this.defaultSliderValue, this.defaultSliderValue];
    }

    private initSliderConfig()
    : void {
        this.config = this.paymentMapService.getSliderConfig(this.options);

        if (this.slider) {
            this.slider.slider.updateOptions(this.config);
        }
    }
}
