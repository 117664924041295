import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class OrderTabFormService {

    constructor(
        private formBuilder: FormBuilder,
    ) { }

    public buildForm()
    : FormGroup {
        return this.formBuilder.group({
            username: [
                "",
                [Validators.required, Validators.minLength(1)]
            ]
        });
    }
}
