import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { combineLatest } from "rxjs";

import { GetPropertiesRequest } from "../../../shared/http-services/properties/models/get-properties-request.model";
import { PropertyHttpService } from "../../../shared/http-services/properties/property-http.service";
import { Property } from "../../../shared/http-services/properties/models/property.model";
import { WrappedError } from "../../../shared/http-services/models/wrapped-error.model";

@Component({
    selector: "app-properties-container",
    templateUrl: "./properties-container.component.html",
})
export class PropertiesContainerComponent {

    public properties: Property[];

    constructor(
        private propertyHttpService: PropertyHttpService,
        private toastrService: ToastrService,
    ) {
        this.loadProperties();
    }

    public loadProperties()
    : void {
        const request = new GetPropertiesRequest();

        this.propertyHttpService.get(request)
            .subscribe(
                (res) => this.properties = res,
                (err: WrappedError) => this.toastrService.error(err.message),
            );
    }

    public onUpdateProperties(properties: Property[])
    : void {
        const updatePropertiesBunch = [];

        properties.forEach(property =>
            updatePropertiesBunch.push(this.propertyHttpService.update(
                property.name,
                {
                    value: property.value,
                    description: property.description,
                }
            )));

        combineLatest(
            ...updatePropertiesBunch
        ).subscribe(
            () => {
                this.loadProperties();
                this.toastrService.success("Властивості системи було успішно оновлено");
            },
            (err: WrappedError) => this.toastrService.error(err.message),
        );
    }
}
