export class LogsRequestFilter {

    public logText: string;
    public loggedAfter: string;
    public loggedBefore: string;
    public userEmail: string;
    public userRole: string;

    constructor(init?: Partial<LogsRequestFilter>) {
        Object.assign(this, init);
    }
}
