import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { UserRolesStateService } from "../../core/roles/services/user-roles-state.service";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";

@Component({
    selector: "app-migrations",
    templateUrl: "migrations.component.html",
    styleUrls: ["migrations.component.scss"],
})
export class MigrationsComponent {

    public canApprove: Observable<boolean>;

    constructor(
        private userRolesStateService: UserRolesStateService,
    ) {
        this.canApprove = this.userRolesStateService.getIsInRole(RolePasses.approveMigrationRequestsPass);
    }
}

