import { FormArray, FormControl, FormGroup } from "@angular/forms";

export class AbstractFormBasedComponent {

    public isControlInvalid(controlName: string, formGroup: FormGroup)
    : boolean {
        const control = formGroup.get(controlName);

        return control.invalid && control.touched;
    }

    public controlHasError(controlName: string, errorCode: string, formGroup: FormGroup, superiorErrorCode?: string)
    : boolean {
        const control = formGroup.get(controlName);

        if (control.hasError(superiorErrorCode)) {
            return false;
        }

        return control.hasError(errorCode) && control.touched;
    }

    public enableAllControls(form: FormGroup)
    : void {
        Object.values(form.controls).forEach(control => {
            control.enable();
        });
    }

    public disableAllControls(form: FormGroup)
    : void {
        Object.values(form.controls).forEach(control => {
            control.disable();
        });
    }

    public getControlClass(field: string, formGroup: FormGroup)
    : { [key: string]: boolean } {
        return {
            "has-error": this.isControlInvalid(field, formGroup),
        };
    }

    public markAllFormControlsTouched(formGroup: FormGroup | FormArray)
    : void {
        Object.keys(formGroup.controls).forEach(field => {

            const control = formGroup.get(field);

            if (control instanceof FormControl) {

                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup || control instanceof FormArray) {

                this.markAllFormControlsTouched(control);
            }
        });
    }

    public markAllFormControlsUntouched(formGroup: FormGroup | FormArray)
    : void {
        Object.keys(formGroup.controls).forEach(field => {

            const control = formGroup.get(field);

            if (control instanceof FormControl) {

                control.markAsUntouched({ onlySelf: true });
            } else if (control instanceof FormGroup || control instanceof FormArray) {

                this.markAllFormControlsUntouched(control);
            }
        });
    }
}
