export class Link {

    public title: string;
    public link?: string;
    public icon?: string;
    public children?: Link[];

    constructor(init?: Partial<Link>) {
        Object.assign(this, init);
    }
}
