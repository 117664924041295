export class EditRoom {

    public dormitory: string;
    public floor: number;
    public maxResidents: number;
    public number: string;
    public privileges: string[];
    public status: string;

    constructor(init?: Partial<EditRoom>) {
        Object.assign(this, init);
    }
}
