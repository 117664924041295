import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class FormValidatorsService {

    public validateConfirmField(
        controlName: string,
        confirmControlName: string,
        formGroup: FormGroup
    )
    : ValidationErrors {
        const control = formGroup.get(controlName);
        const confirmControl = formGroup.get(confirmControlName);

        if (control.value !== confirmControl.value) {

            confirmControl.setErrors(
                {
                    notEqual: true
                });

            return { notEqual: true };
        }

        confirmControl.setErrors(null);

        return null;
    }

    public getDateRangeValidator()
    : ValidatorFn {
        return (form: FormGroup)
        : ValidationErrors => {
            const dateStart = (new Date(form.get("dateStart").value)).getTime();
            const dateEnd = (new Date(form.get("dateEnd").value as Date)).getTime();
            const now = (new Date()).getTime();
            if (dateEnd > dateStart && dateStart >= now) {
                return null;
            }
            return {"dateRangeValidator": true};
        };
    }
}
