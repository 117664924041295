import { AgGridEvent, GridOptions } from "ag-grid-community";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class AgGridOptionsService {

    public getGridOptions(extend?: Partial<GridOptions>)
    : GridOptions {
        const baseOptions = <GridOptions>{
            enableColResize: true,
            enableSorting: true,
            onGridSizeChanged: (event: AgGridEvent) => {
                event.api.sizeColumnsToFit();
            },
            suppressHorizontalScroll: true,
            suppressMovableColumns: true,
            suppressCellSelection: true,
        };

        if (extend) {
            const extendedOptions = {
                ...baseOptions,
                ...extend
            };

            return extendedOptions;
        }

        return baseOptions;
    }
}
