import { FilteredRequest } from "../../models/filtered-request.model";
import { SortedRequest } from "../../models/sorted-request.model";
import { PagedRequest } from "../../models/paged-request.model";
import { GetUsersRequestFilter } from "./get-users-request-filter.model";


export class GetUsersRequest implements PagedRequest, SortedRequest, FilteredRequest<GetUsersRequestFilter> {

    public filters: GetUsersRequestFilter;
    public page: string;
    public size: string;
    public sort: string;

    constructor(init?: { size: string; page: string; filters: {} }) {
        Object.assign(this, init);
    }
}

