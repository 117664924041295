import { Routes } from "@angular/router";

import { PropertiesContainerComponent } from "./containers/properties-container.component";
import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { PeriodsComponent } from "../periods/containers/periods.component";

export const routes: Routes = [
    {
        path: "properties",
        canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.canViewProperties,
        },
        children: [
            {
                path: "",
                redirectTo: "system",
                pathMatch: "full",
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.canViewProperties,
                }
            },
            {
                path: "system",
                component: PropertiesContainerComponent,
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.canViewProperties,
                }
            },
            {
                path: "periods",
                component: PeriodsComponent,
                canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
                data: {
                    isInRole: RolePasses.canViewProperties,
                }
            },
        ]
    }];
