import { NgModule } from "@angular/core";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { UserDetailsModesSwitchModule } from "../../features/user-details-modes-switch/user-details-modes-switch.module";
import { UserInfoCardModule } from "../../features/user-info-card/user-info-card.module";
import { UserPrivilegeCardModule } from "../../features/user-privilege-card/user-privilege-card.module";
import { UserResettlementInfoModule } from "../../features/user-resettlement-info/user-resettlement-info.module";
import { UserRolesCardModule } from "../../features/user-roles-card/user-roles-card.module";
import { UserSettlementInfoModule } from "../../features/user-settlement-info/user-settlement-info.module";
import { SharedModule } from "../../shared/shared.module";
import { UserDetailsComponent } from "./user-details.component";

@NgModule({
    imports: [
        SharedModule,
        PageLoaderModule,
        UserInfoCardModule,
        UserPrivilegeCardModule,
        UserSettlementInfoModule,
        UserRolesCardModule,
        UserDetailsModesSwitchModule,
        UserResettlementInfoModule,
    ],
    declarations: [
        UserDetailsComponent,
    ],
    exports: [
        UserDetailsComponent,
    ]
})
export class UserDetailsModule {}
