import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Component } from "@angular/core";

import { SettlementDocument } from "./models/settlement-document.enum";
import { SettlementDocumentsHelper } from "./helpers/settlement-documents-helper";

@Component({
    selector: "app-settlement-documents",
    templateUrl: "settlement-documents.component.html",
    styleUrls: ["settlement-documents.component.scss"],
})
export class SettlementDocumentsComponent {

    public settlementDocuments: typeof SettlementDocument;
    public documentUrl: SafeUrl;
    public downloadLink: string;
    public selectedDocument: SettlementDocument;

    constructor(
        private sanitizer: DomSanitizer,
    ) {
        this.settlementDocuments = SettlementDocument;
        this.onSelectDocument(SettlementDocument.State);
    }

    public onSelectDocument(document: SettlementDocument)
    : void {
        this.selectedDocument = document;

        this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(SettlementDocumentsHelper.getDocumentDisplayUrl(document));
        this.downloadLink = SettlementDocumentsHelper.getDocumentDownloadUrl(this.selectedDocument);
    }
}

