import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Period } from "../../../shared/http-services/periods/models/period.model";

import { RoomRequestModalApi } from "./room-request-modal/models/room-request-modal-api.model";
import { RoomRequest } from "../../../shared/http-services/models/room-request.model";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { Profile } from "../../../shared/http-services/profile/models/profile.model";
import { ModalApi } from "../../../shared/models/modal-api.interface";
import { MigrationRequestCase } from "../../migration-approval/models/migration-request-case.model";

@Component({
    selector: "app-room-request-board",
    styleUrls: [ "./room-request-board.component.scss", ],
    templateUrl: "./room-request-board.component.html",
})
export class RoomRequestBoardComponent {

    @Input()
    public changingMigrationStatus: boolean;
    @Input()
    public profile: Profile;
    @Input()
    public requests: RoomRequest[];
    @Input()
    public roomRequestStatuses: LocalizedMessage[];
    @Input()
    public canCreateRoomRequest: boolean;
    @Input()
    public canCreateMigrationRequest: boolean;
    @Input()
    public settlementExtensionPeriod: Period;

    @Output()
    public approveMigrationRequest: EventEmitter<MigrationRequestCase>;
    @Output()
    public cancelMigrationRequest: EventEmitter<MigrationRequestCase>;
    @Output()
    public rejectMigrationRequest: EventEmitter<MigrationRequestCase>;
    @Output()
    public requestChanged: EventEmitter<void>;
    @Output()
    public extendRoomRequest: EventEmitter<number>;

    private addNewModalApi: RoomRequestModalApi;
    private migrationModalApi: ModalApi;

    constructor() {
        this.approveMigrationRequest = new EventEmitter<MigrationRequestCase>();
        this.cancelMigrationRequest = new EventEmitter<MigrationRequestCase>();
        this.rejectMigrationRequest = new EventEmitter<MigrationRequestCase>();
        this.extendRoomRequest = new EventEmitter<number>();
        this.requestChanged = new EventEmitter<void>();
    }

    public get canShowAddNewRoomRequest()
    : boolean {
        return this.profile && this.canCreateRoomRequest;
    }

    public get canShowAddNewMigrationRequest()
    : boolean {
        return this.profile && this.canCreateMigrationRequest;
    }

    public get myMigrationRequests()
    : MigrationRequestCase[] {
        return this.profile ?
            this.profile.myMigrationRequests
            : undefined;
    }

    public get proposedMigrationRequests()
    : MigrationRequestCase[] {
        return this.profile ?
            this.profile.proposedMigrationRequests
            : undefined;
    }

    public get roomRequestsNewestFirst(): RoomRequest[] {
        return this.requests.reverse();
    }

    public getIsMigrationRequestApproved(request: MigrationRequestCase)
    : boolean {
        return !!request.migrationDecisions.find(decision => decision.approved);
    }

    public onAddNewRequest()
    : void {
        this.addNewModalApi.open();
    }

    public onRoomRequestCreated()
    : void {
        this.requestChanged.emit();
    }

    public onMigrationRequestCreateFailed()
    : void {
        this.requestChanged.emit();
    }

    public onMigrationRequestCreated()
    : void {
        this.requestChanged.emit();
    }

    public onAddNewMigrationRequest()
    : void {
        this.migrationModalApi.open();
    }

    public onAddNewModalReady(api: RoomRequestModalApi)
    : void {
        this.addNewModalApi = api;
    }

    public onApproveMigrationRequest(request: MigrationRequestCase)
    : void {
        this.approveMigrationRequest.emit(request);
    }

    public onCancelMigrationRequest(request: MigrationRequestCase)
    : void {
        this.cancelMigrationRequest.emit(request);
    }

    public onMigrationModalReady(api: ModalApi)
    : void {
        this.migrationModalApi = api;
    }

    public onRejectMigrationRequest(request: MigrationRequestCase)
    : void {
        this.rejectMigrationRequest.emit(request);
    }

    public onExtendRoomRequest($event: number)
    : void {
        this.extendRoomRequest.emit($event);
    }
}

