import { map, switchMap } from "rxjs/operators";
import { AdalService } from "adal-angular4";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ProfileHttpService } from "../../../shared/http-services/profile/profile-http.service";
import { LoginProcessService } from "../../../shared/services/helpers/login-process.service";
import { UserRole } from "../../../shared/enums/user-role.enum";

@Injectable({
    providedIn: "root",
})
export class UserRolesStateService {

    public userRoles$: Observable<string[]>;

    constructor(
        private adalService: AdalService,
        private loginProcessService: LoginProcessService,
        private profileHttpService: ProfileHttpService,
    ) {
        this.initUserRoles();
    }

    public getIsInRole(allowedRoles: UserRole | UserRole[])
    : Observable<boolean> {
        const normalizedAllowedRoles = this.normalizeRoles(allowedRoles);

        return this.userRoles$
            .pipe(
                map(roles => !!normalizedAllowedRoles.find(role => {
                    return roles.includes(role);
                })),
            );
    }

    private initUserRoles()
    : void {
        this.userRoles$ = this.adalService.getUser()
            .pipe(
                switchMap(() => this.loginProcessService.whenTokenApplied$),
                switchMap(() => this.profileHttpService.getMyRoles()),
                map(res => res.roles)
            );
    }

    private normalizeRoles(roles: UserRole | UserRole[])
    : string[] {
        return Array.isArray(roles)
            ? roles
            : [ roles ];
    }
}
