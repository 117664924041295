import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { RoomRequest } from "../models/room-request.model";
import { EvictRoomRequestRequest } from "./models/evict-room-request-request.model";
import { CancelRoomRequestRequest } from "./models/cancel-room-request-request.model";

@Injectable({
    providedIn: "root"
})
export class RoomRequestHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/room-request`;
    }

    public cancel(request: CancelRoomRequestRequest)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${request.id}/cancel`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public revive(request: CancelRoomRequestRequest)
        : Observable<any> {
        return this.httpClient.put(`${this.path}/${request.id}/revive`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public create()
    : Observable<RoomRequest> {
        return this.httpClient
            .post<void>(this.path, {})
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }

    public evict(request: EvictRoomRequestRequest)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${request.id}/evict`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public extend(id: number)
    : Observable<any> {
        return this.httpClient.post(`${this.path}/${id}/extend`, null)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
