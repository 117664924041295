import { NgModule } from "@angular/core";
import { PipesModule } from "../../core/pipes/pipes.module";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementPaymentEditorModule } from "../user-settlement-payment-editor/user-settlement-payment-editor.module";
import { UserSettlementPaymentCardComponent } from "./user-settlement-payment-card.component";

@NgModule({
    imports: [
        SharedModule,
        PipesModule,
        UserSettlementPaymentEditorModule,
    ],
    declarations: [
        UserSettlementPaymentCardComponent,
    ],
    exports: [
        UserSettlementPaymentCardComponent,
    ],
})
export class UserSettlementPaymentCardModule {}
