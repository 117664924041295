import { Routes } from "@angular/router";

import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { MigrationsComponent } from "./migrations.component";

export const routes: Routes = [
    {
        path: "migrations",
        component: MigrationsComponent,
        canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.seeMigrationsPass,
        }
    }
];
