import { Injectable } from "@angular/core";

import { Privilege } from "../../http-services/privileges/models/privilege.model";
import { IFactory } from "../../../core/models/factory.interface";

@Injectable({
    providedIn: "root",
})
export class PrivilegeFactoryService implements IFactory<Privilege> {

    public create()
    : Privilege {
        return new Privilege({
            privilege: null,
            validSince: null,
            validTo: null,
        });
    }
}
