import { ModuleWithProviders, NgModule } from "@angular/core";

import { RoleGuard } from "./guards/role.guard";

@NgModule()
export class RolesModule {

    public static forRoot()
    : ModuleWithProviders {
        return {
            ngModule: RolesModule,
            providers: [
                RoleGuard,
            ],
        };
    }
}
