import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";

import { PaymentsOptions } from "../../../shared/http-services/payments/models/payment-options.model";
import { PaymentsHttpService } from "../../../shared/http-services/payments/payments-http.service";
import { NewPayment } from "../../../shared/http-services/payments/models/new-payment.model";
import { Payment } from "../../../shared/http-services/payments/models/payment.model";

@Component({
    selector: "app-payments",
    templateUrl: "./payments.component.html",
})
export class PaymentsComponent {

    public payments: Payment[];
    public paymentOptions: PaymentsOptions;

    constructor(
        private paymentsHttpService: PaymentsHttpService,
        private toastrService: ToastrService,
    ) {
        this.loadPayments();
        this.loadOptions();
    }

    public onCreatePayment(payment: NewPayment)
    : void {
        this.paymentsHttpService.create(payment)
            .subscribe(
                () => {
                    this.toastrService.success("Платіж успішно створено.");
                    this.loadPayments();
                    this.loadOptions();
                },
                err => this.toastrService.error(err.message),
            );
    }

    public onCancelPayment(id: number)
    : void {
        this.paymentsHttpService.cancel(id)
            .subscribe(
                () => {
                    this.toastrService.success("Платіж успішно скасовано.");
                    this.loadPayments();
                    this.loadOptions();
                },
                err => this.toastrService.error(err.message),
            );
    }

    private loadOptions()
    : void {
        this.paymentsHttpService.getUserPaymentOptions()
            .subscribe(
                res => this.paymentOptions = res,
                err => this.toastrService.error(err.message)
            );
    }

    private loadPayments()
    : void {
        this.paymentsHttpService.getAll()
            .subscribe(
                res => this.payments = res,
                err => this.toastrService.error(err.message),
            );
    }
}
