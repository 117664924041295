import { NgModule } from "@angular/core";

import { AgGridHelperService } from "./helpers/ag-grid/ag-grid-helper.service";

@NgModule({
    providers: [
        AgGridHelperService,
    ],
})
export class ServicesModule { }
