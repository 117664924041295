import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { RoomRequest } from "../../../shared/http-services/models/room-request.model";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { CreatePaymentForRoomRequestDto } from "../../../shared/http-services/payments/models/create-payment-for-room-request-dto.model";
import { PaymentsOptions } from "../../../shared/http-services/payments/models/payment-options.model";
import { Payment } from "../../../shared/http-services/payments/models/payment.model";
import { UpdatePaymentDto } from "../../../shared/http-services/payments/models/update-payment-dto.model";
import { PaymentsHttpService } from "../../../shared/http-services/payments/payments-http.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { DateHelperService } from "../../../shared/services/helpers/date-helper.service";
import { MigrationRequestCase } from "../../migration-approval/models/migration-request-case.model";

@Injectable()
export class UserSettlementPaymentEditorService {

    constructor(
        private paymentsHttpService: PaymentsHttpService,
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
    ) {}

    public async createPayment(roomRequest: RoomRequest | MigrationRequestCase, payment: Payment)
    : Promise<void> {
        const dto = <CreatePaymentForRoomRequestDto> {
          fromDate: DateHelperService.formatDateIso(payment.from),
          toDate: DateHelperService.formatDateIso(payment.to),
          appliedPrivilege: payment.appliedPrivilege,
          priceInPenny: payment.price * 100,
        };

        try {
            await this.paymentsHttpService.createPaymentForRoomRequest(roomRequest.id, dto).toPromise();

            this.toastrService.success("Платіж успішно доданий");
        } catch (error) {
            this.toastrService.error(error.message);

            throw Error(error.message);
        }
    }

    public async updatePayment(value: Payment)
    : Promise<void> {
        const dto = <UpdatePaymentDto> {
            fromDate: value.from,
            toDate: value.to,
            appliedPrivilege: value.appliedPrivilege,
            priceInPenny: value.price * 100,
        };

        try {
            await this.paymentsHttpService.update(value.id, dto).toPromise();

            this.toastrService.success("Платіж успішно оновлений");
        } catch (error) {
            this.toastrService.error(error.message);

            throw Error(error.message);
        }
    }

    public async loadPaymentOptions()
    : Promise<PaymentsOptions> {
        let options = null;

        try {
            options = await this.paymentsHttpService.getOptions().toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
        }

        return options;
    }

    public async loadPrivilegeOptions()
    : Promise<LocalizedMessage[]> {
        let privilegeOptions = [];

        try {
            privilegeOptions = await this.optionsHttpService.getPrivilegeOptions().toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
        }

        return privilegeOptions;
    }
}
