import { GetDormitoriesRequestFilter} from "./get-dormitories-request-filter.model";
import { FilteredRequest } from "../../models/filtered-request.model";
import { SortedRequest } from "../../models/sorted-request.model";
import { PagedRequest } from "../../models/paged-request.model";

export class GetDormitoriesRequest implements PagedRequest, SortedRequest, FilteredRequest<GetDormitoriesRequestFilter> {

    public filters: GetDormitoriesRequestFilter;
    public page: string;
    public size: string;
    public sort: string;

    constructor(init?: Partial<GetDormitoriesRequest>) {
        Object.assign(this, init);
    }
}
