import { Routes } from "@angular/router";

import { OrganizationComponent } from "./components/organization.component";
import { UsersTabComponent } from "./components/users-tab/users-tab.component";
import { OrderTabComponent } from "./components/order-tab/order-tab.component";
import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";

export const routes: Routes = [
    // {
    // path: "organization",
    // component: OrganizationComponent,
    // canActivate: [ AuthenticationGuard, ProfileFormGuard ],
    // children: [
    //     {
    //         path: "",
    //         redirectTo: "orders",
    //         pathMatch: "full",
    //         canActivate: [ AuthenticationGuard, ProfileFormGuard ],
    //     },
    //     {
    //         path: "users",
    //         component: UsersTabComponent,
    //         canActivate: [ AuthenticationGuard, ProfileFormGuard ],
    //     },
    //     {
    //         path: "orders",
    //         component: OrderTabComponent,
    //         canActivate: [ AuthenticationGuard, ProfileFormGuard ],
    //     }
    // ]},
    // TODO: Permanent workaround, not all organisation tabs are ready
    {
        path: "users",
        component: UsersTabComponent,
        canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.standardAdministrationPass,
        }
    }
];
