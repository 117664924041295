import { Injectable } from "@angular/core";
import { NavigationTab } from "../../../shared/components/navigation-tabs/models/navigation-tab.model";

@Injectable()
export class OrganizationNavService {

    public get navTabs()
    : NavigationTab[] {
        return [
            this.facultiesNavTab,
            this.orderNavTab,
            this.usersNavTab,
        ];
    }

    public get facultiesNavTab()
    : NavigationTab {
        return new NavigationTab({
            link: "uses",
            title: "Факультети",
        });
    }

    public get orderNavTab()
    : NavigationTab {
        return new NavigationTab({
            link: "orders",
            title: "Заява",
        });
    }

    public get usersNavTab()
    : NavigationTab {
        return new NavigationTab({
            link: "users",
            title: "Користувачі",
        });
    }
}
