import { Component, Input } from "@angular/core";

import { Room, RoomStatusName } from "../../shared/models/rooms.model";

@Component({
    selector: "app-room-thumb",
    templateUrl: "./room-thumb.component.html",
    styleUrls: ["./room-thumb.component.scss"]
})
export class RoomThumbComponent {

    @Input()
    public room: Room;
    @Input()
    public showDormitories: boolean;

    public roomStatuses: typeof RoomStatusName;

    constructor() {
        this.roomStatuses = RoomStatusName;
    }

    public get residentsArray()
    : number[] {
        return Array(this.room.maxResidents);
    }
}
