import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MigrationRequestCase } from "../models/migration-request-case.model";
import { PageEvent } from "@angular/material/paginator";

import { PagedSortedResponse } from "../../../shared/http-services/models/paged-sorted-response.model";
import { MigrationApprovalViewApi } from "../models/migration-approval-view-api.model";
import { PagingParams } from "../../../shared/http-services/models/paging-params";

@Component({
    selector: "app-migration-approval-view",
    templateUrl: "./migration-approval-view.component.html",
    styleUrls: ["./migration-approval-view.component.scss"]
})
export class MigrationApprovalViewComponent implements OnInit, OnChanges {

    @Input()
    public activeMigrationsResponse: PagedSortedResponse<MigrationRequestCase>;
    @Input()
    public loading: boolean;
    @Input()
    public changingMigrationStatus: boolean;

    @Output()
    public approveMigration: EventEmitter<MigrationRequestCase>;
    @Output()
    public changePage: EventEmitter<void>;
    @Output()
    public moduleReady: EventEmitter<MigrationApprovalViewApi>;
    @Output()
    public rejectMigration: EventEmitter<MigrationRequestCase>;

    public migrationCases: Array<MigrationRequestCase>;

    public currentPage: number;
    public recordsPerPage: number;
    public totalElements: number;

    constructor(
    ) {
        this.approveMigration = new EventEmitter<MigrationRequestCase>();
        this.changePage = new EventEmitter<void>();
        this.moduleReady = new EventEmitter<MigrationApprovalViewApi>();
        this.rejectMigration = new EventEmitter<MigrationRequestCase>();

        this.initDefaultPagination();
    }

    public ngOnInit()
    : void {
        this.moduleReady.emit(this.migrationApprovalViewApi);
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.activeMigrationsResponse && this.activeMigrationsResponse) {
            this.updatePagination();
        }
    }

    private get migrationApprovalViewApi()
    : MigrationApprovalViewApi {
        return {
            getPagingPayload: this.getPagingPayload.bind(this),
        };
    }

    public onApproveMigration(migrationCase: MigrationRequestCase)
    : void {
        this.approveMigration.emit(migrationCase);
    }

    public onChangePage($event: PageEvent)
    : void {
        this.currentPage = $event.pageIndex;
        this.changePage.emit();
    }

    public onRejectMigration(migrationCase: MigrationRequestCase)
    : void {
        this.rejectMigration.emit(migrationCase);
    }

    private initDefaultPagination()
    : void {
        this.currentPage = 0;
        this.recordsPerPage = 10;
    }

    private updatePagination()
    : void {
        this.migrationCases = this.activeMigrationsResponse.content;
        this.totalElements = this.activeMigrationsResponse.totalElements;
        this.currentPage = this.activeMigrationsResponse.skip / this.activeMigrationsResponse.limit;
    }

    private getPagingPayload()
    : PagingParams {
        return {
            page: this.currentPage,
            size: this.recordsPerPage,
        };
    }
}
