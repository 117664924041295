import { GetFreeRoomsRequestFilter } from "./get-free-rooms-request-filter.model";

export class GetFreeRoomsRequest {

    public filters: GetFreeRoomsRequestFilter;

    constructor(init?: Partial<GetFreeRoomsRequest>) {
        Object.assign(this, init);
    }
}
