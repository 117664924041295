import { DegreesReport } from "./degrees-report.model";

export class FacultiesDegreeReport {

    public dormitoryName: string;
    public degrees: DegreesReport[];

    constructor(init?: Partial<FacultiesDegreeReport>) {
        Object.assign(this, init);
    }
}
