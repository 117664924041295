import { Injectable } from "@angular/core";

import { Option } from "../../../../../core/models/option.model";

@Injectable()
export class RoomControlsService {

    public get genderOptions()
    : Option<boolean>[] {
        return [
            {
                title: "Всі варіанти",
                value: null,
            },
            {
                title: "Жінки",
                value: true,
            },
            {
                title: "Чоловіки",
                value: false,
            },
        ];
    }

    public get sortByOptions()
    : Option[] {
        return [
            {
                title: "",
                value: null,
            },
            {
                title: "Номером кімнати",
                value: "number,ASC",
            },
            {
                title: "Поверхом",
                value: "floor,ASC",
            },
            {
                title: "Гуртожитком",
                value: "dormitory,ASC",
            },
            {
                title: "Місткістю",
                value: "maxResidents,ASC",
            },
        ];
    }

    public get maxResidentsOptions()
    : Option<number>[] {
        return [
            {
                title: "1",
                value: 1,
            },
            {
                title: "2",
                value: 2,
            },
            {
                title: "3",
                value: 3,
            },
            {
                title: "4",
                value: 4,
            },
            {
                title: "5",
                value: 5,
            },
            {
                title: "6",
                value: 6,
            },
            {
                title: "7",
                value: 7,
            },
            {
                title: "8",
                value: 8,
            },

        ];
    }
}
