export const environment = {
  apiUrl: "https://ddms.usic.org.ua/api",
  production: false,
  googleAnalyticsKey: "",
  config: {
      cacheLocation: "localStorage",
      redirectUri: "https://ddms.usic.org.ua/",
      navigateToLoginRequestUrl: true,
      tenant: "b8cbfe43-c90c-4bea-84ae-be5d6d8a5f52",
      clientId: "480c7cad-4e6d-4b48-9e6d-65876adb2e9a"
  }
};
