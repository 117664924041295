import { LocalizedMessage } from "../../../models/privilege-status.model";

export class Payment {

    public id: number;
    public cancelable: boolean;
    public description: string;
    public from: string;
    public payable: boolean;
    public price: number;
    public status: LocalizedMessage;
    public to: string;
    public appliedPrivilege: string;

    constructor(init?: Partial<Payment>) {
        Object.assign(this, init);
    }
}
