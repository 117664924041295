import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";

import { ModalApi } from "../../../../shared/models/modal-api.interface";

@Component({
    selector: "app-migration-request-modal",
    styleUrls: [ "./migration-request-modal.component.scss", ],
    templateUrl: "./migration-request-modal.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class MigrationRequestModalComponent implements OnInit {

    @ViewChild("migrationRequestModal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public modalReady: EventEmitter<ModalApi>;
    @Output()
    public requestAdded: EventEmitter<void>;
    @Output()
    public addRequestFailed: EventEmitter<void>;

    public opened: boolean;
    public saving: boolean;

    constructor() {
        this.addRequestFailed = new EventEmitter<void>();
        this.modalReady = new EventEmitter<ModalApi>();
        this.requestAdded = new EventEmitter<void>();
    }

    public ngOnInit()
    : void {
        this.onModalReady();
    }

    public onAddRequestFailed()
    : void {
        this.addRequestFailed.emit();
        this.modalComponent.close();
    }

    public onRequestAdded()
    : void {
        this.requestAdded.emit();
        this.modalComponent.close();
    }

    public onSavingFinished()
    : void {
        this.saving = false;
    }

    public onSavingStarted()
    : void {
        this.saving = true;
    }

    private onModalReady()
    : void {
        this.modalComponent.onClose.subscribe(() => this.opened = false);

        this.modalReady.emit({
           open: () => {
               this.opened = true;
               this.modalComponent.open();
            },
        });
    }
}
