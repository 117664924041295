import { ModuleWithProviders, NgModule } from "@angular/core";

import { ProfileFormGuard } from "./guards/profile-form.guard";

@NgModule()
export class ProfileModule {

    public static forRoot()
    : ModuleWithProviders {
        return {
            ngModule: ProfileModule,
            providers: [
                ProfileFormGuard,
            ],
        };
    }
}
