import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { UsersHttpService } from "../../../shared/http-services/users/users-http.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";

@Injectable()
export class UserRolesEditorService {

    constructor(
       private optionsHttpService: OptionsHttpService,
       private toastrService: ToastrService,
       private usersHttpService: UsersHttpService,
    ) {}

    public async loadRolesOptions()
    : Promise<LocalizedMessage[]> {
        let roles = [];

        try {
            roles = await this.optionsHttpService.getRoleOptions().toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
        }

        return roles;
    }

    public async updateUserRoles(userEmail: string, roles: string[])
    : Promise<void> {
        const toast = this.toastrService.info("Оновлення інформації...");

        try {
            await this.usersHttpService.updateUserRoles(userEmail, roles).toPromise();
            this.toastrService.success("Ролі успішно збережені");
        } catch (error) {
            this.toastrService.error(error.message);
        }

        this.toastrService.remove(toast.toastId);
    }
}
