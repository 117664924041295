import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { BuildingsEditorApi } from "./models/buildings-editor-api.model";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

import { AbstractFormBasedComponent } from "../../../../core/components/abstract-form-based.component";
import { BuildingsEditorConfig } from "./models/buildings-editor-config.model";
import { Building } from "../../../../shared/models/building.model";
import { DefaultBuildingFactoryService } from "./services/default-building-factory.service";
import { BuildingsEditorFormService } from "./services/buildings-editor-form.service";
import { BuildingHttpService } from "../../../../shared/http-services/buildings/building-http.service";
import { UserRolesStateService } from "../../../../core/roles/services/user-roles-state.service";
import { BuildingsEditorStateEnum } from "./models/buildings-editor-state.enum";

@Component({
    selector: "app-buildings-editor",
    templateUrl: "./buildings-editor.component.html",
    styleUrls: [ "./buildings-editor.component.scss", ],
    providers: [
        BuildingsEditorFormService,
    ]
})
export class BuildingsEditorComponent extends AbstractFormBasedComponent implements OnInit {

    @ViewChild("addBuilding")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public councilsUpdated: EventEmitter<void>;
    @Output()
    public editorReady: EventEmitter<BuildingsEditorApi>;

    public building: Building;
    public form: FormGroup;

    private config: BuildingsEditorConfig;

    constructor(
        private defaultBuildingFactoryService: DefaultBuildingFactoryService,
        private buildingsEditorFormService: BuildingsEditorFormService,
        private buildingHttpService: BuildingHttpService,
        private toastrService: ToastrService,
        private userRolesStateService: UserRolesStateService,
    ) {
        super();

        this.councilsUpdated = new EventEmitter<void>();
        this.editorReady = new EventEmitter<BuildingsEditorApi>();

    }

    public ngOnInit()
    : void {
        this.onEditorReady();
    }

    public get isInAddMode()
    : boolean {
        return this.config && this.config.state === BuildingsEditorStateEnum.Add;
    }

    public get isInEditMode()
    : boolean {
        return this.config && this.config.state === BuildingsEditorStateEnum.Edit;
    }

    public get isInViewMode()
    : boolean {
        return this.config && this.config.state === BuildingsEditorStateEnum.View;
    }

    public get title()
    : string | null {
        if (!this.config) {
            return null;
        }

        return this.config.state === BuildingsEditorStateEnum.Add
            ? "Додати гуртожиток"
            : "Гуртожиток";
    }

    private get api()
    : BuildingsEditorApi {
        return new BuildingsEditorApi({
            open: this.open.bind(this),
        });
    }

    private get isFormValid()
    : boolean {
        this.markAllFormControlsTouched(this.form);

        return this.form.valid;
    }

    private get updatedBuilding()
    : Building {
        return {
            ...this.building,
            ...this.buildingsEditorFormService.getRoomFromForm(this.form),
        };
    }

    public onCouncilsUpdated()
    : void {
        this.buildingHttpService.getDormitory(this.building.id.toString())
            .subscribe(
                building => {
                    this.building = building;
                    this.councilsUpdated.emit();
                },
                err => this.toastrService.error(err.message)
            );
    }

    public onSave()
    : void {
        if (!this.isFormValid) {
            return ;
        }

        this.onClose();
        this.config.onSave(this.updatedBuilding);
    }

    public open(config: BuildingsEditorConfig)
    : void {
        this.config = config;
        this.initBuilding();

        this.modalComponent.open();
    }

    private initBuilding()
    : void {
        if (this.isInEditMode || this.isInViewMode) {
            this.building = this.config.initialValue;
        } else if (this.isInAddMode) {
            this.building = this.defaultBuildingFactoryService.create();
        }

        this.form = this.buildingsEditorFormService.buildGroup(this.building);
    }

    private onClose()
    : void {
        if (!this.isInEditMode) {
            this.modalComponent.removeData();
            this.modalComponent.close();
        }
    }

    private onEditorReady()
    : void {
        this.editorReady.emit(this.api);
    }
}
