import { ModuleWithProviders, NgModule } from "@angular/core";

import { LocalizedValuePipe } from "./localized-value.pipe";
import { UserDatePipe } from "./user-date.pipe";
import { RenderValidityPipe } from "./render-validity.pipe";
import { UserDateFullMonthPipe } from "./user-date-full-month.pipe";

@NgModule({
    declarations: [
        LocalizedValuePipe,
        UserDatePipe,
        UserDateFullMonthPipe,
        RenderValidityPipe,
    ],
    exports: [
        LocalizedValuePipe,
        UserDatePipe,
        RenderValidityPipe,
        UserDateFullMonthPipe,
    ],
})
export class PipesModule {
    public static forRoot()
    : ModuleWithProviders {
        return {
            ngModule: PipesModule,
            providers: [
                // @angular pipes
                // custom pipes
                LocalizedValuePipe,
                UserDatePipe,
            ],
        };
    }
}
