import { LocalizedMessage } from "./privilege-status.model";
import { LivingRoomRequestModel } from "./living-room-request.model";
import { Period } from "../http-services/periods/models/period.model";

export class User {

    public id: number;
    public cancellable: boolean;
    public email: string;
    public evictable: boolean;
    public username: string;
    public settlementAllowedForPeriod?: Period;
    public dateBirth: string;
    public gender: LocalizedMessage;
    public degree: LocalizedMessage;
    public faculty: LocalizedMessage;
    public speciality: LocalizedMessage;
    public educationType: LocalizedMessage;
    public roles: LocalizedMessage[];
    public livingRoomRequest: LivingRoomRequestModel;
    public banned: boolean;

    constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }
}
