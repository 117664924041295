export class GetOrdersConfig {

    public size: number;
    public page: number;
    public sortOptions: any = {};

    constructor(init?: Partial<GetOrdersConfig>) {
        Object.assign(this, init);
    }
}
