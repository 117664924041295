import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material";

import { User } from "../../../../../shared/models/user.model";

@Component({
    selector: "app-user-search-view",
    templateUrl: "./user-search.component.html",
    styleUrls: ["./user-search.component.scss"]
})
export class UserSearchComponent {

    @Input()
    public options: User[];

    @Output()
    public search: EventEmitter<string>;
    @Output()
    public userSelect: EventEmitter<User>;

    public displayOptionFn: Function;
    public searchString: string;

    constructor() {
        this.search = new EventEmitter<string>();
        this.userSelect = new EventEmitter<User>();

        this.resetSearchString();

        this.displayOptionFn = (option: User) => option && option.username;
    }

    public onInputChange(value: string)
    : void {
        this.search.emit(value);
    }

    public onUserSelect(event: MatAutocompleteSelectedEvent)
    : void {
        setTimeout(() => {
            this.resetSearchString();
            this.userSelect.emit(event.option.value);
        });
    }

    private resetSearchString()
    : void {
        this.searchString = "";
    }
}
