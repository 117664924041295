import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { Payment } from "../../../../shared/http-services/payments/models/payment.model";
import { ExtendedProfile } from "../../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileHttpService } from "../../../../shared/http-services/profile/profile-http.service";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { OptionsHttpService } from "../../../../shared/http-services/options/options-http.service";

@Component({
    selector: "app-payment-list",
    templateUrl: "./payment-list.component.html",
    styleUrls: ["./payment-list.component.scss"],
})
export class PaymentListComponent {

    @Input()
    public payments: Payment[];

    @Output()
    public cancelPayment: EventEmitter<number>;

    public privilegeOptions$: Observable<LocalizedMessage[]>;
    public profile: ExtendedProfile;

    constructor(
        private profileHttpService: ProfileHttpService,
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
    ) {
        this.loadProfile();

        this.cancelPayment = new EventEmitter<number>();
        this.privilegeOptions$ = this.optionsHttpService.getPrivilegeOptions();
    }

    public onDeletePayment(id: number)
    : void {
        this.cancelPayment.emit(id);
    }

    private loadProfile()
    : void {
        this.profileHttpService.getProfile()
            .subscribe(
                res => this.profile = res,
                err => this.toastrService.error(err.message),
            );
    }
}
