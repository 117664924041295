import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RoomDetails } from "../../models/room-details.model";

import { GetRoomsRequest } from "../models/get-rooms-request.model";
import { AbstractHttpService } from "../abstract-http.service";
import { Room, RoomStatus } from "../../models/rooms.model";
import { WrappedError } from "../models/wrapped-error.model";
import { GetFreeRoomsRequest } from "./models/get-free-rooms-request.model";
import { EditRoom } from "../../models/edit-room.model";

@Injectable({
    providedIn: "root"
})
export class RoomHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/rooms`;
    }

    public getFreeRooms(request?: GetFreeRoomsRequest)
    : Observable<Room[]> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient
            .get(`${this.path}/free`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRooms(request: GetRoomsRequest)
    : Observable<Room[]> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient
            .get(this.path, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoomStatuses()
    : Observable<RoomStatus[]> {
        return this.httpClient.get(`${this.apiUrl}/room-statuses/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoom(id: number)
    : Observable<RoomDetails> {
        return this.httpClient
            .get(`${this.path}/${id}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public postRoom(room: EditRoom)
    : Observable<any> {
        return this.httpClient
            .post(`${this.path}`, room)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
    public putRoom(room: EditRoom, id: number)
    : Observable<any> {
        return this.httpClient
            .put(`${this.path}/${id}`, room)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public deleteRoom(id: number): Observable<any> {
        return this.httpClient
            .delete(`${this.path}/${id}`)
            .pipe(
                catchError<WrappedError, never>(this.handleError.bind(this))
            );
    }
}
