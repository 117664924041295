import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { FormGroup } from "@angular/forms";

import { PeriodEditorFormService } from "./services/period-editor-form.service";
import { PeriodEditorConfig } from "./models/period-editor-config.model";
import { PeriodEditorApi } from "./models/period-editor-api.model";
import { Period } from "../../../../shared/http-services/periods/models/period.model";
import { AbstractFormBasedComponent } from "../../../../core/components/abstract-form-based.component";

@Component({
    selector: "app-period-editor",
    templateUrl: "./period-editor.component.html",
    styleUrls: [ "./period-editor.component.scss" ],
    providers: [
        PeriodEditorFormService,
    ],
})
export class PeriodEditorComponent extends AbstractFormBasedComponent implements OnInit {

    @ViewChild("modal")
    public modal: NgxSmartModalComponent;

    @Input()
    public loading: boolean;

    @Output()
    public create: EventEmitter<Period>;
    @Output()
    public ready: EventEmitter<PeriodEditorApi>;
    @Output()
    public update: EventEmitter<Period>;

    public form: FormGroup;

    private config: PeriodEditorConfig;

    constructor(
       private periodEditorFormService: PeriodEditorFormService,
    ) {
        super();

        this.create = new EventEmitter<Period>();
        this.ready = new EventEmitter<PeriodEditorApi>();
        this.update = new EventEmitter<Period>();
    }

    public ngOnInit()
    : void {
        this.ready.emit(this.api);
    }

    public get title()
    : string {
        return this.isInCreateMode
            ? "Створити Період"
            : "Редагувати Період";
    }

    private get api()
    : PeriodEditorApi {
        return new PeriodEditorApi({
           open: this.openModal.bind(this),
        });
    }

    private get isInCreateMode()
    : boolean {
        return this.config && !this.config.period;
    }

    public onCancel()
    : void {
        this.form = this.periodEditorFormService.buildForm();
        this.modal.close();
    }

    public onSubmit()
    : void {
        this.markAllFormControlsTouched(this.form);

        if (!this.form.valid) {
            return;
        }

        const model = this.periodEditorFormService.mapToModel(this.form);

        this.isInCreateMode
            ? this.create.emit(model)
            : this.update.emit(model);

        this.modal.close();
    }

    private initForm()
    : void {
        this.form = this.periodEditorFormService.buildForm(this.config.period);
    }

    private openModal(config: PeriodEditorConfig)
    : void {
        this.config = config;

        this.modal.open();
        this.initForm();
    }
}
