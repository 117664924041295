import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { FilterUserModel } from "../../../../../shared/http-services/users/models/filter.user.model";

@Injectable()
export class UsersTabFilterFormService {

    constructor(
        private formBuilder: FormBuilder,
    ) {}

    public buildForm()
        : FormGroup {
        return this.formBuilder.group({
            role:[null],
            degree: [ null ],
            faculty: [ null ],
            speciality: [ null ],
            username:[null]
        });
    }

    public mapToModel(form: FormGroup)
        : FilterUserModel {
        const { degree, faculty, speciality, username, role} = form.value;

        return new FilterUserModel({
            degree, faculty, speciality, username,role
        });
    }
}
