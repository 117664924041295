import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserRolesEditorComponent } from "./user-roles-editor.component";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        UserRolesEditorComponent,
    ],
    exports: [
        UserRolesEditorComponent,
    ]
})
export class UserRolesEditorModule {}
