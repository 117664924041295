import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { AdalService } from "adal-angular4";
import { BehaviorSubject, Observable } from "rxjs";
import * as _ from "lodash";

import { ProfileHttpService } from "../../http-services/profile/profile-http.service";
import { UserRole } from "../../enums/user-role.enum";
import { Link } from "../../models/link.model";
import { LoginProcessService } from "../helpers/login-process.service";
import { RolePasses } from "../../../core/roles/rules/roles-passes.class";

@Injectable({
    providedIn: "root",
})
export class NavigationService {

    public navigationLinks$: Observable<Link[]>;

    private navigationLinksSubject: BehaviorSubject<Link[]>;

    constructor(
        private adalService: AdalService,
        private loginProcessService: LoginProcessService,
        private navigationService: NavigationService,
        private profileHttpService: ProfileHttpService,
    ) {
        this.navigationLinksSubject = new BehaviorSubject<Link[]>(null);
        this.navigationLinks$ = this.navigationLinksSubject.asObservable();

        this.initNavigationLinks();
    }

    private getNavigation(userRoles: string[])
    : Link[] {
        const navigation = [];

        addToNavigationIfFitsRoles(
            RolePasses.studentPass,
            this.getStudentNavigation()
        );
        addToNavigationIfFitsRoles(
            RolePasses.extendedAdminPass,
            this.getExtendedAdminNavigation()
        );
        addToNavigationIfFitsRoles(
            RolePasses.standardAdministrationPass,
            this.getBaseAdminNavigation()
        );
        addToNavigationIfFitsRoles(
            RolePasses.seeMigrationsPass,
            this.getMigrationNavigation()
        );
        addToNavigationIfFitsRoles(
            RolePasses.canViewReports,
            this.getReportsNavigation(),
        );
        addToNavigationIfFitsRoles(
            RolePasses.canViewLogs,
            this.getLogsNavigation(),
        );

        return _.uniqBy(navigation, "link");

        // region Local Functions

        function addToNavigationIfFitsRoles(roles: UserRole[], links: Link[])
            : void {
            const hasRole = roles.find(role => userRoles.includes(role));

            if (hasRole) {
                navigation.push(...links);
            }
        }

        // endregion
    }

    private getStudentNavigation()
    : Link[] {
        return [
            {
                title: "поселення",
                link: "/dashboard",
                icon: "fas fa-door-open",
            },
            {
                title: "оплата",
                link: "/payments",
                icon: "fas fa-money-bill-wave",
            },
        ];
    }

    private getMigrationNavigation()
    : Link[] {
        return [
            {
                title: "переселення",
                link: "/migrations",
                icon: "fas fa-exchange-alt"
            }
        ];
    }

    private getBaseAdminNavigation()
    : Link[] {
        return [
            {
                title: "гуртожитки",
                link: "/dormitories",
                icon: "fas fa-building",
                children: [
                    {
                        title: "Будівлі",
                        icon: "fas fa-sliders-h",
                        link: "/dormitories/buildings",
                    },
                    {
                        title: "Кімнати",
                        icon: "fas fa-sliders-h",
                        link: "/dormitories/rooms",
                    },
                ]
            },
            {
                title: "користувачі",
                link: "/users",
                icon: "fas fa-users-cog",
            },
        ];
    }

    private getExtendedAdminNavigation()
    : Link[] {
        return [
            ...this.getBaseAdminNavigation(),
            {
                title: "Система",
                icon: "fas fa-sliders-h",
                children: [
                    {
                        title: "Система",
                        icon: "fas fa-sliders-h",
                        link: "/properties/system",
                    },
                    {
                        title: "Періоди",
                        icon: "fas fa-history",
                        link: "/properties/periods",
                    },
                ],
            },
        ];
    }

    private getReportsNavigation()
    : Link[] {
        return [
            {
                title: "Звіти",
                link: "/reports",
                icon: "fas fa-table",
            },
        ];
    }

    private getLogsNavigation()
    : Link[] {
        return [
            {
                title: "Логи",
                link: "/logs",
                icon: "fas fa-file-alt",
            },
        ];
    }

    private initNavigationLinks()
    : void {
        this.adalService
            .getUser()
            .pipe(
                switchMap(() => this.loginProcessService.whenTokenApplied$),
                switchMap(() => this.profileHttpService.getMyRoles()),
            ).subscribe(res => {
            this.navigationLinksSubject.next(this.getNavigation(res.roles));
        });
    }
}
