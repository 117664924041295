import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: "app-pager",
    templateUrl: "./pager.component.html",
    styleUrls: ["./pager.component.scss"]
})
export class PagerComponent implements OnInit {

    @Input() public currentPage: number = 0;
    @Input() public totalPages: number = 0;

    @Output() public pageEvent: EventEmitter<number>;

    public pagesArray: String[];

    private arrowR = "\uD83E\uDC7A";
    private arrowL = "\uD83E\uDC78";

    constructor() {
        this.pageEvent = new EventEmitter<number>();
    }

    public ngOnInit()
    : void {

        this.pagesArray = [];

        if (this.totalPages <= 6) {
            for (let i = 0; i < this.totalPages; i++) {
                this.pagesArray.push((i + 1).toString());
            }
        } else {

            if (this.currentPage <= 5) {
                this.caseFirst5();
            } else if (this.currentPage >= this.totalPages - 4) {
                this.caseLast5();
            } else {
                this.caseOthers();
            }
        }
    }

    public changePagination(page: String)
    : void {
        if (this.totalPages > 6) {

            if (+page == 1) {

                this.currentPage = 1;
            } else if (+page == this.totalPages) {

                this.currentPage = this.totalPages;

            } else if (page == this.arrowL) {

                const prevPage = this.pagesArray[2];
                const prevPageN = +prevPage;
                this.currentPage = prevPageN - 1;

            } else if (page == this.arrowR) {

                const prevPage = this.pagesArray[4];
                const prevPageN = +prevPage;
                this.currentPage = prevPageN + 1;

            } else {

                this.currentPage = +page;
            }
        } else {

            this.currentPage = +page;
        }

        this.pageEvent.emit(this.currentPage - 1);
    }

    private caseFirst5()
    : void {
        this.pagesArray = [
            "1",
            "2",
            "3",
            "4",
            "5",
            this.arrowR,
            (this.totalPages).toString()
        ];
    }

    private caseOthers()
    : void {
        this.pagesArray = [
            "1",
            this.arrowL,
            (this.currentPage - 1).toString(),
            (this.currentPage).toString(),
            (this.currentPage + 1).toString(),
            this.arrowR,
            (this.totalPages).toString()
        ];
    }

    private caseLast5()
    : void {
        this.pagesArray = [
            "1",
            this.arrowL,
            (this.totalPages - 4).toString(),
            (this.totalPages - 3).toString(),
            (this.totalPages - 2).toString(),
            (this.totalPages - 1).toString(),
            (this.totalPages).toString()
        ];
    }
}
