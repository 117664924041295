import { Routes } from "@angular/router";
import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";
import { UserDetailsComponent } from "./user-details.component";

export const routes: Routes = [
    {
        path: "user-details/:id",
        component: UserDetailsComponent,
        canActivate: [ AuthenticationGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.standardAdministrationPass,
        }
    }
];
