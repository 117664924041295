import { ValueFormatterParams } from "ag-grid-community";
import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";

import { DateHelperService } from "../date-helper.service";

@Injectable({
    providedIn: "root"
})
export class ValueFormatterService {

    constructor(
        private currencyPipe: CurrencyPipe,
    ) {}

    public coinsToUahWLabelFormatter()
    : Function {
        return (params: ValueFormatterParams): string => {
            const value = params.value / 100;
            return this.currencyPipe.transform(value, "UAH", "symbol-narrow", "1.0-2");
        };
    }

    public dateTimeFormatter()
    : Function {
        return (params: ValueFormatterParams): string => {
            const dateTime = params.value;

            return DateHelperService.formatDate(dateTime) + " " + DateHelperService.formatTime(dateTime);
        };
    }

    public dateFormatter()
        : Function {
        return (params: ValueFormatterParams): string => {
            const dateTime = params.value;
            return DateHelperService.formatDate(dateTime);
        };
    }
}
