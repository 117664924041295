import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Privilege } from "../privileges/models/privilege.model";
import { AbstractHttpService } from "../abstract-http.service";

@Injectable({
    providedIn: "root"
})
export class ClientPrivilegesHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/users/me/privileges`;
    }

    public add(value: Privilege)
    : Observable<void> {
        return this.httpClient
            .post<void>(this.path, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }

    public delete(id: number)
    : Observable<void> {
        return this.httpClient
            .delete(`${this.path}/${id}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }
}
