import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Building } from "../../models/building.model";
import { AbstractHttpService } from "../abstract-http.service";
import { DormitoriesResponse } from "./models/dormitories-response.model";
import { GetDormitoriesRequest } from "./models/get-dormitories-request.model";
import { DormitoryCouncilRequest } from "./models/dormitory-council-request.model";
import { ManagerRequest } from "./models/manager-request.model";

@Injectable({
    providedIn: "root"
})
export class BuildingHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/dormitories`;
    }

    public addDormitoryCouncil(request: DormitoryCouncilRequest)
    : Observable<any> {
        return this.httpClient
            .put(`${this.path}/${request.dormitoryId}/council/${request.userEmail}`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getDormitories(request: GetDormitoriesRequest)
    : Observable<DormitoriesResponse> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient
            .get(`${this.path}`, {params: params})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public postDormitories(building: Building)
    : Observable<any> {
        return this.httpClient
            .post(`${this.path}/`, building)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getDormitory(id: string)
    : Observable<Building> {
        const params = { id };

        return this.httpClient
            .get(`${this.path}/${id}`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public putDormitory(building: Building)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${building.id}`, building)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public removeDormitoryCouncil(request: DormitoryCouncilRequest)
    : Observable<any> {
        return this.httpClient
            .delete(`${this.path}/${request.dormitoryId}/council/${request.userEmail}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public deleteDormitories(id)
    : Observable<any> {
        return this.httpClient.delete(`${this.path}/${id}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public addManager(request: ManagerRequest)
    : Observable<any> {
        return this.httpClient
            .put(`${this.path}/${request.dormitoryId}/manager/${request.userEmail}`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public removeManager(request: ManagerRequest)
    : Observable<any> {
        return this.httpClient
            .delete(`${this.path}/${request.dormitoryId}/manager/${request.userEmail}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
