import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";

import {OrderTabFormService} from "./services/order-tab-form.service";

@Component({
    selector: "app-search-name-form",
    templateUrl: "./search-name-form.component.html",
})
export class SearchNameFormComponent implements OnInit {

    @Output()
    public userNameEvent: EventEmitter<String>;

    public searchForm: FormGroup;

    constructor(
        private orderTabFormService: OrderTabFormService
    ) {
        this.userNameEvent = new EventEmitter<String>();
    }

    public ngOnInit()
    : void {
        this.initForm();
    }

    private initForm()
    : void {
        this.searchForm = this.orderTabFormService.buildForm();
    }

    public onSubmit()
    : void {
        this.userNameEvent.emit(this.searchForm.value.username);
    }
}
