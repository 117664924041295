import { NgModule } from "@angular/core";
import { PipesModule } from "../../core/pipes/pipes.module";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementPaymentsModule } from "../user-settlement-payments/user-settlement-payments.module";
import { UserSettlementCardComponent } from "./user-settlement-card.component";

@NgModule({
    imports: [
        SharedModule,
        PipesModule,
        UserSettlementPaymentsModule,
    ],
    declarations: [
        UserSettlementCardComponent,
    ],
    exports: [
        UserSettlementCardComponent,
    ]
})
export class UserSettlementCardModule {}
