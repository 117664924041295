import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { AddPrivilegeConfig } from "../../../shared/models/add-privilege-config.model";
import { ModalApi } from "../../../shared/models/modal-api.interface";

@Component({
    selector: "app-dashboard",
    styleUrls: [ "./dashboard.component.scss" ],
    templateUrl: "./dashboard.component.html",
})
export class DashboardComponent {

    @Input()
    public profile: ExtendedProfile;
    @Input()
    public degreeOptions: LocalizedMessage[];
    @Input()
    public genderOptions: LocalizedMessage[];
    @Input()
    public educationTypeOptions: LocalizedMessage[];
    @Input()
    public facultyOptions: LocalizedMessage[];
    @Input()
    public privilegeOptions: LocalizedMessage[];
    @Input()
    public specialityOptions: LocalizedMessage[];

    @Output()
    public addNewPrivilege: EventEmitter<AddPrivilegeConfig>;
    @Output()
    public deletePrivilege: EventEmitter<number>;
    @Output()
    public reloadProfile: EventEmitter<void>;

    private documentsModalApi: ModalApi;

    constructor() {
        this.addNewPrivilege = new EventEmitter<AddPrivilegeConfig>();
        this.deletePrivilege = new EventEmitter<number>();
        this.reloadProfile = new EventEmitter<void>();
    }

    public get loaded()
    : boolean {
        return this.degreeOptions &&
            this.educationTypeOptions &&
            this.facultyOptions &&
            this.privilegeOptions &&
            this.genderOptions &&
            !!this.profile;
    }

    public onAddNewPrivilege(config: AddPrivilegeConfig)
    : void {
        this.addNewPrivilege.emit(config);
    }

    public onDeletePrivilege(id: number)
    : void {
        this.deletePrivilege.emit(id);
    }

    public onDocumentsModalReady(api: ModalApi)
    : void {
        this.documentsModalApi = api;
    }

    public onOpenDocuments()
    : void {
        this.documentsModalApi.open();
    }

    public onLoadRoomRequests()
    : void {
        this.reloadProfile.emit();
    }
}
