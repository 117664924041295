import { SortedRequest } from "./sorted-request.model";
import { PagedRequest } from "./paged-request.model";

export class GetRoomsRequest implements PagedRequest, SortedRequest {

    public page: string;
    public size: string;
    public state: string;
    public sort: string;

    constructor(init?: Partial<GetRoomsRequest>) {
        Object.assign(this, init);
    }
}
