import { Component, OnDestroy, OnInit } from "@angular/core";
import { untilDestroyed } from "ngx-take-until-destroy";
import { ToastrService } from "ngx-toastr";
import { PageEvent } from "@angular/material";

import { Building } from "../../../../shared/models/building.model";
import { BuildingHttpService } from "../../../../shared/http-services/buildings/building-http.service";
import { BuildingsEditorConfig } from "../buildings-editor/models/buildings-editor-config.model";
import { BuildingsEditorApi } from "../buildings-editor/models/buildings-editor-api.model";
import { GetDormitoriesRequest } from "../../../../shared/http-services/buildings/models/get-dormitories-request.model";
import { WrappedError } from "../../../../shared/http-services/models/wrapped-error.model";
import { GetDormitoriesRequestFilter } from "../../../../shared/http-services/buildings/models/get-dormitories-request-filter.model";
import { RolePasses } from "../../../../core/roles/rules/roles-passes.class";
import { UserRolesStateService } from "../../../../core/roles/services/user-roles-state.service";
import { BuildingsEditorStateEnum } from "../buildings-editor/models/buildings-editor-state.enum";

@Component({
    selector: "app-buildings-tab",
    templateUrl: "./buildings-tab.component.html",
    styleUrls: ["./buildings-tab.component.scss"]
})
export class BuildingsTabComponent implements OnInit, OnDestroy {

    public readonly pageSizeOptions: number[];

    public canEdit: boolean;
    public currentPage: number;
    public totalRecords: number;
    public pageSize: number;
    public buildings: Building[];
    public showActive: boolean;
    public isLoadedInitially: boolean;

    private buildingsEditorApi: BuildingsEditorApi;

    constructor(
        private buildingHttpService: BuildingHttpService,
        private toastrService: ToastrService,
        private userRolesStateService: UserRolesStateService,
    ) {
        this.currentPage = 0;
        this.pageSize = 20;
        this.pageSizeOptions = [5, 10, 20, 50];

        this.initCanEdit();
    }

    public get request()
    : GetDormitoriesRequest {
        const request = new GetDormitoriesRequest( {
            page: this.currentPage.toString(),
            size: this.pageSize.toString(),
            filters: new GetDormitoriesRequestFilter()
        });

        if (!this.showActive) {
            request.filters.active = true;
        }

        return request;
    }

    public ngOnInit()
    : void {
        this.buildings = [];

        this.loadBuildings();

        this.showActive = false;
    }

    public ngOnDestroy()
    : void {
    }

    public onAddNew()
    : void {
        const config = new BuildingsEditorConfig({
            state: BuildingsEditorStateEnum.Add,
            onSave: this.addDormitory.bind(this),
        });

        this.buildingsEditorApi.open(config);
    }

    public onBuildingsEditorReady(api: BuildingsEditorApi)
    : void {
        this.buildingsEditorApi = api;
    }

    public onCouncilsUpdated()
    : void {
        this.loadBuildings();
    }

    public changePage(pageEvent: PageEvent)
    : void {
        this.pageSize = pageEvent.pageSize;
        this.currentPage = pageEvent.pageIndex;
        this.loadBuildings();
    }

    public openInfo(building: Building)
    : void {
        const config = new BuildingsEditorConfig({
            state: this.canEdit ? BuildingsEditorStateEnum.Edit : BuildingsEditorStateEnum.View,
            initialValue: building,
            onSave: this.putDormitory.bind(this),
        });

        this.buildingsEditorApi.open(config);
    }

    public addDormitory(value: Building)
    : void {
        this.buildingHttpService.postDormitories(value)
            .subscribe(() => {
                this.loadBuildings();
                this.toastrService.success("Доданий гуртожиток");
            }, error => this.toastrService.error(error.message));
    }

    public putDormitory(building: Building)
    : void {
        this.buildingHttpService.putDormitory(building)
            .subscribe(
                () => {
                    this.loadBuildings();
                    this.toastrService.success("Гуртожиток збережено");
                },
                error => this.toastrService.error(error.message)
            );
    }

    private initCanEdit()
    : void {
        this.userRolesStateService.getIsInRole(RolePasses.canEditBuildingsPass)
            .pipe(untilDestroyed(this))
            .subscribe(res => this.canEdit = res);
    }

    private loadBuildings()
    : void {
        this.buildingHttpService.getDormitories(this.request)
            .subscribe(res => {
            this.buildings = res.content;

            this.totalRecords = res.totalElements;
            this.currentPage = res.skip / this.pageSize;

            this.isLoadedInitially = true;
        },
        (error: WrappedError) => this.toastrService.error(error.message));
    }

    public changeActive($event: boolean)
    : void {
        this.showActive = $event;
        this.loadBuildings();
    }
}
