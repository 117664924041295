import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

import * as appConstants from "../constants/app-constants";

@Pipe({
    name: "userDate"
})
export class UserDatePipe implements PipeTransform {

    public transform(value: string)
    : string | null {
        return moment(value).format(appConstants.userDateFormat);
    }
}
