import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { CreatePaymentForRoomRequestDto } from "./models/create-payment-for-room-request-dto.model";
import { NewPayment } from "./models/new-payment.model";
import { Payment } from "./models/payment.model";
import { PaymentsOptions } from "./models/payment-options.model";
import { UpdatePaymentDto } from "./models/update-payment-dto.model";

@Injectable({
    providedIn: "root"
})
export class PaymentsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/payments`;
    }

    public create(value: NewPayment)
    : Observable<any> {
        return this.httpClient.post(`${this.path}`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public createPaymentForRoomRequest(roomRequestId: number, payment: CreatePaymentForRoomRequestDto)
    : Observable<void> {
        return this.httpClient.post(`${this.apiUrl}/room-request/${roomRequestId}/payments`, payment)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public update(id: number, value: UpdatePaymentDto)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${id}`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public cancel(id: number)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${id}/cancel`, null)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getAll()
    : Observable<Payment[]> {
        return this.httpClient.get(`${this.path}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getOptions()
    : Observable<PaymentsOptions> {
        return this.httpClient.get(`${this.path}/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getUserPaymentOptions(): Observable<PaymentsOptions> {
        return this.httpClient.get(`${this.path}/users/me/roomRequest/active/payments/options`)
                   .pipe(
                       catchError<any, never>(this.handleError.bind(this))
                   );
    }

    public markAsPaid(paymentId: number)
    : Observable<void> {
        return this.httpClient.put<void>(`${this.path}/${paymentId}/mark-paid`, {
            paymentId
        });
    }

    public markAsCreated(paymentId: number)
    : Observable<void> {
        return this.httpClient.put<void>(`${this.path}/${paymentId}/mark-created`, {
            paymentId
        });
    }
}
