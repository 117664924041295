import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { RoomRequest } from "../../shared/http-services/models/room-request.model";
import { Payment } from "../../shared/http-services/payments/models/payment.model";
import { MigrationRequestCase } from "../migration-approval/models/migration-request-case.model";
import {
    UserSettlementPaymentEditorInterface
} from "../user-settlement-payment-editor/interfaces/user-settlement-payment-editor.interface";
import { UserSettlementPaymentEditorComponent } from "../user-settlement-payment-editor/user-settlement-payment-editor.component";

@Component({
    selector: "app-user-settlement-payments",
    templateUrl: "user-settlement-payments.component.html",
    styleUrls: ["user-settlement-payments.component.scss"]
})
export class UserSettlementPaymentsComponent {

    @ViewChild(UserSettlementPaymentEditorComponent)
    public editor: UserSettlementPaymentEditorInterface;

    @Input()
    public roomRequest: RoomRequest | MigrationRequestCase;

    @Output()
    public paymentCanceled: EventEmitter<void>;
    @Output()
    public paymentModified: EventEmitter<void>;
    @Output()
    public updated: EventEmitter<void>;

    constructor() {
        this.updated = new EventEmitter<void>();
        this.paymentCanceled = new EventEmitter<void>();
        this.paymentModified = new EventEmitter<void>();
    }

    public async onAddNewPayment()
    : Promise<void> {
        await this.editor.open(this.roomRequest, {} as Payment);
    }

    public async onEditPayment(payment: Payment)
    : Promise<void> {
        await this.editor.open(this.roomRequest, payment);
    }

    public onPaymentUpdated()
    : void {
        this.updated.emit();
    }

    public onPaymentCanceled()
    : void {
        this.paymentCanceled.emit();
    }

    public onPaymentModified()
    : void {
        this.paymentModified.emit();
    }
}

