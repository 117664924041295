import { RoomRequest } from "../../../shared/http-services/models/room-request.model";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { MigrationDecision } from "./migration-decision.model";
import { User } from "../../../shared/models/user.model";

export class MigrationRequestCase {

    public initiator: Partial<User>;
    public status: LocalizedMessage;
    public type: LocalizedMessage;
    public migrationDecisions: Array<MigrationDecision>;
    public sourceRoomRequest: RoomRequest;
    public targetRoomRequest: RoomRequest;
    public targetUser: Partial<User>;
    public id: number;
    public processing: boolean;
    public requiresApproval?: boolean;
    public requiresAdditionalPayment?: boolean;

    constructor(init?: Partial<MigrationRequestCase>) {
        Object.assign(this, init);
    }
}
