import {User} from './user.model';

export class Order{
  constructor(
    public created:string,
    public updated:string,
    public id:number,
    public status: string,
    public type:string,
    public user:User
  ){}
}
