import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { AbstractFormBasedComponent } from "../../core/components/abstract-form-based.component";
import { RoomRequest } from "../../shared/http-services/models/room-request.model";
import { PaymentsOptions } from "../../shared/http-services/payments/models/payment-options.model";
import { Payment } from "../../shared/http-services/payments/models/payment.model";
import { LocalizedMessage } from "../../shared/models/privilege-status.model";
import { MigrationRequestCase } from "../migration-approval/models/migration-request-case.model";
import { UserSettlementPaymentEditorInterface } from "./interfaces/user-settlement-payment-editor.interface";
import { UserSettlementPaymentEditorFormService } from "./services/user-settlement-payment-editor-form.service";
import { UserSettlementPaymentEditorService } from "./services/user-settlement-payment-editor.service";

@Component({
    selector: "app-user-settlement-payment-editor",
    styleUrls: ["user-settlement-payment-editor.component.scss"],
    templateUrl: "user-settlement-payment-editor.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [
        UserSettlementPaymentEditorFormService,
        UserSettlementPaymentEditorService,
    ]
})
export class UserSettlementPaymentEditorComponent extends AbstractFormBasedComponent implements UserSettlementPaymentEditorInterface {

    @ViewChild("paymentEditorModal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public saved: EventEmitter<void>;

    public form: FormGroup;
    public paymentsOptions: PaymentsOptions;
    public privilegeOptions: LocalizedMessage[];

    private roomRequest: RoomRequest | MigrationRequestCase;
    private payment: Payment;
    private promiseResolver: () => void;

    constructor(
        private userSettlementPaymentEditorFormService: UserSettlementPaymentEditorFormService,
        private userSettlementPaymentEditorService: UserSettlementPaymentEditorService,
    ) {
        super();

        this.saved = new EventEmitter<void>();

        this.loadPaymentsOptions();
        this.loadPrivilegeOptions();
    }

    public open(roomRequest: RoomRequest | MigrationRequestCase, payment?: Payment)
    : Promise<void> {
        this.modalComponent.open();

        this.roomRequest = roomRequest;
        this.payment = payment;

        this.buildForm();

        return new Promise<void>(res => {
            this.promiseResolver = res;
        });
    }

    public async save()
    : Promise<void> {
        this.markAllFormControlsTouched(this.form);

        if (!this.form.valid) {
            return ;
        }

        const updatedModel = this.userSettlementPaymentEditorFormService.mapToModel(this.form, this.payment);

        try {
            if (this.payment.id) {
                await this.userSettlementPaymentEditorService.updatePayment(updatedModel);
            } else {
                await this.userSettlementPaymentEditorService.createPayment(this.roomRequest, updatedModel);
            }

            this.saved.emit();

            this.close();
        } catch (error) { }
    }

    public close()
    : void {
        this.form = null;
        this.modalComponent.close();
    }

    private buildForm()
    : void {
        this.form = this.userSettlementPaymentEditorFormService.buildGroup(this.payment);
    }

    private async loadPaymentsOptions()
    : Promise<void> {
        this.paymentsOptions = await this.userSettlementPaymentEditorService.loadPaymentOptions();
    }

    private async loadPrivilegeOptions()
    : Promise<void> {
        this.privilegeOptions = await this.userSettlementPaymentEditorService.loadPrivilegeOptions();
    }
}
