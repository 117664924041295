import { Component, EventEmitter, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";

import { MigrationRequestHttpService } from "../../../../../shared/http-services/migration-request/migration-request-http.service";

@Component({
    selector: "app-switch-room-request",
    templateUrl: "./switch-room-request.component.html",
})
export class SwitchRoomRequestComponent {

    @Output()
    public requestAdded: EventEmitter<void>;
    @Output()
    public savingFinished: EventEmitter<void>;
    @Output()
    public savingStarted: EventEmitter<void>;

    constructor(
        private migrationRequestHttpService: MigrationRequestHttpService,
        private toastrService: ToastrService,
    ) {
        this.requestAdded = new EventEmitter<void>();
        this.savingFinished = new EventEmitter<void>();
        this.savingStarted = new EventEmitter<void>();
    }

    public onSend(email: string)
    : void {
        this.savingStarted.emit();

        this.migrationRequestHttpService.createSwitchRequest(email)
            .pipe(
                finalize(() => this.savingFinished.emit())
            )
            .subscribe(() => {
                this.toastrService.success(`Запит на обмін кімнатами з користувачем, ${email}, успішно створений. 
                Слідкуйте за статусом заяви на сторінці поселення.`);
                this.requestAdded.emit();
            },
                error => this.toastrService.error(error.message));
    }
}
