import { BuildingsEditorConfig } from "./buildings-editor-config.model";

export class BuildingsEditorApi {

    public open: (config: BuildingsEditorConfig) => void;

    constructor(init?: Partial<BuildingsEditorApi>) {
        Object.assign(this, init);
    }
}
