import { Component, HostListener, ViewChild } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileHttpService } from "../../../shared/http-services/profile/profile-http.service";
import { ProfileInfo } from "../../../shared/http-services/profile/models/profile-info.model";
import { ProfileStateService } from "../../../core/profile/services/profile-state.service";
import { WrappedError } from "../../../shared/http-services/models/wrapped-error.model";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { ProfileModalContainerApi } from "../models/profile-modal-api.model";

@Component({
    selector: "app-profile-modal-container",
    templateUrl: "./profile-modal-container.component.html",
})
export class ProfileModalContainerComponent {

    @ViewChild("profileModal")
    public modalComponent: NgxSmartModalComponent;

    public profile: ExtendedProfile;

    public degreeOptions$: Observable<LocalizedMessage[]>;
    public educationTypeOptions$: Observable<LocalizedMessage[]>;
    public facultyOptions$: Observable<LocalizedMessage[]>;
    public genderOptions$: Observable<LocalizedMessage[]>;
    public specialityOptions: LocalizedMessage[];

    private _isLoadingSpecialities: boolean;

    constructor(
        private optionsHttpService: OptionsHttpService,
        private profileStateService: ProfileStateService,
        private router: Router,
        private toastrService: ToastrService,
        private profileHttpService: ProfileHttpService,
    ) {
        this._isLoadingSpecialities = true;

        this.setOptions();
        this.loadProfile();
    }

    @HostListener("document:keyup.escape", ["$event"])
    public escPress(event: KeyboardEvent)
    : void {
        if (this.modalVisible) {
            event.stopImmediatePropagation();
        }
    }

    public get api()
    : ProfileModalContainerApi {
        return new ProfileModalContainerApi({
            open: this.open.bind(this),
        });
    }

    public get isLoadingSpecialities()
    : boolean {
        return this._isLoadingSpecialities;
    }

    private get modalVisible()
    : boolean {
        return this.modalComponent && this.modalComponent.isVisible();
    }

    public save(request: ProfileInfo)
    : void {
        this.profileHttpService.updateProfileInfo(request)
            .subscribe(
                () => {
                    this.toastrService.success("Форму успішно збережено");
                    this.modalComponent.close();
                    this.profileStateService.loadProfile()
                        .then(() => this.router.navigate(["/"]));
                },
                (err: WrappedError) => this.toastrService.error(err.message),
            );
    }

    public onFacultyChange(faculty: string | null)
    : void {
        this._isLoadingSpecialities = true;
        if (!faculty) {
            return;
        }

        this.optionsHttpService.getSpecialityOptionsByFaculty(faculty)
            .pipe(
                finalize(() => this._isLoadingSpecialities = false),
            )
            .subscribe(
                specialities => this.specialityOptions = specialities,
                err => this.toastrService.error(err.message),
            );
    }

    private setOptions()
    : void {
        this.degreeOptions$ = this.optionsHttpService.getDegreeOptions();
        this.educationTypeOptions$ = this.optionsHttpService.getEducationTypeOptions();
        this.facultyOptions$ = this.optionsHttpService.getFacultyOptions();
        this.genderOptions$ = this.optionsHttpService.getGenderOptions();
    }

    private loadProfile()
    : void {
        this.profileHttpService.getProfile()
            .subscribe(
                res => this.profile = res,
                err => this.toastrService.error(err.message),
            );
    }

    private open()
    : void {
        this.modalComponent.open();
    }
}
