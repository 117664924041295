import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Payment } from "../../../../shared/http-services/payments/models/payment.model";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";
import { PayNowModalApi } from "../../../../features/room-request-board/components/pay-now-modal/models/pay-now-modal.api";
import { Profile } from "../../../../shared/http-services/profile/models/profile.model";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";

@Component({
    selector: "app-payment-item",
    templateUrl: "./payment-item.component.html",
    styleUrls: ["./payment-item.component.scss"],
})
export class PaymentItemComponent {

    @Input()
    public payment: Payment;
    @Input()
    public privilegeOptions: LocalizedMessage[];
    @Input()
    public profile: Profile;

    @Output()
    public cancelPayment: EventEmitter<void>;

    private paymentModalApi: PayNowModalApi;

    constructor() {
        this.cancelPayment = new EventEmitter<void>();
    }

    public get showActions()
    : boolean {
        return this.payment.payable || this.payment.cancelable;
    }

    public formatDate(date: string)
    : string {
        return DateHelperService.formatDate(date);
    }

    public onCancel()
    : void {
        if (confirm(`Ви впевнені що хочете скасувати платіж
        (${DateHelperService.formatDate(this.payment.from)} - ${DateHelperService.formatDate(this.payment.to)})?`)) {
            this.cancelPayment.emit();
        }
    }

    public onPay()
    : void {
        this.paymentModalApi.open();
    }

    public onPayNowModalReady(api: PayNowModalApi)
    : void {
        this.paymentModalApi = api;
    }
}
