import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserRolesEditorModule } from "../user-roles-editor/user-roles-editor.module";
import { UserRolesCardComponent } from "./user-roles-card.component";

@NgModule({
    imports: [SharedModule, UserRolesEditorModule],
    declarations: [UserRolesCardComponent],
    exports: [UserRolesCardComponent]
})
export class UserRolesCardModule {}
