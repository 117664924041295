import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Period } from "../../../shared/http-services/periods/models/period.model";
import { PeriodEditorApi } from "./period-editor/models/period-editor-api.model";

@Component({
    selector: "app-periods-view",
    templateUrl: "./periods-view.component.html",
    styleUrls: [ "./periods-view.component.scss" ],
})
export class PeriodsViewComponent {

    @Input()
    public periods: Period[];
    @Input()
    public loading: boolean;

    @Output()
    public createPeriod: EventEmitter<Period>;
    @Output()
    public deletePeriod: EventEmitter<Period>;
    @Output()
    public reload: EventEmitter<void>;
    @Output()
    public updatePeriod: EventEmitter<Period>;

    private editorApi: PeriodEditorApi;

    constructor() {
        this.createPeriod = new EventEmitter<Period>();
        this.deletePeriod = new EventEmitter<Period>();
        this.reload = new EventEmitter<void>();
        this.updatePeriod = new EventEmitter<Period>();
    }

    public onAddNewPeriod()
    : void {
        this.editorApi.open({});
    }

    public onCreatePeriod(period: Period)
    : void {
        this.createPeriod.emit(period);
    }

    public onDeletePeriod(period: Period)
    : void {
        this.deletePeriod.emit(period);
    }

    public onEditPeriod(period: Period)
    : void {
        this.editorApi.open({ period });
    }

    public onEditorReady(api: PeriodEditorApi)
    : void {
        this.editorApi = api;
    }

    public onReload()
    : void {
        this.reload.emit();
    }

    public onUpdatePeriod(period: Period)
    : void {
        this.updatePeriod.emit(period);
    }
}

