import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "showActive"
})
export class ShowActivePipe implements PipeTransform {

    public transform(array: any[], active: boolean)
    : any[] {
        if (active === false) {
            return array.filter(item => item.active === true);
        }

        return array;
    }
}
