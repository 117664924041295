export class Period {

    public id: number;
    public startDate: string;
    public masterStartDate: string;
    public endDate: string;
    public migrationEnabled: boolean;
    public settlementEnabled: boolean;
    public settlementProlongationEnabled: boolean;

    constructor(init?: Partial<Period>) {
        Object.assign(this, init);
    }
}
