import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { MigrationRequestCase } from "../migration-approval/models/migration-request-case.model";

@Component({
    selector: "app-user-resettlement-info",
    templateUrl: "user-resettlement-info.component.html",
    styleUrls: ["user-resettlement-info.component.scss"]
})
export class UserResettlementInfoComponent implements OnChanges {

    @Input()
    public user: UserDetails;

    @Output()
    public paymentCanceled: EventEmitter<void>;
    @Output()
    public updated: EventEmitter<void>;

    public allMigrationRequests: MigrationRequestCase[];

    constructor() {
        this.paymentCanceled = new EventEmitter<void>();
        this.updated = new EventEmitter<void>();
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.user) {
            this.allMigrationRequests = [
                ...(this.user
                    ? this.user.myMigrationRequests
                    : []),
                ...(this.user
                    ? this.user.proposedMigrationRequests
                    : [])];
        }
    }

    public onUserInfoUpdated()
    : void {
        this.updated.emit();
    }

    public onPaymentCanceled()
    : void {
        this.paymentCanceled.emit();
    }
}
