import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { BuildingsTabComponent } from "./components/buildings-tab/buildings-tab.component";
import { RoomTabComponent } from "./components/room-tab/room-tab.component";
import { BuildingHttpService } from "../../shared/http-services/buildings/building-http.service";
import { ShowActivePipe } from "./pipes/show-active.pipe";
import { SharedModule } from "../../shared/shared.module";
import { DormitoriesComponent } from "./components/dormitories.component";
import { RoomEditorComponent } from "./components/room-editor/room-editor.component";
import { DefaultRoomFactoryService } from "./components/room-editor/services/default-room-factory.service";
import { DefaultBuildingFactoryService } from "./components/buildings-editor/services/default-building-factory.service";
import { BuildingsEditorComponent } from "./components/buildings-editor/buildings-editor.component";
import { RoomControlsComponent } from "./components/room-controls/room-controls.component";
import { BuildingsControlsComponent } from "./components/buildings-controls/buildings-controls.component";
import { SwitcherComponent } from "./components/switcher/switcher.component";
import { ResidentCardComponent } from "./components/resident-card/resident-card.component";
import { MigrationCandidateCardComponent } from "./components/migration-candidate-card/migration-candidate-card.component";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { MembersOfCouncilComponent } from "./components/members-of-council/members-of-council.component";
import { UserSearchModule } from "./components/user-search/user-search.module";
import { PipesModule } from "../../core/pipes/pipes.module";
import { RoomThumbModule } from "../../features/room-thumb/room-thumb.module";
import { MembersOfManagementComponent } from "./components/members-of-management/members-of-management.component";

@NgModule({
    declarations: [
        BuildingsControlsComponent,
        BuildingsEditorComponent,
        BuildingsTabComponent,
        DormitoriesComponent,
        MembersOfCouncilComponent,
        MembersOfManagementComponent,
        ResidentCardComponent,
        MigrationCandidateCardComponent,
        RoomControlsComponent,
        RoomEditorComponent,
        RoomTabComponent,
        ShowActivePipe,
        SwitcherComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PageLoaderModule,
        RoomThumbModule,
        UserSearchModule,
        PipesModule,
    ],
    providers: [
        BuildingHttpService,
        DefaultBuildingFactoryService,
        DefaultRoomFactoryService,
    ]
})
export class DormitoriesModule { }
