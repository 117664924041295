import { FilteredRequest } from "../../models/filtered-request.model";
import { SortedRequest } from "../../models/sorted-request.model";
import { PagedRequest } from "../../models/paged-request.model";
import { LogsRequestFilter } from "./logs-request-filter.model";

export class LogsRequestModel implements PagedRequest, SortedRequest, FilteredRequest<LogsRequestFilter> {

    public filters: LogsRequestFilter;
    public page: string;
    public size: string;
    public sort: string;

    constructor(init?: Partial<LogsRequestModel>) {
        Object.assign(this, init);
    }
}
