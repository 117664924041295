import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { NotFoundComponent } from "./components/not-found.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        NotFoundComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ],
})
export class NotFoundModule {
}
