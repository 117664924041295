import { AgGridEvent } from "ag-grid-community";
import { Injectable } from "@angular/core";

@Injectable()
export class AgGridHelperService {

    public fitColumnsSizeToContent(event: AgGridEvent)
    : void {
        event.columnApi.autoSizeAllColumns();
    }

    public sizeColumnsToFit(event: AgGridEvent)
    : void {
        event.api.sizeColumnsToFit();
    }
}
