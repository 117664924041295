import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserDetails } from "../../../shared/http-services/users/models/user-details.model";
import { UserInfo } from "../../../shared/http-services/users/models/user-info.model";

@Injectable({
   providedIn: "root",
})
export class UserInfoCardEditorFormService {

    constructor(
        private formBuilder: FormBuilder,
    ) {}

    public buildForm(user: UserDetails)
    : FormGroup {
        const dateBirth = (user && user.dateBirth) ? user.dateBirth : "";
        const gender = (user && user.gender) ? user.gender.codeName : "";
        const degree = (user && user.degree) ? user.degree.codeName : "";
        const faculty = (user && user.faculty) ? user.faculty.codeName : "";
        const speciality = (user && user.speciality) ? user.speciality.codeName : "";
        const educationType = (user && user.educationType) ? user.educationType.codeName : "";

        return this.formBuilder.group({
            dateBirth: [dateBirth, Validators.required],
            gender: [gender, Validators.required],
            degree: [degree, Validators.required],
            faculty: [faculty, Validators.required],
            speciality: [speciality, Validators.required],
            educationType: [educationType, Validators.required],
        });
    }

    public mapToModel(form: FormGroup)
    : UserInfo {
        const { dateBirth, gender, degree, faculty, speciality, educationType } = form.value;

        return {
            dateBirth,
            gender,
            degree,
            faculty,
            speciality,
            educationType,
        };
    }
}
