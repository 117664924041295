import { Injectable } from "@angular/core";

import { NouisliderConfig } from "../../../../../shared/models/nouislider-config.model";
import { PaymentOptionItem } from "../../../../../shared/http-services/payments/models/payment-option-item.model";
import { DateHelperService } from "../../../../../shared/services/helpers/date-helper.service";
import { ScreenHelperService } from "../../../../../shared/services/helpers/screen-helper.service";

@Injectable()
export class PaymentMapService {

    private sortedOptions: PaymentOptionItem[];

    private get valuesFormat()
    : any {
        return {
            to: (value) => {
                return DateHelperService.formatDate(this.sortedOptions[value].untilDate);
            },
            from: (value) => {
                return value;
            },
        };
    }

    public getSliderConfig(options: PaymentOptionItem[])
    : Partial<NouisliderConfig> {
        this.sortedOptions = this.sortOptionsByDate(options);
        const range = {
            min: 0,
            max: this.sortedOptions.length - 1,
        };

        const defaultValue = this.getDefaultSliderValue(this.sortedOptions);

        const pipsValues = options.map(option => options.indexOf(option));
        const padding = [defaultValue, 0];

        return {
            behaviour: "tap-drag",
            connect: [true, true, false],
            start: [defaultValue, defaultValue],
            orientation: ScreenHelperService.detectXSScreen() ? "vertical" : "horizontal",
            range,
            padding,
            format: {
                to: (value) => value,
                from: (value) => value,
            },
            pips: {
                mode: "steps",
                start: 0,
                values: pipsValues,
                density: 20,
                format: this.valuesFormat,
            },
            step: 1,
        };
    }

    public getDefaultSliderValue(options: PaymentOptionItem[])
    : number {
        const sortedOptions = this.sortOptionsByDate(options);
        const firstAvailableOption = this.sortedOptions.find(option => option.available);

        if (!firstAvailableOption) {
            return sortedOptions.length - 1;
        }

        return sortedOptions.indexOf(firstAvailableOption) - 1;
    }

    private sortOptionsByDate(options: PaymentOptionItem[])
    : PaymentOptionItem[] {
        return options.sort((a, b) =>  new Date(a.untilDate).getTime() - new Date(b.untilDate).getTime());
    }
}
