import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MigrationDecision } from "../migration-approval/models/migration-decision.model";
import { MigrationRequestCase } from "../migration-approval/models/migration-request-case.model";

@Component({
    selector: "app-user-resettlement-card",
    templateUrl: "user-resettlement-card.component.html",
    styleUrls: ["user-resettlement-card.component.scss"]
})
export class UserResettlementCardComponent {

    @Input()
    public migrationRequest: MigrationRequestCase;

    @Output()
    public updated: EventEmitter<void>;
    @Output()
    public paymentCanceled: EventEmitter<void>;

    public showPayments: boolean;

    constructor() {
        this.paymentCanceled = new EventEmitter<void>();
        this.updated = new EventEmitter<void>();
    }

    public getColorDecision(decision: MigrationDecision)
    : string {
        return decision.approved === null ? "gray" : (decision.approved ? "green" : "red");
    }

    public changePaymentsVisibility()
    : void {
        this.showPayments = !this.showPayments;
    }

    public onPaymentsUpdated()
    : void {
        this.updated.emit();
    }

    public onPaymentCanceled()
    : void {
        this.paymentCanceled.emit();
    }
}
