import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserDetailsModesSwitchComponent } from "./user-details-modes-switch.component";

@NgModule({
    imports: [SharedModule],
    declarations: [
        UserDetailsModesSwitchComponent,
    ],
    exports: [
        UserDetailsModesSwitchComponent,
    ]
})
export class UserDetailsModesSwitchModule {}
