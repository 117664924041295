import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { GetFreeRoomsRequest } from "../../../../../shared/http-services/rooms/models/get-free-rooms-request.model";
import { FreeRoomRequestFormService } from "../services/free-room-request-form.service";
import { Entity } from "../../../../../shared/models/entity.model";
import { Room } from "../../../../../shared/models/rooms.model";

@Component({
    selector: "app-free-room-request-view",
    templateUrl: "./free-room-request-view.component.html",
    styleUrls: [ "./free-room-request-view.component.scss" ],
    providers: [ FreeRoomRequestFormService, ],
})
export class FreeRoomRequestViewComponent {

    @Input()
    public dormitories: Entity[];
    @Input()
    public rooms: Room[];

    @Output()
    public loadRooms: EventEmitter<GetFreeRoomsRequest>;
    @Output()
    public roomSelect: EventEmitter<Room>;

    public form: FormGroup;

    constructor(
        private freeRoomRequestFormService: FreeRoomRequestFormService,
    ) {
        this.form = this.freeRoomRequestFormService.buildForm();
        this.loadRooms = new EventEmitter<GetFreeRoomsRequest>();
        this.roomSelect = new EventEmitter<Room>();
    }

    public get loaded()
    : boolean {
        return !!this.rooms && !!this.dormitories;
    }

    public applyFilters()
    : void {
        const request = this.getRoomsRequest();
        this.loadRooms.emit(request);
    }

    public onRoomSelect(room: Room)
    : void {
        this.roomSelect.emit(room);
    }

    private getRoomsRequest()
    : GetFreeRoomsRequest {
        return new GetFreeRoomsRequest({
            filters: this.freeRoomRequestFormService.mapToModel(this.form),
        });
    }
}
