import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { Period } from "./models/period.model";

@Injectable({
    providedIn: "root"
})
export class PeriodsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/periods`;
    }

    public getAll()
    : Observable<Period[]> {
        return this.httpClient.get(this.path)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getCurrentAndFuture()
    : Observable<Period[]> {
        return this.httpClient.get(`${this.path}?includePast=false`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public create(value: Period)
    : Observable<any> {
        return this.httpClient.post(this.path, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public update(value: Period)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${value.id}`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public delete(id: number)
    : Observable<any> {
        return this.httpClient.delete(`${this.path}/${id}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
