import { AgGridEvent, ColDef, GridOptions } from "ag-grid-community";
import { Injectable } from "@angular/core";
import { AgGridOptionsService } from "../../../../../shared/services/tables/ag-grid-options.service";
import { AgGridHelperService } from "../../../../../shared/services/helpers/ag-grid/ag-grid-helper.service";
import { ColumnMeta } from "../../../../../shared/http-services/reports/models/column-meta.model";

@Injectable()
export class DegreesReportTableService {

    constructor(
        private agGridHelperService: AgGridHelperService,
        private agGridOptionsService: AgGridOptionsService,
    ) {}

    public getGridOptions(additionalColumns: ColumnMeta[])
    : GridOptions {
        return this.agGridOptionsService.getGridOptions({
            gridAutoHeight: true,
            columnDefs: this.getColumnDefs(additionalColumns),
            onGridSizeChanged: this.adjustGridColumns.bind(this),
            onModelUpdated: this.adjustGridColumns.bind(this),
            rowSelection: "none",
            enableSorting: false,
            suppressHorizontalScroll: false,
            enableCellTextSelection: true,
        });
    }

    public getColumnDefs(additionalProperties: ColumnMeta[])
    : ColDef[] {
        const additionalColumns = additionalProperties.map(columnMeta => {
           return <ColDef> {
               headerName: columnMeta.headerName,
               field: columnMeta.field,
           };
        });

        return additionalColumns;
    }

    private adjustGridColumns(event: AgGridEvent)
    : void {
        this.agGridHelperService.fitColumnsSizeToContent(event);
    }
}
