import { PageEvent } from "@angular/material/paginator";
import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MigrationRequestHttpService } from "../../shared/http-services/migration-request/migration-request-http.service";
import { MigrationRequestCase } from "../migration-approval/models/migration-request-case.model";
import { PagedSortedResponse } from "../../shared/http-services/models/paged-sorted-response.model";

@Component({
    selector: "app-migrations-list",
    templateUrl: "migrations-list.component.html",
    styleUrls: ["migrations-list.component.scss"],
})
export class MigrationsListComponent {

    public data: MigrationRequestCase[];

    public page: number;
    public pageSize: number;
    public search: string;
    public totalElements: number;

    constructor(
        private migrationRequestHttpService: MigrationRequestHttpService,
        private toastrService: ToastrService,
    ) {
        this.page = 0;
        this.pageSize = 10;

        this.loadRequests();
    }

    public loadRequests()
    : void {
        this.data = null;

        this.migrationRequestHttpService.getAllActiveMigrationRequests(
            {
                page: this.page,
                size: this.pageSize,
            },
            this.search
        ).subscribe(
            res => this.handleRequestsResponse(res),
            error => {
                this.toastrService.error(error.message);
                this.data = [];
            }
        );
    }

    public onChangePage($event: PageEvent)
    : void {
        this.page = $event.pageIndex;
        this.loadRequests();
    }

    public onSearchChange()
    : void {
        this.loadRequests();
    }

    private handleRequestsResponse(response: PagedSortedResponse<MigrationRequestCase>)
    : void {
        this.data = response.content;

        this.totalElements = response.totalElements;
        this.page = response.skip / response.limit;
    }
}
