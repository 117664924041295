import { Component, EventEmitter, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { GetUsersRequest } from "../../../../../shared/http-services/users/models/get-users-request.model";
import { UsersHttpService } from "../../../../../shared/http-services/users/users-http.service";
import { User } from "../../../../../shared/models/user.model";

@Component({
    selector: "app-user-search",
    templateUrl: "./user-search-container.component.html",
})
export class UserSearchContainerComponent {

    public static optionsSize = 50;

    @Output()
    public userSelect: EventEmitter<User>;

    public options: User[];

    constructor(
        private usersHttpService: UsersHttpService,
        private toastrService: ToastrService,
    ) {
        this.userSelect = new EventEmitter<User>();

        this.resetOptions();
    }

    private getRequest(search: string)
    : GetUsersRequest {
        return new GetUsersRequest({
            size: UserSearchContainerComponent.optionsSize.toString(),
            page: "0",
            filters: {
              username: search,
            },
        });
    }

    public onSearch(value: string)
    : void {
        this.resetOptions();

        if (!value || !value.length) {
            return;
        }

        this.usersHttpService.getUsers(this.getRequest(value))
            .subscribe(
                response => this.options = response.content,
                err => this.toastrService.error(err.message),
            );
    }

    public onUserSelect(value: User)
    : void {
        this.userSelect.emit(value);
    }

    private resetOptions()
    : void {
        this.options = [];
    }
}
