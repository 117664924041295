import { Dictionary } from "../../../../../core/models/dictionary.interface";

export class RoomControlsApi {

    public getFilter: () => Dictionary;

    constructor(init?: Partial<RoomControlsApi>) {
        Object.assign(this, init);
    }
}
