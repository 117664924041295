import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { OpenSettlementForUserComponent } from "./open-settlement-for-user.component";
import { PipesModule } from "../../../../core/pipes/pipes.module";

@NgModule({
    imports: [
        SharedModule,
        PipesModule,
    ],
    declarations: [
        OpenSettlementForUserComponent,
    ],
    exports: [
        OpenSettlementForUserComponent,
    ],
})
export class OpenSettlementForUserModule {}
