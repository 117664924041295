import { ModalApi } from "../../../../../shared/models/modal-api.interface";

export class RoomRequestModalApi implements ModalApi {

    public open: () => void;

    constructor(init?: Partial<RoomRequestModalApi>) {
        Object.assign(this, init);
    }
}
