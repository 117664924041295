import { Component, EventEmitter, Input, Output } from "@angular/core";

import { PaymentsOptions } from "../../../shared/http-services/payments/models/payment-options.model";
import { Payment } from "../../../shared/http-services/payments/models/payment.model";
import { NewPayment } from "../../../shared/http-services/payments/models/new-payment.model";

@Component({
    selector: "app-payments-view",
    templateUrl: "./payments-view.component.html",
    styleUrls: [ "./payments-view.component.scss" ],
})
export class PaymentsViewComponent {

    @Input()
    public payments: Payment[];
    @Input()
    public paymentOptions: PaymentsOptions;

    @Output()
    public createPayment: EventEmitter<NewPayment>;
    @Output()
    public cancelPayment: EventEmitter<number>;

    constructor() {
        this.createPayment = new EventEmitter<NewPayment>();
        this.cancelPayment = new EventEmitter<number>();
    }

    public get hasOpenPayments()
    : boolean {
        return this.payments.some(payment => payment.payable);
    }

    public onCreatePayment(toDate: string)
    : void {
        this.createPayment.emit(new NewPayment({
            fromDate: this.paymentOptions.from,
            toDate: toDate,
        }));
    }

    public onCancelPayment(id: number)
    : void {
        this.cancelPayment.emit(id);
    }
}
