import { ColDef, GridOptions } from "ag-grid-community";
import { Injectable } from "@angular/core";

import { AgGridHelperService } from "../../../../../shared/services/helpers/ag-grid/ag-grid-helper.service";
import { AgGridOptionsService } from "../../../../../shared/services/tables/ag-grid-options.service";

@Injectable()
export class DormitoriesReportTableService {

    constructor(
       private agGridHelperService: AgGridHelperService,
       private agGridOptionsService: AgGridOptionsService,
    ) {}

    public getGridOptions()
    : GridOptions {
    return this.agGridOptionsService.getGridOptions({
            gridAutoHeight: true,
            columnDefs: this.getColumnDefs(),
            onGridSizeChanged: this.agGridHelperService.sizeColumnsToFit,
            rowSelection: "none",
            enableSorting: false,
            suppressHorizontalScroll: true,
            enableCellTextSelection: true,
        });
    }

    private getColumnDefs()
    : ColDef[] {
        return <ColDef[]>[
            {
                headerName: "",
                field: "name",
            },
            {
                field: "totalPlaces",
                headerName: "Загальна кількість місць",
            },
            {
                field: "freePlaces",
                headerName: "Вільні місця",
            },
            {
                field: "livingPlaces",
                headerName: "Оплачені місця",
            },
            {
                field: "unpaidPlaces",
                headerName: "Очікують оплати",
            },
        ];
    }
}
