import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Period } from "../../../../shared/http-services/periods/models/period.model";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";

@Component({
    selector: "app-period",
    templateUrl: "./period.component.html",
    styleUrls: [ "./period.component.scss" ],
})
export class PeriodComponent {

    @Input()
    public period: Period;

    @Output()
    public delete: EventEmitter<void>;
    @Output()
    public edit: EventEmitter<void>;

    constructor() {
        this.delete = new EventEmitter<void>();
        this.edit = new EventEmitter<void>();
    }

    public formatDate(date: string)
    : string {
        return DateHelperService.formatDate(date);
    }

    public onDelete()
    : void {
        this.delete.emit();
    }

    public onEdit()
    : void {
        this.edit.emit();
    }
}
