import { ExtendedPrivilege } from "../../client-privileges/models/extended-privilege.model";
import { MigrationRequestCase } from "../../../../features/migration-approval/models/migration-request-case.model";
import { RoomRequest } from "../../models/room-request.model";
import { ExtendedProfileInfo } from "./extended-profile-info.model";

export class ExtendedProfile extends ExtendedProfileInfo {

    public requiredUpdate: boolean;
    public id: number;
    public privileges: ExtendedPrivilege[];
    public roomRequests: RoomRequest[];
    public myMigrationRequests?: MigrationRequestCase[];
    public proposedMigrationRequests?: MigrationRequestCase[];

    constructor(init?: Partial<ExtendedProfile>) {
        super();
        Object.assign(this, init);
    }
}
