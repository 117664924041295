import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { RoomHttpService } from "../../../../shared/http-services/rooms/room-http.service";
import { RoomDetails } from "../../../../shared/models/room-details.model";
import { RoomEditorConfig } from "./models/room-editor-config.model";
import { RoomEditorApi } from "./models/room-editor-api.model";
import { RoomEditorFormService } from "./services/room-editor-form.service";
import { RoomStatus } from "../../../../shared/models/rooms.model";
import { DefaultRoomFactoryService } from "./services/default-room-factory.service";
import { AbstractFormBasedComponent } from "../../../../core/components/abstract-form-based.component";
import { RESIDENT_COUNTS } from "../../../../shared/constants/dormitory-constants";
import { UserRolesStateService } from "../../../../core/roles/services/user-roles-state.service";
import { RolePasses } from "../../../../core/roles/rules/roles-passes.class";
import { untilDestroyed } from "ngx-take-until-destroy";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { EditRoom } from "../../../../shared/models/edit-room.model";
import { catchError } from "rxjs/operators";
import { User } from "../../../../shared/models/user.model";

@Component({
    selector: "app-room-editor",
    templateUrl: "./room-editor.component.html",
    styleUrls: [ "./room-editor.component.scss" ],
    providers: [ RoomEditorFormService ],
})
export class RoomEditorComponent extends AbstractFormBasedComponent implements OnInit, OnDestroy {

    @ViewChild("roomEditor")
    public modalComponent: NgxSmartModalComponent;

    @Input()
    public dormitories: any[];
    @Input()
    public privilegeOptions: LocalizedMessage[];

    @Output()
    public editorReady: EventEmitter<RoomEditorApi>;

    public config: RoomEditorConfig;
    public form: FormGroup;
    public hasPassToEditRoom: boolean;
    public statusOptions$: Observable<RoomStatus[]>;
    public statusCompareFn: Function;

    public readonly residentCounts;

    constructor(
        private defaultRoomFactoryService: DefaultRoomFactoryService,
        private roomEditorFormService: RoomEditorFormService,
        private userRolesStateService: UserRolesStateService,
        private roomHttpService: RoomHttpService,
    ) {
        super();

        this.residentCounts = RESIDENT_COUNTS;
        this.editorReady = new EventEmitter<RoomEditorApi>();

        this.statusOptions$ = this.roomHttpService.getRoomStatuses();
        this.statusCompareFn = (first: RoomStatus, second: RoomStatus) => {
            return first && second && first.codeName === second.codeName;
        };
    }

    public ngOnInit()
    : void {
        this.onRoomEditorReady();
    }

    public ngOnDestroy()
    : void {}

    public get headerTitle()
    : string {
        return this.isInEditMode
            ? "Змінити Кімнату"
            : "Додати нову кімнату";
    }

    public get isFormValid()
    : boolean {
        this.markAllFormControlsTouched(this.form);

        return this.form.valid;
    }

    public get isInEditMode()
    : boolean | null {
        if (!this.config) {
            return null;
        }

        return this.config.edit;
    }

    public get room()
    : RoomDetails {
        return this.isInEditMode
            ? this.config.initialValue
            : this.defaultRoomFactoryService.create();
    }

    public get updatedRoom()
    : EditRoom {
        return this.roomEditorFormService.getRoomFromForm(this.form);
    }

    public get isAnyUser()
    : boolean {
        return this.room.users ? this.room.users.length > 0 : false;
    }

    public onClose()
    : void {
        this.modalComponent.close();
    }

    public onSave()
    : void {
        if (!this.isFormValid) {
            return;
        }

        this.config.onSave(this.updatedRoom, this.room ? this.room.id : null);
        this.onClose();
    }

    public open(config: RoomEditorConfig)
    : void {
        this.config = config;
        if (this.isInEditMode) {
            this.fetchRoomDetails(this.room.id).subscribe((details: RoomDetails): void => {
                if (details) {
                    this.config.initialValue.users = details.users;
                }
                this.openModal();
            });
        } else {
            this.openModal();
        }
    }

    public isRoomResident(roomId: number, user: User): boolean {
        return user.livingRoomRequest.room.id === roomId;
    }

    private initForm()
    : void {
        this.form = this.roomEditorFormService.buildForm(this.room);
    }

    private onRoomEditorReady()
    : void {
        const api = new RoomEditorApi({
            open: this.open.bind(this),
        });

        this.editorReady.emit(api);
    }

    private subscribeOnPass()
    : void {
        this.userRolesStateService.getIsInRole(RolePasses.canEditRoomsPass)
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((value) => {
                this.hasPassToEditRoom = value;

                this.hasPassToEditRoom
                    ? this.enableAllControls(this.form)
                    : this.disableAllControls(this.form);
            });
    }

    private fetchRoomDetails(id: number): Observable<RoomDetails> {
        return this.roomHttpService.getRoom(id).pipe(
            catchError((error): Observable<null> => {
                console.error("Error fetching room details: ", error);
                return of(null);
            })
        );
    }

    private openModal(): void {
        this.initForm();
        this.subscribeOnPass();
        this.modalComponent.open();
    }
}
