import { NgModule } from "@angular/core";
import { CoreModule } from "../../core/core.module";
import { SharedModule } from "../../shared/shared.module";
import { UserSettlementCardModule } from "../user-settlement-card/user-settlement-card.module";
import { UserSettlementInfoComponent } from "./user-settlement-info.component";

@NgModule({
    imports: [
        SharedModule,
        CoreModule,
        UserSettlementCardModule,
    ],
    declarations: [
        UserSettlementInfoComponent,
    ],
    exports: [
        UserSettlementInfoComponent,
    ]
})
export class UserSettlementInfoModule {}
