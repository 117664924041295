import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AbstractHttpService } from "../abstract-http.service";
import { ExtendedProfile } from "./models/extended-profile.model";
import { ProfileInfo } from "./models/profile-info.model";
import { UserRolesResponse } from "./models/user-roles-response.model";

@Injectable({
    providedIn: "root"
})
export class ProfileHttpService extends AbstractHttpService {

    private readonly path: string;
    private readonly path_2: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/users/me`;
        this.path_2 = `${this.apiUrl}/users`;
    }

    public getMyRoles()
    : Observable<UserRolesResponse> {
        return this.httpClient
            .get(`${this.path}/identity`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getProfile()
    : Observable<ExtendedProfile> {
        return this.httpClient
            .get(`${this.path}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public updateProfileInfo(request: ProfileInfo)
    : Observable<ProfileInfo> {
        return this.httpClient
            .put(`${this.path}/info`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }

    public updateProfileInfoByEmail(request: ProfileInfo, email: string)
    : Observable<ProfileInfo> {
        return this.httpClient
            .put(`${this.path_2}/${email}/info`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }
}
