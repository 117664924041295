import { Component, Input } from "@angular/core";

@Component({
    selector: "app-label-card-table",
    templateUrl: "./label-card-table.component.html",
    styleUrls: ["./label-card-table.component.scss"]
})
export class LabelCardTableComponent {

    @Input()
    public labels: string[];
}
