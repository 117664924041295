import { PaymentOptionItem } from "./payment-option-item.model";
import { NamedValue } from "../../../models/named-value.model";

export class PaymentsOptions {

    public disabled: boolean;
    public from: string;
    public options: PaymentOptionItem[];
    public prices: NamedValue<string>[];

    constructor(init?: Partial<PaymentsOptions>) {
        Object.assign(this, init);
    }
}
