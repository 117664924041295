import { Component, EventEmitter, Input, Output } from "@angular/core";

import { MigrationDecision } from "../../migration-approval/models/migration-decision.model";
import { MigrationRequestCase } from "../../migration-approval/models/migration-request-case.model";

@Component({
    selector: "app-migration-request-card",
    templateUrl: "./migration-request-card.component.html",
    styleUrls: ["./migration-request-card.component.scss"]
})
export class MigrationRequestCardComponent {

    @Input()
    public dashed: boolean;
    @Input()
    public disableButtons: boolean;
    @Input()
    public cancelable: boolean;
    @Input()
    public case: MigrationRequestCase;
    @Input()
    public showApproval: boolean;

    @Output()
    public approve: EventEmitter<number>;
    @Output()
    public reject: EventEmitter<number>;

    constructor() {
        this.approve = new EventEmitter<number>();
        this.reject = new EventEmitter<number>();
    }

    public getColorDecision(decision: MigrationDecision)
    : string {
        return decision.approved === null ? "gray" : (decision.approved ? "green" : "red");
    }

    public accept()
    : void {
        this.approve.emit(this.case.id);
    }

    public deny()
    : void {
        this.reject.emit(this.case.id);
    }
}
