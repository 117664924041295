import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import { MigrationRequestCardModule } from "../migration-request-card/migration-request-card.module";
import { MigrationApprovalViewComponent } from "./components/migration-approval-view.component";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { MigrationApprovalComponent } from "./containers/migration-approval.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        MigrationApprovalComponent,
        MigrationApprovalViewComponent,
    ],
    imports: [
        CommonModule,
        PageLoaderModule,
        SharedModule,
        MigrationRequestCardModule,
    ],
    exports: [
        MigrationApprovalComponent,
    ]
})
export class MigrationApprovalModule {
}
