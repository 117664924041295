import { NgModule } from "@angular/core";

import { ProfileModalContainerComponent } from "./containers/profile-modal-container.component";
import { ProfileModalComponent } from "./components/profile-modal.component";
import { SharedModule } from "../../shared/shared.module";
import { PipesModule } from "../../core/pipes/pipes.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";

@NgModule({
    imports: [
        PipesModule,
        SharedModule,
        PageLoaderModule,
    ],
    declarations: [
        ProfileModalComponent,
        ProfileModalContainerComponent,
    ],
    exports: [
        ProfileModalContainerComponent,
    ],
    entryComponents: [
        ProfileModalContainerComponent
    ],
})
export class ProfileModule { }
