import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { OrderTabComponent } from "./components/order-tab/order-tab.component";
import { UsersTabComponent } from "./components/users-tab/users-tab.component";
import { OrderTypePipe } from "./components/info-card/pipes/order-type.pipe";
import { OrganizationComponent } from "./components/organization.component";
import { SharedModule } from "../../shared/shared.module";
import { OrganizationNavService } from "./services/organization-nav.service";
import { OrganizationNavTabsComponent } from "./components/organization-nav-tabs/organization-nav-tabs.component";
import { SearchNameFormComponent } from "./components/search-name-form/search-name-form.component";
import { LabelCardTableComponent } from "./components/label-card-table/label-card-table.component";
import { InfoCardComponent } from "./components/info-card/info-card.component";
import { UsersTabFilterFormService } from "./components/users-tab/services/users-tab-filter-form.service";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { OpenSettlementForUserModule } from "./components/open-settlement-for-user/open-settlement-for-user.module";

@NgModule({
    declarations: [
        OrderTabComponent,
        OrderTypePipe,
        OrganizationComponent,
        OrganizationNavTabsComponent,
        UsersTabComponent,
        SearchNameFormComponent,
        LabelCardTableComponent,
        InfoCardComponent,
    ],
    providers: [
        OrganizationNavService,
        UsersTabFilterFormService,
    ],
    imports: [
        CommonModule,
        PageLoaderModule,
        SharedModule,
        OpenSettlementForUserModule,
    ]
})
export class OrganizationModule { }
