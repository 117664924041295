import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";

import { Building } from "../../../../../shared/models/building.model";

@Injectable()
export class BuildingsEditorFormService {

    constructor(
        private fromBuilder: FormBuilder,
    ) { }

    public buildGroup(value: Building)
    : FormGroup {
        return this.fromBuilder.group({
            name: [
                value.name,
                [
                    Validators.required,
                ]
            ],
            address: value.address,
            active: value.active,
        });
    }

    public getRoomFromForm(form: FormGroup)
    : Partial<Building> {
        const { name, active, address } = form.value;

        return new Building({
            active,
            address,
            name,
        });
    }
}
