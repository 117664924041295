import { NgModule } from "@angular/core";

import { DashboardContainerComponent } from "./containers/dashboard-container.component";
import { DashboardComponent } from "./components/dashboard.component";
import { SharedModule } from "../../shared/shared.module";
import { ProfileInfoComponent } from "./components/profile-info/profile-info.component";
import { PipesModule } from "../../core/pipes/pipes.module";
import { PrivilegesEditorComponent } from "./components/privileges-editor/privileges-editor.component";
import { RoomRequestBoardModule } from "../../features/room-request-board/room-request-board.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";

@NgModule({
    declarations: [
        DashboardComponent,
        DashboardContainerComponent,
        PrivilegesEditorComponent,
        ProfileInfoComponent,
    ],
    imports: [
        PipesModule,
        RoomRequestBoardModule,
        SharedModule,
        PageLoaderModule,
    ],
    exports: [
        DashboardContainerComponent,
        PrivilegesEditorComponent,
    ],
})
export class DashboardModule { }
