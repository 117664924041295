import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { Dictionary } from "../../core/models/dictionary.interface";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { LocalizedMessage } from "../../shared/models/privilege-status.model";
import { UserRolesEditorInterface } from "./models/user-roles-editor.interface";
import { UserRolesEditorService } from "./services/user-roles-editor.service";
import { UserRoleIsAdminPanelAssignable } from "../../shared/enums/user-role.enum";

@Component({
    selector: "app-user-roles-editor",
    templateUrl: "user-roles-editor.component.html",
    styleUrls: ["user-roles-editor.component.scss"],
    providers: [
        UserRolesEditorService,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class UserRolesEditorComponent implements UserRolesEditorInterface {

    @ViewChild("modal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public rolesUpdated: EventEmitter<void>;

    public user: UserDetails;
    public roleOptions: LocalizedMessage[];
    public selectedRoles: Dictionary<boolean>;

    constructor(
        private userRolesEditorService: UserRolesEditorService,
    ) {
        this.rolesUpdated = new EventEmitter<void>();

        this.loadRoleOptions();
    }

    public get adminPanelAssignableRoles(): LocalizedMessage[] {
        return this.roleOptions.filter(r =>
            UserRoleIsAdminPanelAssignable[r.codeName]
        );
    }

    public open(user: UserDetails)
    : void {
        this.user = user;

        this.updateSelectedRoles();
        this.modalComponent.open(true);
    }

    public async onSave()
    : Promise<void> {
        const selectedRoles = Object.keys(this.selectedRoles).filter(key => this.selectedRoles[key]);

        await this.userRolesEditorService.updateUserRoles(this.user.email, selectedRoles);
        this.rolesUpdated.emit();

        this.onClose();
    }

    public onClose()
    : void {
        this.modalComponent.close();
    }

    public onSelectedRolesChange(role: LocalizedMessage, value: boolean)
    : void {
        this.selectedRoles[role.codeName] = value;
    }

    private async loadRoleOptions()
    : Promise<void> {
        this.roleOptions = await this.userRolesEditorService.loadRolesOptions();
    }

    private updateSelectedRoles()
    : void {
        const selectedRoles = {};
        const userRoles = this.user.roles.map(role => role.codeName);

        this.roleOptions.forEach(roleOption => {
            selectedRoles[roleOption.codeName] = userRoles.includes(roleOption.codeName);
        });

        this.selectedRoles = selectedRoles;
    }
}
