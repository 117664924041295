import { PrivilegesEditorConfig } from "./privileges-editor-config.model";

export class PrivilegesEditorApi {

    public close: Function;
    public open: (config: PrivilegesEditorConfig) => void;

    constructor(init?: Partial<PrivilegesEditorApi>) {
        Object.assign(this, init);
    }
}
