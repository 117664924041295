import { Component } from "@angular/core";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: [ "./reports.component.scss" ],
})
export class ReportsComponent {

    public date: string | Date;

    public readonly defaultDay: string;

    constructor() {
        this.defaultDay = new Date().toISOString();

        this.date = this.defaultDay;
    }
}
