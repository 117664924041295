import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { GetOrdersConfig } from "../models/get-orders-config.model";
import { AbstractHttpService } from "../abstract-http.service";

@Injectable({
    providedIn: "root"
})
export class OrderHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/services`;
    }

    public getOrders(config: GetOrdersConfig): Observable<any> {
        // status PENDING, DONE, DECLINED

        const { size, page, sortOptions } = config;

        let request = `${this.path}?size=${size}&page=${page}`;

        if ( sortOptions.hasOwnProperty("status") ) {
            request = `${request}&status=${sortOptions.status}`;
        }

        if ( sortOptions.hasOwnProperty("username") ) {
            request = `${request}&username=${sortOptions.username}`;
        }

        return this.httpClient
            .get(request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public postOrders(type: string)
    : Observable<any> {
        // for this time only type=="DORMITORY_REGISTRATION"

        const body = JSON.stringify(type);

        return this.httpClient
            .post(`${this.path}/`, body)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public patchOrders(id: number, status: string)
    : Observable<any> {
        const body = JSON.stringify(status);
        return this.httpClient.patch(`${this.path}/${id}`, body);
    }
}
