import { filter, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ProfileModalContainerComponent } from "../containers/profile-modal-container.component";
import { ViewRefHelperService } from "../../../shared/services/helpers/view-ref-helper.service";
import { ProfileStateService } from "../../../core/profile/services/profile-state.service";

@Injectable({
    providedIn: "root",
})
export class ProfileModalService {

    constructor(
        private profileStateService: ProfileStateService,
        private viewRefHelperService: ViewRefHelperService,
    ) {
        this.showProfileFormIfNeeded();
    }

    public get profileRequiresUpdate()
    : Observable<boolean> {
        return this.profileStateService.profile$
            .pipe(
                filter(val => !!val),
                map(profile => profile.requiredUpdate)
            );
    }

    public showProfileFormIfNeeded()
    : void {
        this.profileRequiresUpdate
            .subscribe(requiresUpdate => {
           if (requiresUpdate) {
               this.showProfileForm();
           }
        });
    }

    public showProfileForm()
    : void {
        const componentRef = this.viewRefHelperService.dynamicallyInsertComponent(ProfileModalContainerComponent);
        const api = componentRef.instance.api;

        api.open();
    }
}
