import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { AdalService } from "adal-angular4";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileInfo } from "../../../shared/http-services/profile/models/profile-info.model";
import { FormValidatorsService } from "../../../shared/validators/services/form-validators.service";
import { DateHelperService } from "../../../shared/services/helpers/date-helper.service";

@Injectable()
export class ProfileModalFormService {

    constructor(
        private formBuilder: FormBuilder,
        private formValidatorsService: FormValidatorsService,
        private adalService: AdalService
    ) {
    }

    public buildForm(profile: ExtendedProfile)
    : FormGroup {
        return this.formBuilder.group({
            username: [
                profile.username || this.adalService.userInfo.profile.name,
                Validators.required
            ],
            dateBirth: [profile.dateBirth, Validators.required],
            degree: [profile.degree && profile.degree.codeName, Validators.required],
            educationType: [profile.educationType && profile.educationType.codeName, Validators.required],
            faculty: [profile.faculty && profile.faculty.codeName, Validators.required],
            gender: [profile.gender && profile.gender.codeName, Validators.required],
            speciality: [profile.speciality && profile.speciality.codeName, Validators.required],
        });
    }

    public mapToModel(form: FormGroup)
    : ProfileInfo {
        const {username, dateBirth, degree, educationType, faculty, gender, speciality} = form.value;

        return new ProfileInfo({
            dateBirth: DateHelperService.formatDateIso(dateBirth),
            username, degree, educationType, faculty, gender, speciality
        });
    }
}
