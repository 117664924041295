import { User } from "./user.model";

export class Building {

    public name: string;
    public active: boolean;
    public dormitoryManager: User;
    public membersOfCouncil: User[];
    public id?: number;
    public address?: string;

    constructor(init?: Partial<Building>) {
        Object.assign(this, init);
    }
}
