import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthModule } from "./auth/auth.module";
import { ProfileModule } from "./profile/profile.module";
import { PipesModule } from "./pipes/pipes.module";
import { RolesModule } from "./roles/roles.module";

@NgModule({
    imports: [
        AuthModule.forRoot(),
        BrowserAnimationsModule,
        ProfileModule.forRoot(),
        PipesModule.forRoot(),
        RolesModule.forRoot(),
        NgxSpinnerModule,
    ],
    providers: [
        CurrencyPipe,
    ],
    exports: [
        BrowserAnimationsModule,
        NgxSpinnerModule,
    ]
})
export class CoreModule {

}
