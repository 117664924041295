import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { RoomDetails } from "../../../../../shared/models/room-details.model";
import { EditRoom } from "../../../../../shared/models/edit-room.model";

@Injectable()
export class RoomEditorFormService {

    constructor(
       private formBuilder: FormBuilder,
    ) {}

    public buildForm(value: RoomDetails)
    : FormGroup {
        return this.formBuilder.group({
            active: true,
            status: [
                value.status,
                Validators.required,
                ],
            dormitory: [
                value.dormitory,
                [
                    Validators.required,
                ],
            ],
            floor: [
                value.floor,
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(99)
                ],
            ],
            number: [
                value.number,
                [
                    Validators.required,
                ],
            ],
            maxResidents: [
                value.maxResidents,
                [
                    Validators.required,
                ],
            ],
            privileges: [ value.privileges ],
        });
    }

    public getRoomFromForm(form: FormGroup)
    : EditRoom {
        const { status, dormitory, floor, number, maxResidents, privileges } = form.value;

        return new EditRoom({
            status: status.codeName,
            dormitory,
            floor,
            number,
            maxResidents,
            privileges,
        });
    }
}
