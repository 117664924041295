import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ColDef, GridOptions } from "ag-grid-community";
import * as _ from "lodash";

import { Property } from "../../../shared/http-services/properties/models/property.model";
import { PropertiesTableService } from "../services/properties-table.service";

@Component({
    selector: "app-properties",
    templateUrl: "./properties.component.html",
    styleUrls: [ "./properties.component.scss" ],
    providers: [
        PropertiesTableService,
    ]
})
export class PropertiesComponent implements OnChanges {

    @Input()
    public properties: Property[];

    @Output()
    public updateProperties: EventEmitter<Property[]>;

    public changedProperties: Property[];
    public colDefs: ColDef[];
    public gridOptions: GridOptions;

    private initialProperties: Property[];

    constructor(
        private propertiesTableService: PropertiesTableService,
    ) {
        this.initTableOptions();

        this.updateProperties = new EventEmitter<Property[]>();
    }

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.properties) {
            this.updateInitialProperties();
        }
    }

    public get loaded()
    : boolean {
        return !!this.properties;
    }

    public get valuesChanged()
    : boolean {
        return this.changedProperties && this.changedProperties.length > 0;
    }

    private updateChangedProperty()
    : void {
        if (_.isEqual(this.properties, this.initialProperties)) {
            return null;
        }

        const changedProperties = this.properties.filter(property => {
                const sameInitialProperty = this.initialProperties.find(initialProperty => initialProperty.name === property.name);
                return property.value !== sameInitialProperty.value;
            });

        this.changedProperties = changedProperties;
    }

    public onCellEdited()
    : void {
        this.updateChangedProperty();
    }

    public onSave()
    : void {
        if (this.changedProperties && this.changedProperties.length) {
            this.onUpdateProperties();
        }

    }

    private initTableOptions()
    : void {
        this.colDefs = this.propertiesTableService.getColumnDefs();
        this.gridOptions = this.propertiesTableService.getGridOptions(this);
    }

    private updateInitialProperties()
    : void {
        this.initialProperties = _.cloneDeep(this.properties);
    }

    private onUpdateProperties()
    : void {
        this.updateProperties.emit(this.changedProperties);
        this.changedProperties = null;
    }
}
