export class Privilege {

    public privilege: string;
    public validSince: string;
    public validTo: string;
    public id?: number;

    constructor(init?: Partial<Privilege>) {
        Object.assign(this, init);
    }
}
