import { MatProgressSpinnerModule } from "@angular/material";
import { NgModule } from "@angular/core";

import { PageLoaderComponent } from "./components/page-loader.component";

@NgModule({
    imports: [
        MatProgressSpinnerModule,
    ],
    declarations: [
        PageLoaderComponent,
    ],
    exports: [
        PageLoaderComponent,
    ]
})
export class PageLoaderModule {

}
