import { interval, Observable, Subject } from "rxjs";
import { AdalService } from "adal-angular4";
import { Injectable } from "@angular/core";
import { filter, first, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class LoginProcessService {

    public tokenApplied$: Observable<boolean>;

    private tokenApplied: Subject<boolean>;

    constructor(
        private adalService: AdalService,
    ) {
        this.tokenApplied = new Subject<boolean>();
        this.tokenApplied$ = this.tokenApplied.asObservable();

        this.initLoginInProgressCheck();
    }

    public get whenTokenApplied$()
    : Observable<boolean> {
        return this.tokenApplied$
            .pipe(
                filter(val => {
                    return val;
                }),
                first(),
            );
    }

    private initLoginInProgressCheck()
    : void {
        const timeout = interval(200);

        timeout.subscribe(
            () => {
                return this.tokenApplied.next(!!this.adalService.userInfo.token);
            }
        );
    }
}

