import { LocalizedMessage } from "../../../models/privilege-status.model";

export class ExtendedProfileInfo {

    public username: LocalizedMessage;
    public dateBirth: LocalizedMessage | Partial<LocalizedMessage>;
    public degree: LocalizedMessage;
    public educationType: LocalizedMessage;
    public faculty: LocalizedMessage;
    public gender: LocalizedMessage;
    public speciality: LocalizedMessage;

    constructor(init?: Partial<ExtendedProfileInfo>) {
        Object.assign(this, init);
    }
}
