import { Component, Input } from "@angular/core";

import { NavigationTab } from "../models/navigation-tab.model";

@Component({
    selector: "app-navigation-tabs",
    templateUrl: "./navigation-tabs.component.html",
    styleUrls: ["./navigation-tabs.component.scss"]
})
export class NavigationTabsComponent {

    @Input()
    public currentTab: NavigationTab;
    @Input()
    public tabs: NavigationTab[];

    public getIsActive(tab: NavigationTab)
    : boolean {
        return this.currentTab.title === tab.title;
    }

    public getIsFirstToggler(title: string)
    : boolean {
        return this.tabs && this.tabs[0].title === title;
    }

    public getIsLastToggler(title: string)
    : boolean {
        return this.tabs && this.tabs[this.tabs.length - 1].title === title;
    }
}
