import { Routes } from "@angular/router";

import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";
import { LogsComponent } from "./containers/logs.component";
import { RoleGuard } from "../../core/roles/guards/role.guard";

export const routes: Routes = [
    {
        path: "logs",
        component: LogsComponent,
        canActivate: [ AuthenticationGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.canViewLogs,
        }
    }
];
