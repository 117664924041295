import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material";
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";

import { User } from "../../../../shared/models/user.model";
import { OrganizationNavService } from "../../services/organization-nav.service";
import { NavigationTab } from "../../../../shared/components/navigation-tabs/models/navigation-tab.model";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { OptionsHttpService } from "../../../../shared/http-services/options/options-http.service";
import { UsersTabFilterFormService } from "./services/users-tab-filter-form.service";
import { GetUsersRequest } from "../../../../shared/http-services/users/models/get-users-request.model";
import { UsersHttpService } from "../../../../shared/http-services/users/users-http.service";
import { OpenSettlementForUserComponent } from "../open-settlement-for-user/open-settlement-for-user.component";

@Component({
    selector: "app-users-tab",
    templateUrl: "./users-tab.component.html",
    styleUrls: ["./users-tab.component.scss"]
})
export class UsersTabComponent implements OnInit {

    public currentTab: NavigationTab;

    public filterForm: FormGroup;
    public isLoadingSpecialities: boolean;
    public isLoaded: boolean;
    public users: User[];
    public actions: string[];

    public totalElements = 0;
    public currentPage = 0;
    public recordsPerPage = 5;

    public degreeOptions$: Observable<LocalizedMessage[]>;
    public facultyOptions$: Observable<LocalizedMessage[]>;
    public roleOptions$: Observable<LocalizedMessage[]>;
    public educationTypeOptions$: Observable<LocalizedMessage[]>;
    public genderOptions$: Observable<LocalizedMessage[]>;
    public specialityFilterOptions$: Observable<LocalizedMessage[]>;
    public specialityOptions: LocalizedMessage[];

    constructor(
        private organizationNavService: OrganizationNavService,
        private optionsHttpService: OptionsHttpService,
        private usersTabFilterFormService: UsersTabFilterFormService,
        private toastrService: ToastrService,
        private usersHttpService: UsersHttpService,
    ) {
        this.currentTab = this.organizationNavService.usersNavTab;
        this.users = [];
        this.setOptions();
    }

    public ngOnInit()
    : void {
        this.buildForm();
        this.loadUsers();
    }

    private setOptions()
    : void {
        this.degreeOptions$ = this.optionsHttpService.getDegreeOptions();
        this.facultyOptions$ = this.optionsHttpService.getFacultyOptions();
        this.roleOptions$ = this.optionsHttpService.getRoleOptions();

        this.educationTypeOptions$ = this.optionsHttpService.getEducationTypeOptions();
        this.genderOptions$ = this.optionsHttpService.getGenderOptions();
        this.optionsHttpService.getActionOptions()
            .subscribe(
                res => this.actions = res,
                error => this.toastrService.error(error.message)
            );
    }

    private buildForm()
    : void {
        this.filterForm = this.usersTabFilterFormService.buildForm();
    }

    public applyFilter()
    : void {
        this.currentPage = 0;
        this.loadUsers();
    }

    public get request()
    : GetUsersRequest {

        const filterForm = this.usersTabFilterFormService.mapToModel(this.filterForm);
        const filters = {};
        Object.keys(filterForm).forEach(key => {

            if (filterForm[key] !== "null" && filterForm[key] !== null) {
                if (key === "degree") {
                    filters["year"] = filterForm["degree"];
                } else {
                    filters[key] = filterForm[key];
                }
            }

        });

        return new GetUsersRequest({
            page: this.currentPage.toString(),
            size: this.recordsPerPage.toString(),
            filters
        });
    }

    public loadSpecialities(faculty: string | null, filter?: boolean)
    : void {
        if (filter) {
            this.specialityFilterOptions$ = this.optionsHttpService.getSpecialityOptionsByFaculty(faculty);
        }

        if (!faculty) {
            this.specialityOptions = [];
            return;
        }

        this.isLoadingSpecialities = true;

        this.optionsHttpService.getSpecialityOptionsByFaculty(faculty)
            .pipe(
                finalize(() => this.isLoadingSpecialities = false),
            )
            .subscribe(
                specialities => this.specialityOptions = specialities,
                err => this.toastrService.error(err.message),
            );
    }

    private updateUsers(res: any)
    : void {
        this.users = res.content;
        this.recordsPerPage = res.limit;
        this.currentPage = res.skip / res.limit;
        this.totalElements = res.totalElements;
    }

    public loadUsers()
    : void {
        this.isLoaded = false;

        this.usersHttpService.getUsers(this.request)
            .pipe(
                finalize(() => this.isLoaded = true),
            )
            .subscribe(
                res => {
                    this.updateUsers(res);
                },
                error => this.toastrService.error(error.message)
            );
    }

    public changePage($event: PageEvent)
    : void {
        this.currentPage = $event.pageIndex;
        this.loadUsers();
    }

    public getRolesLocalizedString(roles: LocalizedMessage[])
    : string {
        const localizedRoles = roles.map(role => role.localizedName);
        return localizedRoles.join(", ");
    }

    public openSettlement(popup: OpenSettlementForUserComponent, user: User)
    : void {
        popup.userEmail = user.email;
        popup.open();
    }

    public closeSettlement(user: User)
    : void {
        this.usersHttpService.closeActiveSettlementAllowance(user.email)
            .subscribe(() => {
                this.toastrService.success(`Поселення для користувача(-ки) ${user.username} закрите.`);
                this.loadUsers();
            },
            err => this.toastrService.error(err.message));
    }

    public anyActionToChange(user: User)
    : boolean {
        return this.actionPutUser() || this.actionOpenSettlement() || this.actionEvictUser() || this.actionCancelRoomRequest(user);
    }

    public actionCancelRoomRequest(user: User)
    : boolean {
        if (!this.actions) {
            return ;
        }

        return user.livingRoomRequest && this.actions.includes("USERS_PAGE_CANCEL_ROOM_REQUEST");
    }

    public actionPutUser()
    : boolean {
        if (!this.actions) {
            return ;
        }

        return this.actions.includes("USERS_PAGE_EDIT_USER");
    }

    public actionOpenSettlement()
    : boolean {
        if (!this.actions) {
            return ;
        }

        return this.actions.includes("USERS_PAGE_OPEN_SETTLEMENT");
    }

    public actionEvictUser()
    : boolean {
        if (!this.actions) {
            return ;
        }

        return this.actions.includes("USERS_PAGE_EVICT");
    }

    public actionBanUser()
    : boolean {
        if (!this.actions) {
            return ;
        }

        return this.actions.includes("USERS_PAGE_BAN_USER");
    }

    public actionUnbanUser()
    : boolean {
        if (!this.actions) {
            return ;
        }

        return this.actions.includes("USERS_PAGE_UNBAN_USER");
    }

    public banUser(user: User)
    : void {
        const request = {
            email: user.email,
        };

        this.usersHttpService.banUser(request)
            .subscribe(
                () => {
                    this.toastrService.success("Користувача " + user.username + " успішно заблоковано");
                    this.loadUsers();
                },
                error => this.toastrService.error(error.message)
            );

    }

    public unbanUser(user: User)
    : void {
        const request = {
            email: user.email,
        };

        this.usersHttpService.unbanUser(request)
            .subscribe(
                () => {
                    this.toastrService.success("Користувача " + user.username + " успішно розблоковано");
                    this.loadUsers();
                },
                error => this.toastrService.error(error.message)
            );

    }
}
