import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { SystemProperty } from "./models/system-property.model";

@Injectable({
    providedIn: "root"
})
export class PropertiesHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}/properties`;
    }

    public get()
    : Observable<SystemProperty[]> {
        const params = this.buildHttpParams({
            "page": 1,
            "size": 100,
        });

        return this.httpClient
            .get(`${this.path}`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
