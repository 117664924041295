import { Component, EventEmitter, Input, Output } from "@angular/core";

import { User } from "../../../../shared/models/user.model";

@Component({
    selector: "app-migration-candidate-card",
    templateUrl: "./migration-candidate-card.component.html",
    styleUrls: [ "./migration-candidate-card.component.scss" ],
})
export class MigrationCandidateCardComponent {

    @Input()
    public removable: boolean;
    @Input()
    public candidate: User;

    @Output()
    public remove: EventEmitter<void>;

    constructor() {
        this.remove = new EventEmitter<void>();
    }

    public onRemove()
        : void {
        this.remove.emit();
    }
}
