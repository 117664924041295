import { Component, EventEmitter, Input, Output } from "@angular/core";

import { RoomRequest } from "../../../../shared/http-services/models/room-request.model";
import { Period } from "../../../../shared/http-services/periods/models/period.model";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { Profile } from "../../../../shared/http-services/profile/models/profile.model";

@Component({
    selector: "app-room-request-card",
    styleUrls: ["./room-request-card.component.scss"],
    templateUrl: "./room-request-card.component.html",
})
export class RoomRequestCardComponent {

    @Input()
    public profile: Profile;
    @Input()
    public roomRequest: RoomRequest;
    @Input()
    public roomRequestStatuses: LocalizedMessage[];
    @Input()
    public settlementExtensionPeriod: Period;

    @Output()
    public extendRoomRequest: EventEmitter<number>;

    public constructor() {
        this.extendRoomRequest = new EventEmitter<number>();
    }

    public isActive()
    : boolean {
        const status = this.roomRequest.status.codeName;
        return status === "LIVING" || status === "PENDING_PAYMENT";
    }

    public  get isExtendable()
    : boolean {
        return this.isActive() && this.roomRequest.extendable;
    }

    public onExtendRoomRequest()
    : void {
        return this.extendRoomRequest.emit(this.roomRequest.id);
    }
}
