import { Pipe, PipeTransform } from "@angular/core";

import { LocalizedMessage } from "../../shared/models/privilege-status.model";

@Pipe({
    name: "localizedValue"
})
export class LocalizedValuePipe implements PipeTransform {

    public transform(value: string, options: LocalizedMessage[])
        : string | null {
        if (!options || !value) {
            return null;
        }

        const opt = options.find(option => option.codeName === value);
        return opt.localizedName;
    }
}

