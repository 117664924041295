import { Component, Input } from "@angular/core";

@Component({
    selector: "app-cross-tick",
    templateUrl: "./cross-tick.component.html",
    styleUrls: [ "./cross-tick.component.scss" ],
})
export class CrossTickComponent {

    @Input()
    public value: boolean;
}

