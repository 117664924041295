import { Component } from "@angular/core";
import { Observable } from "rxjs";

import { NavigationService } from "../../../shared/services/application-state/navigation.service";
import { Link } from "../../../shared/models/link.model";
import { ProfileModalService } from "../../../features/profile-modal/services/profile-modal.service";

@Component({
    selector: "app-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: [ "./navigation.component.scss" ],
})
export class NavigationComponent {

    public navigationLinks: Link[];

    constructor(
        private navigationService: NavigationService,
        private profileModalService: ProfileModalService,
    ) {
        this.initNavigationLinks();
    }

    public get loaded()
    : boolean {
        return !!this.navigationLinks;
    }

    public get profileRequiresUpdate()
    : Observable<boolean> {
        return this.profileModalService.profileRequiresUpdate;
    }

    public openUserForm()
    : void {
        this.profileModalService.showProfileForm();
    }

    private initNavigationLinks()
    : void {
        this.navigationService.navigationLinks$
            .subscribe(links => {
                this.navigationLinks = links;
            });
    }
}
