import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";

import { Privilege } from "../../../../../shared/http-services/privileges/models/privilege.model";
import { DateHelperService } from "../../../../../shared/services/helpers/date-helper.service";

@Injectable()
export class PrivilegesEditorFormService {

    constructor(
       private formBuilder: FormBuilder,
    ) {}

    public buildForm(value: Privilege)
    : FormGroup {
        return this.formBuilder.group({
            privilege: [ value.privilege, Validators.required ],
            validSince: [ value.validSince, Validators.required ],
            validTo: [ value.validTo, Validators.required ],
            id: value.id,
        });
    }

    public mapToModel(form: FormGroup)
    : Privilege {
        const { privilege, validSince, validTo, id } = form.value;

        return new Privilege({
            validTo: DateHelperService.formatDateIso(validTo),
            validSince: DateHelperService.formatDateIso(validSince),
            privilege,
            id,
        });
    }
}
