import { Routes } from "@angular/router";

import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { PaymentsComponent } from "./containers/payments.component";

export const routes: Routes = [
    {
        path: "payments",
        component: PaymentsComponent,
        canActivate: [ AuthenticationGuard, ProfileFormGuard ]
    }
];

