import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AdalService} from "adal-angular4";
import {Observable} from "rxjs";

@Injectable()
export class TokenAppenderInterceptor implements HttpInterceptor {

    constructor(
        private adalService: AdalService,
    ) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpEvent<any>> {

        if (this.adalService.userInfo.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.adalService.userInfo.token}`,
                    "Content-Type": "application/json",
                    "Accept-Language": "ua"
                }
            });
        }

        return next.handle(request);
    }
}
