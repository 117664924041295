import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../shared/shared.module";
import { NavigationComponent } from "./components/navigation.component";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { NavigationMenuComponent } from "./components/navigation-menu-item/navigation-menu.component";

@NgModule({
    declarations: [
        NavigationComponent,
        NavigationMenuComponent,
    ],
    imports: [
        PageLoaderModule,
        CommonModule,
        RouterModule,
        SharedModule,
    ],
    exports: [
        NavigationComponent,
    ]
})
export class NavigationModule { }

