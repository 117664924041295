import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { tap } from "rxjs/operators";

import { UserRolesStateService } from "../services/user-roles-state.service";

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        private userRolesStateService: UserRolesStateService,
        private router: Router,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | Observable<boolean> {
        if (_.has(route, "data.isInRole")) {
            const requiredRoles = _.get(route, "data.isInRole");

            return this.userRolesStateService.getIsInRole(requiredRoles)
                .pipe(
                    tap(value => {
                        if (!value) {
                            this.router.navigate([ "/" ]);
                        }
                    })
                );
        }

        return true;
    }
}
