import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { finalize } from "rxjs/operators";

import { MigrationRequestHttpService } from "../../../shared/http-services/migration-request/migration-request-http.service";
import { PagedSortedResponse } from "../../../shared/http-services/models/paged-sorted-response.model";
import { MigrationApprovalViewApi } from "../models/migration-approval-view-api.model";
import { PagingParams } from "../../../shared/http-services/models/paging-params";
import { MigrationRequestCase } from "../models/migration-request-case.model";
import { MigrationStatusService } from "../services/migration-status.service";

@Component({
    selector: "app-migration-approval",
    templateUrl: "./migration-approval.component.html",
})
export class MigrationApprovalComponent {

    public activeMigrationsResponse: PagedSortedResponse<MigrationRequestCase>;
    public loading: boolean;
    public changingMigrationStatus: boolean;
    public viewApi: MigrationApprovalViewApi;

    constructor(
       private migrationRequestHttpService: MigrationRequestHttpService,
       private toastrService: ToastrService,
       private migrationStatusService: MigrationStatusService,
    ) {}

    public async onApproveMigration(migrationCase: MigrationRequestCase)
    : Promise<void> {
        this.changingMigrationStatus = true;

        await this.migrationStatusService.onApproveMigration(migrationCase);

        this.changingMigrationStatus = false;
        this.reloadData();
    }

    public onChangePage()
    : void {
        this.reloadData();
    }

    public async onRejectMigration(migrationCase: MigrationRequestCase)
    : Promise<void> {
        this.changingMigrationStatus = true;

        await this.migrationStatusService.onRejectMigration(migrationCase);

        this.changingMigrationStatus = false;
        this.reloadData();
    }

    public onViewReady(api: MigrationApprovalViewApi)
    : void {
        this.viewApi = api;
        this.reloadData();
    }

    private loadData(payload: PagingParams)
    : void {
        this.loading = true;

        this.migrationRequestHttpService.getActiveMigrationRequests(payload)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(
                res => this.activeMigrationsResponse = res,
                error => this.toastrService.error(error.message)
            );
    }

    private reloadData()
    : void {
        this.loadData(this.viewApi.getPagingPayload());
    }
}
