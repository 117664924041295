import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OptionsHttpService } from "../../shared/http-services/options/options-http.service";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { UserInfoCardEditorApi } from "../user-info-card-editor/models/user-info-card-editor-api.model";
import { UserInfoCardOptions } from "./models/user-info-card-options.model";

@Component({
    selector: "app-user-info-card",
    templateUrl: "user-info-card.component.html",
    styleUrls: [
        "user-info-card.component.scss",
    ]
})
export class UserInfoCardComponent {

    @Input()
    public user: UserDetails;

    @Output()
    public updated: EventEmitter<void>;

    public options: UserInfoCardOptions;

    private editorApi: UserInfoCardEditorApi;

    constructor(
        private optionsHttpService: OptionsHttpService,
        private toastrService: ToastrService,
    ) {
        this.updated = new EventEmitter<void>();

        this.loadOptions();
    }

    public onEdit()
    : void {
        this.editorApi.open(this.user);
    }

    public onEditorReady(api: UserInfoCardEditorApi)
    : void {
        this.editorApi = api;
    }

    public onUpdated()
    : void {
        this.updated.emit();
    }

    private async loadOptions()
    : Promise<void> {
        const optionsAsync = Promise.all([
            this.optionsHttpService.getGenderOptions().toPromise(),
            this.optionsHttpService.getDegreeOptions().toPromise(),
            this.optionsHttpService.getFacultyOptions().toPromise(),
            this.optionsHttpService.getSpecialityOptions().toPromise(),
            this.optionsHttpService.getEducationTypeOptions().toPromise(),
        ]);

        try {
            const [ gender, degree, faculty, speciality, educationType ] = await optionsAsync;

            this.options = {
              gender, degree, faculty, speciality, educationType
            };
        } catch (error) {
            this.toastrService.error(error.message);
        }
    }
}
