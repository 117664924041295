import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AbstractHttpService } from "../abstract-http.service";
import { LogsRequestModel } from "./models/logs-request.model";
import { LogsResponseModel } from "./models/logs-response.model";

@Injectable({
    providedIn: "root",
})
export class LogsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/audit`;
    }

    public get(request: LogsRequestModel)
    : Observable<LogsResponseModel> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient
            .get(`${this.path}`, {params})
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
