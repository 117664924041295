import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxSmartModalModule } from "ngx-smart-modal";

import { SettlementDocumentsComponent } from "./settlement-documents.component";
import { SettlementDocumentsModalComponent } from "./components/settlement-documents-modal.component";

@NgModule({
    imports: [
        CommonModule,
        NgxSmartModalModule,
    ],
    declarations: [
        SettlementDocumentsComponent,
        SettlementDocumentsModalComponent,
    ],
    exports: [
        SettlementDocumentsComponent,
        SettlementDocumentsModalComponent,
    ]
})
export class SettlementDocumentsModule {}
