import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { LocalizedMessage } from "../../models/privilege-status.model";
import { AbstractHttpService } from "../abstract-http.service";
import { Entity } from "../../models/entity.model";

@Injectable({
    providedIn: "root"
})
export class OptionsHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${this.apiUrl}`;
    }

    public getDegreeOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/degrees/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getDormitoriesOptions()
    : Observable<Entity[]> {
        return this.httpClient
            .get(`${this.path}/dormitories/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getEducationTypeOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/educationTypes/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getFacultyOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/faculties/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getGenderOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/genders/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getPrivilegeOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/privileges/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoomRequestStatuses()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/room-request/statuses/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getSpecialityOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/specialities/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getSpecialityOptionsByFaculty(faculty: string = "FACULTY_OF_COMPUTER_SCIENCES")
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/${faculty}/specialities/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoleOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/roles/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getActionOptions()
    : Observable<string[]> {
        return this.httpClient
            .get(`${this.path}/actions/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoomStatusOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/room-statuses/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public getRoomAvailabilityStatusOptions()
        : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/room-availability-statuses/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
