export class ProfileInfo {

    public username: string;
    public dateBirth: string;
    public degree: string;
    public educationType: string;
    public faculty: string;
    public gender: string;
    public speciality: string;

    constructor(init?: Partial<ProfileInfo>) {
        Object.assign(this, init);
    }
}
