import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";

import { NavigationTabsComponent } from "./components/navigation-tabs.component";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
    ],
    declarations: [
        NavigationTabsComponent,
    ],
    exports: [
        NavigationTabsComponent,
    ]
})
export class NavigationTabsModule { }
