
export interface Room {

    dormitory: string;
    floor: number;
    maxResidents: number;
    number: string;
    settlementAvailable?: boolean;
    status: RoomStatus;
    id?: number;
}

export interface RoomStatus {

    codeName: string;
    localizedName: string;
}

export enum RoomStatusName {

    Available = "AVAILABLE",
    Reserved = "RESERVED",
    Blocked = "BLOCKED",
}

