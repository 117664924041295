import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AgGridModule } from "ag-grid-angular";
import { NgModule } from "@angular/core";

import { NavigationTabsModule } from "./components/navigation-tabs/navigation-tabs.module";
import { HeaderComponent } from "./components/header/header.component";
import { PagerComponent } from "./components/pager/pager.component";
import { AppRoutingModule } from "../app-routing.module";
import {
    MatCheckboxModule,
    MatDatepickerModule, MatFormFieldModule,
    MatIconModule, MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule, MatSelectModule
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ServicesModule } from "./services/services.module";
import { PipesModule } from "./pipes/pipes.module";
import { FooterComponent } from "./components/footer/footer.component";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutElementsModule } from "./layout-elements/layout-elements.module";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import { NgSelectModule } from "@ng-select/ng-select";
import { HeaderDropDownMenuComponent } from "./components/header/header-drop-down-menu/header-drop-down-menu.component";
import { HeaderDropDownIsLinkActivePipe } from "./components/header/header-drop-down-menu/pipes/header-drop-down-is-link-active.pipe";
import { SettlementDocumentsModule } from "./components/settlement-documents/settlement-documents.module";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        PagerComponent,
        HeaderDropDownMenuComponent,
        HeaderDropDownIsLinkActivePipe
    ],
    imports: [
        AgGridModule,
        AppRoutingModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSelectModule,
        NavigationTabsModule,
        NgSelectModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule,
        ServicesModule,
        NgxSmartModalModule.forRoot(),
        MatMomentDateModule
    ],
    exports: [
        NgSelectModule,
        AgGridModule,
        BrowserAnimationsModule,
        BrowserModule,
        FooterComponent,
        FormsModule,
        HeaderComponent,
        LayoutElementsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTabsModule,
        NavigationTabsModule,
        PagerComponent,
        PipesModule,
        ReactiveFormsModule,
        RouterModule,
        ServicesModule,
        NgxSmartModalModule,
        SettlementDocumentsModule,
    ]
})
export class SharedModule { }
