import { NgModule } from "@angular/core";

import { PropertiesContainerComponent } from "./containers/properties-container.component";
import { PropertiesComponent } from "./components/properties.component";
import { SharedModule } from "../../shared/shared.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import {DashboardModule} from "../dashboard/dashboard.module";
import {MatMomentDateModule} from "@angular/material-moment-adapter";

@NgModule({
    declarations: [
        PropertiesComponent,
        PropertiesContainerComponent,
    ],
    imports: [
        SharedModule,
        PageLoaderModule,
        DashboardModule,
        MatMomentDateModule
    ],
    exports: [
        PropertiesContainerComponent
    ]
})
export class PropertiesModule { }
