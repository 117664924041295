import { Component } from "@angular/core";
import { environment } from "../environments/environment";
import { AdalService } from "adal-angular4";
import localeUk from "@angular/common/locales/uk";
import { registerLocaleData } from "@angular/common";

import { AnalyticsService } from "./core/analytics/analytics.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [ "./app.component.scss" ],
    providers: [
        AnalyticsService,
    ]
})
export class AppComponent {

    constructor(
        private adalService: AdalService,
        private analyticsService: AnalyticsService,
    ) {
        registerLocaleData(localeUk);

        this.adalService.init(environment.config);
        this.analyticsService.initRoutesTrackingGA();
    }
}
