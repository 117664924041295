import { Component, OnInit } from "@angular/core";
import { AdalService } from "adal-angular4";
import { Observable } from "rxjs";

import { NavigationService } from "../../services/application-state/navigation.service";
import { Link } from "../../models/link.model";
import { ProfileModalService } from "../../../features/profile-modal/services/profile-modal.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: [ "./header.component.scss" ],
})
export class HeaderComponent implements OnInit {

    public name: String;
    public navigationLinks: Link[];

    public isCollapsed = false;

    constructor(
        private adalService: AdalService,
        private navigationService: NavigationService,
        private profileModalService: ProfileModalService,
    ) {
        this.initNavigationLinks();
    }

    public ngOnInit()
    : void {
        this.adalService.handleWindowCallback();

        this.name = this.adalService.userInfo.profile.name;
    }

    public get isAuthenticated()
    : boolean {
        return this.adalService.userInfo.authenticated;
    }

    public get profileRequiresUpdate()
    : Observable<boolean> {
        return this.profileModalService.profileRequiresUpdate;
    }

    public get mobileTogglerClassName()
    : string {
        return this.isCollapsed
            ? "bg-c-orange"
            : "bg-c-white";
    }

    public toggleCollapse()
    : void {
        this.isCollapsed = !this.isCollapsed;
    }

    public logout()
    : void {
        this.adalService.logOut();
    }

    private initNavigationLinks()
    : void {
        this.navigationService.navigationLinks$
            .subscribe((links) => this.navigationLinks = links);
    }
}
