import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";

import { ModalApi } from "../../../models/modal-api.interface";

@Component({
    selector: "app-settlement-documents-modal",
    templateUrl: "settlement-documents-modal.component.html",
    styleUrls: ["settlement-documents-modal.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SettlementDocumentsModalComponent implements OnInit {

    @ViewChild("documentsModal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public ready: EventEmitter<ModalApi>;

    constructor() {
        this.ready = new EventEmitter<ModalApi>();
    }

    public ngOnInit()
    : void {
        this.ready.emit({
            open: () => this.modalComponent.open(),
        });
    }
}
