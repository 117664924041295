import { Privilege } from "../http-services/privileges/models/privilege.model";

export class AddPrivilegeConfig {

    public callback: Function;
    public privilege: Privilege;

    constructor(init?: Partial<AddPrivilegeConfig>) {
        Object.assign(this, init);
    }
}
