import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocalizedMessage } from "../../models/privilege-status.model";
import { AbstractHttpService } from "../abstract-http.service";
import { GetPrivilegesResponse } from "./models/get-privileges-response.model";
import { GetPrivilegesRequest } from "./models/get-privileges-request.model";
import { ExtendedPrivilege } from "../client-privileges/models/extended-privilege.model";
import { PrivilegeUpdateModel } from "./models/privilege-update-model.model";
import { Privilege } from "./models/privilege.model";

@Injectable({
    providedIn: "root"
})
export class PrivilegesHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/privileges`;
    }

    public get(request: GetPrivilegesRequest)
    : Observable<GetPrivilegesResponse> {
        const params = this.buildHttpParams(request);

        return this.httpClient
            .get(`${this.path}`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public add(email: string, value: Privilege)
    : Observable<void> {
        return this.httpClient
            .post<void>(`${this.apiUrl}/users/${email}/privileges`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }

    public getOptions()
    : Observable<LocalizedMessage[]> {
        return this.httpClient
            .get(`${this.path}/options`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public update(request: PrivilegeUpdateModel, id: number)
    : Observable<ExtendedPrivilege> {
        return this.httpClient
            .patch(`${this.path}/${id}`, request)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }

    public delete(email: string, id: number)
    : Observable<void> {
        return this.httpClient
            .delete(`${this.apiUrl}/users/${email}/privileges/${id}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this)),
            );
    }
}
