import { ToastrService } from "ngx-toastr";
import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileHttpService } from "../../../shared/http-services/profile/profile-http.service";
import { WrappedError } from "../../../shared/http-services/models/wrapped-error.model";
import { RoomRequestHttpService } from "../../../shared/http-services/room-request/room-request-http.service";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { AddPrivilegeConfig } from "../../../shared/models/add-privilege-config.model";
import { ClientPrivilegesHttpService } from "../../../shared/http-services/client-privileges/client-privileges-http.service";

@Component({
    selector: "app-dashboard-container",
    templateUrl: "./dashboard-container.component.html",
})
export class DashboardContainerComponent {

    public degreeOptions$: Observable<LocalizedMessage[]>;
    public genderOptions$: Observable<LocalizedMessage[]>;
    public educationTypeOptions$: Observable<LocalizedMessage[]>;
    public facultyOptions$: Observable<LocalizedMessage[]>;
    public privilegeOptions$: Observable<LocalizedMessage[]>;
    public specialityOptions$: Observable<LocalizedMessage[]>;

    public profile: ExtendedProfile;

    constructor(
        private clientPrivilegesHttpService: ClientPrivilegesHttpService,
        private toastrService: ToastrService,
        private profileHttpService: ProfileHttpService,
        private roomRequestHttpService: RoomRequestHttpService,
        private optionsHttpService: OptionsHttpService,
    ) {
        this.loadProfile();
        this.initOptions();
    }

    public addNewPrivilege(config: AddPrivilegeConfig)
    : void {
        this.clientPrivilegesHttpService.add(config.privilege)
            .subscribe(
                () => {
                    this.toastrService.success("Пільгу успішно додано");
                    config.callback();
                    this.loadProfile();
                },
                (error: WrappedError) => this.toastrService.error(error.message),
            );
    }

    public deletePrivilege(id: number)
    : void {
        this.clientPrivilegesHttpService.delete(id)
            .subscribe(
                () => {
                    this.toastrService.success("Пільгу успішно видалено");
                    this.loadProfile();
                },
                (err: WrappedError) => this.toastrService.error(err.message),
            );
    }

    private initOptions()
    : void {
        this.degreeOptions$ = this.optionsHttpService.getDegreeOptions();
        this.genderOptions$ = this.optionsHttpService.getGenderOptions();
        this.educationTypeOptions$ = this.optionsHttpService.getEducationTypeOptions();
        this.facultyOptions$ = this.optionsHttpService.getFacultyOptions();
        this.privilegeOptions$ = this.optionsHttpService.getPrivilegeOptions();
        this.specialityOptions$ = this.optionsHttpService.getSpecialityOptions();
    }

    public loadProfile()
    : void {
        this.profileHttpService
            .getProfile()
            .subscribe(
                profile => this.profile = profile,
                (err: WrappedError) => this.toastrService.error(err.message),
            );
    }
}
