import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Payment } from "../../../shared/http-services/payments/models/payment.model";
import { DateHelperService } from "../../../shared/services/helpers/date-helper.service";

@Injectable()
export class UserSettlementPaymentEditorFormService {

    constructor(
        private formBuilder: FormBuilder,
    ) {}

    public buildGroup(payment?: Payment)
    : FormGroup {
        return this.formBuilder.group({
            from: [payment && payment.from, Validators.required],
            to: [payment && payment.to, Validators.required],
            appliedPrivilege: [payment && payment.appliedPrivilege],
            price: [payment && payment.price, Validators.required],
        });
    }

    public mapToModel(form: FormGroup, payment: Payment)
    : Payment {
        const {from, to, appliedPrivilege, price} = form.value;

        return {
            ...(payment || {} as Payment),
            from: DateHelperService.formatDateIso(moment(from).toDate()),
            to: DateHelperService.formatDateIso(moment(to).toDate()),
            appliedPrivilege, price
        };
    }
}
