import { Injectable } from "@angular/core";

import { IFactory } from "../../../../../core/models/factory.interface";
import { Room } from "../../../../../shared/models/rooms.model";

@Injectable()
export class DefaultRoomFactoryService implements IFactory<Room> {

    public create()
    : Room {
        return <Room>{
            status: null,
            dormitory: "",
            floor: null,
            number: "",
            maxResidents: null,
            users: []
        };
    }
}
