import { Component, OnDestroy, OnInit } from "@angular/core";
import { Option } from "../../../../core/models/option.model";
import { PageEvent } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";

import { GetRoomsRequest } from "../../../../shared/http-services/models/get-rooms-request.model";
import { BuildingHttpService } from "../../../../shared/http-services/buildings/building-http.service";
import { RoomHttpService } from "../../../../shared/http-services/rooms/room-http.service";
import { Room } from "../../../../shared/models/rooms.model";
import { RoomEditorApi } from "../room-editor/models/room-editor-api.model";
import { RoomEditorConfig } from "../room-editor/models/room-editor-config.model";
import { RoomControlsService } from "../room-controls/services/room-controls.service";
import { RoomControlsFormService } from "../room-controls/services/room-controls-form.service";
import { RoomControlsApi } from "../room-controls/models/room-controls-api.model";
import { GetDormitoriesRequest } from "../../../../shared/http-services/buildings/models/get-dormitories-request.model";
import { LocalizedMessage } from "../../../../shared/models/privilege-status.model";
import { Observable } from "rxjs";
import { PrivilegesHttpService } from "../../../../shared/http-services/privileges/privileges-http.service";
import { EditRoom } from "../../../../shared/models/edit-room.model";

@Component({
    selector: "app-room-tab",
    templateUrl: "./room-tab.component.html",
    styleUrls: ["./room-tab.component.scss"],
    providers: [
        RoomControlsService,
        RoomControlsFormService,
    ],
})
export class RoomTabComponent implements OnInit, OnDestroy {

    public currentPage: number;
    public totalRecords: number;

    public readonly genderOptions: Option<boolean>[];
    public readonly maxResidentsOptions: Option<number>[];
    public readonly pageSizeOptions: number[];
    public readonly sortByOptions: Option[];

    public dormitories: string[];
    public pageSize: number;
    public privilegeOptions$: Observable<LocalizedMessage[]>;
    public isLoaded: boolean;
    public showActive: boolean;
    public displayedRooms: Room[];

    private rooms: Room[];
    private roomControlsApi: RoomControlsApi;
    private roomEditorApi: RoomEditorApi;

    constructor(
        private buildingHttpService: BuildingHttpService,
        private privilegesHttpService: PrivilegesHttpService,
        private roomHttpService: RoomHttpService,
        private roomTabService: RoomControlsService,
        private toastr: ToastrService
    ) {
        this.resetPagination();
        this.dormitories = [];
        this.pageSizeOptions = [5, 10, 20, 50];

        this.genderOptions = this.roomTabService.genderOptions;
        this.maxResidentsOptions = this.roomTabService.maxResidentsOptions;
        this.sortByOptions = this.roomTabService.sortByOptions;

        this.privilegeOptions$ = this.privilegesHttpService.getOptions();
    }

    public ngOnInit()
    : void {
        this.loadDormitories();
    }

    public ngOnDestroy()
    : void { }

    private get roomsRequest()
    : GetRoomsRequest {
        return new GetRoomsRequest({
            page: this.currentPage.toString(),
            size: this.pageSize.toString(),
            ...this.roomControlsApi.getFilter(),
        });
    }

    public addRoom(value: EditRoom)
    : void {
        this.roomHttpService.postRoom(value)
            .subscribe(
                () => {
                    this.loadRooms();
                    this.toastr.success("Додана кімната");
                },
                error => this.toastr.error(error.message)
            );
    }

    public changePage(pageEvent: PageEvent)
    : void {
        this.pageSize = pageEvent.pageSize;
        this.currentPage = pageEvent.pageIndex;

        this.filterDisplayedRooms();
    }

    public deleteRoom(room: Room)
    : void {
        const confirmation = confirm(`Ви справді хочете видалити кімнату ${room.number} гуртожитку ${room.dormitory}?`);

        if (confirmation) {

            this.roomHttpService.deleteRoom(room.id)
                .subscribe(() => {

                    this.loadRooms();
                    this.toastr.success("Видалена кімната");
                }, error => this.toastr.error(error.message));
        }
    }

    public onControlsReady(api: RoomControlsApi)
    : void {
        this.roomControlsApi = api;
        this.loadRooms();
    }

    public onRoomEditorReady(api: RoomEditorApi)
    : void {
        this.roomEditorApi = api;
    }

    public openEditRoom(room: Room)
    : void {
        const config = new RoomEditorConfig({
            edit: true,
            initialValue: room,
            onSave: this.putRoom.bind(this),
        });

        this.roomEditorApi.open(config);
    }

    public putRoom(value: EditRoom, id: number)
    : void {
        this.roomHttpService.putRoom(value, id)
            .subscribe(
                () => {
                    this.loadRooms();
                    this.toastr.success("Змінена кімната");
                },
                error => this.toastr.error(error.message)
            );
    }
    public openAddRoomModal()
    : void {
        const config = new RoomEditorConfig({
            edit: false,
            onSave: this.addRoom.bind(this),
        });

        this.roomEditorApi.open(config);
    }

    private loadDormitories()
    : void {
        const request = new GetDormitoriesRequest({
            page: "0",
            size: "1000",
        });

        this.buildingHttpService.getDormitories(request)
            .subscribe(
                res => this.dormitories = res.content.map(value => value.name),
                error => this.toastr.error(error.message)
            );
    }

    public loadRooms()
    : void {
        this.isLoaded = false;
        const config = this.roomsRequest;

        this.roomHttpService.getRooms(config)
            .pipe(
                finalize(() => this.isLoaded = true),
            )
            .subscribe(
                res => this.updateRooms(res),
                error => this.toastr.error(error.message)
            );
    }

    private updateRooms(res: any)
    : void {
        this.rooms = res;
        this.resetPagination();
        this.filterDisplayedRooms();
    }

    private filterDisplayedRooms()
    : void {
        this.displayedRooms = this.rooms.filter((room, index) =>
            index > this.currentPage * this.pageSize - 1 && index < (this.currentPage + 1) * this.pageSize);
    }

    private resetPagination()
    : void {
        this.currentPage = 0;
        this.pageSize = 20;
        this.totalRecords = this.rooms && this.rooms.length;
    }

    public changeActive($event: boolean)
    : void {
        this.showActive = $event;
    }
}
