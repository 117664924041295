import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";

import { LogsRequestFilter } from "../../../shared/http-services/logs/models/logs-request-filter.model";
import { LogsRequestModel } from "../../../shared/http-services/logs/models/logs-request.model";
import { LogsTableService } from "../services/logs-table.service";
import { Log } from "../models/log.model";

@Component({
    selector: "app-logs-view",
    styleUrls: [ "./logs-view.component.scss", ],
    templateUrl: "./logs-view.component.html",
    providers: [ LogsTableService ]
})
export class LogsViewComponent {

    @ViewChild(MatPaginator)
    public paginator: MatPaginator;

    @Input()
    public data: Log[];
    @Input()
    public totalRecords: number;

    @Output()
    public updateData: EventEmitter<LogsRequestModel>;

    public currentPage: number;
    public gridOptions: GridOptions;
    public pageSize: number;
    public pageSizeOptions: number[];
    public sort: string;
    public filter: LogsRequestFilter;

    private gridApi: GridApi;

    constructor(
        private logsTableService: LogsTableService,
    ) {
        this.updateData = new EventEmitter<LogsRequestModel>();

        this.initFilter();
        this.initDefaultValues();
        this.initTableOptions();
    }

    private get formattedFilterDateFields()
    : Partial<LogsRequestFilter> {
        let {loggedBefore, loggedAfter} = this.filter;

        loggedBefore = loggedBefore ? loggedBefore : null;
        loggedAfter = loggedAfter ? loggedAfter : null;

        return {loggedBefore, loggedAfter};
    }

    private get logsRequestModel()
    : LogsRequestModel {
        return new LogsRequestModel({
            filters: {
                ...this.filter,
                ...this.formattedFilterDateFields,
            },
            page: this.currentPage.toString(),
            size: this.pageSize.toString(),
            sort: this.sort,
        });
    }

    public changePage(pageEvent: PageEvent)
    : void {
        this.pageSize = pageEvent.pageSize;
        this.currentPage = pageEvent.pageIndex;

        this.onUpdateData();
    }

    public onFilterChange()
    : void {
        this.resetPagination();
        this.onUpdateData();
    }

    public onGridReady(event: GridReadyEvent)
    : void {
        this.gridApi = event.api;

        this.onUpdateData();
    }

    public onSortChange()
    : void {
        const sortModel = this.gridApi.getSortModel();

        if (!sortModel || !sortModel.length) {
            this.sort = null;
        } else {
            this.sort = `${sortModel[0].colId},${sortModel[0].sort}`;
        }

        this.onUpdateData();
    }

    private initDefaultValues()
    : void {
        this.currentPage = 0;
        this.pageSize = 20;
        this.pageSizeOptions = [5, 10, 20, 50];
    }

    private initFilter()
    : void {
        this.filter = new LogsRequestFilter();
    }

    private initTableOptions()
    : void {
        this.gridOptions = this.logsTableService.getGridOptions();
    }

    private onUpdateData()
    : void {
        this.gridApi.showLoadingOverlay();
        this.updateData.emit(this.logsRequestModel);
    }

    private resetPagination()
    : void {
        this.paginator.firstPage();
    }
}
