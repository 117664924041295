import { NgModule } from "@angular/core";

import { LogsFilterComponent } from "./components/filter/logs-filter.component";
import { LogsViewComponent } from "./components/logs-view.component";
import { LogsComponent } from "./containers/logs.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        LogsComponent,
        LogsFilterComponent,
        LogsViewComponent,
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        LogsComponent,
    ],
})
export class LogsModule { }
