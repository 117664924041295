import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AbstractFormBasedComponent } from "../../../core/components/abstract-form-based.component";
import { ExtendedProfile } from "../../../shared/http-services/profile/models/extended-profile.model";
import { ProfileInfo } from "../../../shared/http-services/profile/models/profile-info.model";
import { ProfileModalFormService } from "../services/profile-modal-form.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";

@Component({
    selector: "app-profile-modal",
    templateUrl: "./profile-modal.component.html",
    styleUrls: ["./profile-modal.component.scss"],
    providers: [
        ProfileModalFormService,
    ],
})
export class ProfileModalComponent extends AbstractFormBasedComponent implements OnInit {

    @Input()
    public degreeOptions: LocalizedMessage[];
    @Input()
    public educationTypeOptions: LocalizedMessage[];
    @Input()
    public facultyOptions: LocalizedMessage[];
    @Input()
    public genderOptions: LocalizedMessage[];
    @Input()
    public isLoadingSpecialities: boolean;
    @Input()
    public profile: ExtendedProfile;
    @Input()
    public specialityOptions: LocalizedMessage[];

    @Output()
    public save: EventEmitter<ProfileInfo>;
    @Output()
    public facultyChange: EventEmitter<string>;

    public form: FormGroup;
    public todayDate: string;

    constructor(
        private profileModalFormService: ProfileModalFormService,
    ) {
        super();

        this.save = new EventEmitter<ProfileInfo>();
        this.facultyChange = new EventEmitter<string>();
        this.todayDate = new Date().toISOString();
    }

    public ngOnInit()
    : void {
        this.buildForm();

        if (this.profile) {
            this.facultyChange.emit(this.profile.faculty.codeName);
        }
    }

    public onSave()
    : void {
        if (!this.checkFormOnValidity()) {
            return;
        }

        const request = this.profileModalFormService.mapToModel(this.form);
        this.save.emit(request);
    }

    public onFacultyChange(faculty: string | null)
    : void {
        this.resetSpeciality();
        this.facultyChange.emit(faculty);
    }

    private buildForm()
    : void {
        this.form = this.profileModalFormService.buildForm(this.profile);
    }

    private checkFormOnValidity()
    : boolean {
        this.markAllFormControlsTouched(this.form);

        return this.form.valid;
    }

    private resetSpeciality()
    : void {
        const control = this.form.controls.speciality;
        control.reset(null);
    }
}
