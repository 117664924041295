import { LocalizedMessage } from "../../models/privilege-status.model";
import { Room } from "../../models/rooms.model";
import { User } from "../../models/user.model";
import { Payment } from "../payments/models/payment.model";

export class RoomRequest {

    public extendable: boolean;
    public cancellable: boolean;
    public evictable: boolean;
    public revivable: boolean;
    public id: number;
    public room: Room;
    public status: LocalizedMessage;
    public validSince: string;
    public validTo: string;
    public payments?: Payment[];
    public user?: User;
    public comment?: string;
    public created?: string;
    public paymentRequestable?: boolean;
    public lastPaymentEndDate?: string;

    constructor(init?: Partial<RoomRequest>) {
        Object.assign(this, init);
    }
}
