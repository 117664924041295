import { Routes } from "@angular/router";

import { DashboardContainerComponent } from "./containers/dashboard-container.component";
import { AuthenticationGuard } from "../../core/auth/guards/authentication.guard";
import { ProfileFormGuard } from "../../core/profile/guards/profile-form.guard";
import { RoleGuard } from "../../core/roles/guards/role.guard";
import { RolePasses } from "../../core/roles/rules/roles-passes.class";

export const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardContainerComponent,
        canActivate: [ AuthenticationGuard, ProfileFormGuard, RoleGuard ],
        data: {
            isInRole: RolePasses.studentPass,
        }
    },
];
