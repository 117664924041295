import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

import * as appConstants from "../constants/app-constants";

@Pipe({
    name: "userDateFullMonth"
})
export class UserDateFullMonthPipe implements PipeTransform {

    public transform(value: string)
    : string | null {
        return moment(value).locale("uk-ua").format(appConstants.userDateMMMM);
    }
}
