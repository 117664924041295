import { FormBuilder, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class RoomControlsFormService {

    constructor(
        private formBuilder: FormBuilder,
    ) { }

    public buildForm()
    : FormGroup {
        return this.formBuilder.group({
                filterDate: new Date().toISOString().split("T")[0],
                dormitory: null,
                maxResidents: null,
                state: null,
                status: null,
                sortBy: null
        });
    }
}
