import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";

import { FacultiesDegreeReport } from "../../../../shared/http-services/reports/models/faculties-degrees-report.model";
import { ReportsHttpService } from "../../../../shared/http-services/reports/reports-http.service";
import { DateHelperService } from "../../../../shared/services/helpers/date-helper.service";
import { DegreesReportTableService } from "./services/degrees-report-table.service";
import { FacultiesDegreeReports } from "../../../../shared/http-services/reports/models/faculties-degrees-reports.model";
import { ColumnMeta } from "../../../../shared/http-services/reports/models/column-meta.model";

@Component({
    selector: "app-degrees-report",
    templateUrl: "./degrees-report.component.html",
    styleUrls: [ "./degrees-report.component.scss" ],
    providers: [
        DegreesReportTableService
    ],
})
export class DegreesReportComponent implements OnChanges {

    @Input()
    public date: string | Date;

    public loading: boolean;
    public reportsData: FacultiesDegreeReports;

    constructor(
        private degreesReportTableService: DegreesReportTableService,
        private reportsHttpService: ReportsHttpService,
        private toastrService: ToastrService,
    ) {}

    public ngOnChanges(changes: SimpleChanges)
    : void {
        if (changes.date && this.date) {
            this.loadData();
        }
    }

    public get columnsMeta()
    : ColumnMeta[] {
        return this.reportsData.columns;
    }

    public get reports()
    : FacultiesDegreeReport[] {
        return this.reportsData.dormitories;
    }

    private loadData()
    : void {
        this.loading = true;

        this.reportsHttpService.getFacultiesAndDegrees(DateHelperService.formatDateIso(this.date))
            .pipe(
                finalize(() => this.loading = false),
            )
            .subscribe(
                res => this.onDataLoaded(res),
                err => this.toastrService.error(err.message),
            );
    }

    private onDataLoaded(data: FacultiesDegreeReports)
    : void {
        this.reportsData = data;
    }
}
