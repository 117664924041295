import { Privilege } from "../../../../../shared/http-services/privileges/models/privilege.model";
import { LocalizedMessage } from "../../../../../shared/models/privilege-status.model";

export class PrivilegesEditorConfig {

    public edit: boolean;
    public initialValue: Privilege;
    public nameOptions: LocalizedMessage[];
    public onSave: (value: Privilege) => void;

    constructor(init?: Partial<PrivilegesEditorConfig>) {
        Object.assign(this, init);
    }
}
