import { FormBuilder, FormGroup } from "@angular/forms";
import { Injectable } from "@angular/core";

import { GetFreeRoomsRequestFilter } from "../../../../../shared/http-services/rooms/models/get-free-rooms-request-filter.model";

@Injectable()
export class FreeRoomRequestFormService {


    constructor(
        private formBuilder: FormBuilder
    ) {}

    public buildForm()
    : FormGroup {
        return this.formBuilder.group({
            dormitory: null,
            number: null,
        });
    }

    public mapToModel(form: FormGroup)
    : GetFreeRoomsRequestFilter {
        const { dormitory, number } = form.value;

        const model = new GetFreeRoomsRequestFilter();

        if (dormitory && dormitory !== "null") {
            model.dormitory = dormitory;
        }

        if (number && number !== "null") {
            model.number = number;
        }

        return model;
    }
}
