import { NavigationEnd, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { environment } from "../../../environments/environment";

@Injectable()
export class AnalyticsService {

    constructor(
        private router: Router,
    ) {
        this.initGA();
    }

    public initRoutesTrackingGA()
    : void {
        this.router.events
            .pipe(
                filter((event) => !!(event instanceof NavigationEnd)),
            )
            .subscribe((event: any) => {
                    (<any>window).ga("set", "page", event.urlAfterRedirects);
                    (<any>window).ga("send", "pageview");
                }
            );
    }

    private initGA()
    : void {
        this.loadAnalytics(
            window,
            document,
            "script",
            "https://www.google-analytics.com/analytics.js",
            "ga",
            null,
            null
        );

        (<any>window).ga("create", environment.googleAnalyticsKey, "auto");
        (<any>window).ga("send", "pageview");
    }

    private loadAnalytics(i, s, o, g, r, a, m)
    : void {
        i["GoogleAnalyticsObject"] = r;
        i[r] = i[r] || (function (): void {
            (i[r].q = i[r].q || []).push(arguments);
        }), i[r].l = 1 * (new Date() as any);
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
}
