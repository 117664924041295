import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgxSmartModalComponent } from "ngx-smart-modal";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";

import { RoomRequestHttpService } from "../../../../../shared/http-services/room-request/room-request-http.service";
import { WrappedError } from "../../../../../shared/http-services/models/wrapped-error.model";
import { RoomRequestModalApi } from "../models/room-request-modal-api.model";

@Component({
    selector: "app-room-request-modal",
    templateUrl: "./room-request-modal.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class RoomRequestModalComponent implements OnInit {

    @ViewChild("roomRequestModal")
    public modalComponent: NgxSmartModalComponent;

    @Output()
    public modalReady: EventEmitter<RoomRequestModalApi>;
    @Output()
    public requestAdded: EventEmitter<void>;

    constructor(
        private roomRequestHttpService: RoomRequestHttpService,
        private toastrService: ToastrService,
    ) {
        this.modalReady = new EventEmitter<RoomRequestModalApi>();
        this.requestAdded = new EventEmitter<void>();
    }

    public ngOnInit()
    : void {
        this.onModalReady();
    }

    public onSave()
    : void {
        const savingToastr = this.toastrService.info("Збереження...");

        this.roomRequestHttpService.create()
            .pipe(
                finalize(
                    () => {
                        savingToastr.toastRef.close();
                        this.modalComponent.close();
                    }),
            ).subscribe(
            () => {
                this.toastrService.success("Запит на поселення успішно створений");
                this.requestAdded.emit();
            },
            (err: WrappedError) => this.toastrService.error(err.message),
        );
    }

    private onModalReady()
    : void {
        const api = new RoomRequestModalApi({
            open: this.modalComponent.open.bind(this.modalComponent),
        });

        this.modalReady.emit(api);
    }
}
