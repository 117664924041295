import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserInfoCardEditorModule } from "../user-info-card-editor/user-info-card-editor.module";
import { UserInfoCardComponent } from "./user-info-card.component";

@NgModule({
    imports: [
        SharedModule,
        UserInfoCardEditorModule,
    ],
    declarations: [
        UserInfoCardComponent,
    ],
    exports: [
        UserInfoCardComponent,
    ]
})
export class UserInfoCardModule {}
