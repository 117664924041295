import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSmartModalComponent } from "ngx-smart-modal";

import { UsersHttpService } from "../../../../shared/http-services/users/users-http.service";
import { PeriodsHttpService } from "../../../../shared/http-services/periods/periods-http.service";
import { Period } from "../../../../shared/http-services/periods/models/period.model";
import { ModalApi } from "../../../../shared/models/modal-api.interface";

@Component({
    selector: "app-open-settlement-for-user",
    templateUrl: "open-settlement-for-user.component.html",
    styleUrls: ["open-settlement-for-user.component.scss"],
})
export class OpenSettlementForUserComponent implements OnInit {

    @ViewChild(NgxSmartModalComponent)
    public modalComponent: NgxSmartModalComponent;

    @Input()
    public userEmail: string;

    @Output()
    public ready: EventEmitter<ModalApi>;
    @Output()
    public saved: EventEmitter<void>;

    public periodsOptions: Period[];
    public selectedPeriod: Period;

    constructor(
        private usersHttpService: UsersHttpService,
        private periodsHttpService: PeriodsHttpService,
        private toastrService: ToastrService,
    ) {
        this.ready = new EventEmitter<ModalApi>();
        this.saved = new EventEmitter<void>();

        this.setDefaultValues();
    }

    public ngOnInit()
    : void {
        this.loadOptions();

        this.ready.emit({
            open: this.open.bind(this),
        });
    }

    public onSave()
    : void {
        if (!this.selectedPeriod || !confirm("Відкрити користувачу поселення?")) {
            return ;
        }

        this.usersHttpService.allowSettlementForPeriod(this.userEmail, this.selectedPeriod)
            .subscribe(
                () => {
                    this.toastrService.success(`Поселення успішно відкрито користувачу ${this.userEmail}`);
                    this.modalComponent.close();

                    this.setDefaultValues();
                    this.saved.emit();
                },
                error => this.toastrService.error(error.message)
            );
    }

    public open()
    : void {
        this.modalComponent.open();
    }

    private loadOptions()
    : void {
        this.periodsHttpService.getCurrentAndFuture()
            .subscribe(
                res => this.periodsOptions = res,
                err => this.toastrService.error(err.message),
                );
    }

    private setDefaultValues()
    : void {
        this.selectedPeriod = null;
    }
}
