import { NgModule } from "@angular/core";

import { MigrationRequestCardComponent } from "./components/migration-request-card.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        MigrationRequestCardComponent,
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        MigrationRequestCardComponent,
    ]
})
export class MigrationRequestCardModule {
}
