import { Injectable } from "@angular/core";
import { ColDef, GridOptions } from "ag-grid-community";

import { AgGridHelperService } from "../../../shared/services/helpers/ag-grid/ag-grid-helper.service";
import { AgGridOptionsService } from "../../../shared/services/tables/ag-grid-options.service";
import { PropertiesComponent } from "../components/properties.component";

@Injectable()
export class PropertiesTableService {

    constructor(
        private agGridHelperService: AgGridHelperService,
        private agGridOptionsService: AgGridOptionsService,
    ) { }

    public getColumnDefs()
    : ColDef[] {
        return <ColDef[]>[
            {
                field: "name",
                headerName: "Робоча назва",
                suppressMenu: true,
            },
            {
                field: "description",
                headerName: "Опис",
                suppressMenu: true,
            },
            {
                field: "value",
                headerName: "Значення",
                suppressMenu: true,
                editable: true,
            },
        ];
    }

    public getGridOptions(componentParent: PropertiesComponent)
    : GridOptions {
        return this.agGridOptionsService.getGridOptions({
            onGridSizeChanged: this.agGridHelperService.sizeColumnsToFit,
            context: {
                componentParent,
            },
            rowSelection: "none",
            enableSorting: false,
        });
    }
}
