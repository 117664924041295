import { UserRole } from "../../../shared/enums/user-role.enum";

export class RolePasses {

    public static readonly studentPass = [ UserRole.Student ];
    public static readonly canEditRoomsPass = [ UserRole.Admin, UserRole.HousingDepartmentMember ];
    public static readonly canEditBuildingsPass = [
        UserRole.Admin,
        UserRole.HousingDepartmentMember,
    ];
    public static readonly extendedAdminPass = [ UserRole.Admin ];
    public static readonly seeMigrationsPass = [
        UserRole.DormitoryBoardMember,
        UserRole.DormitoryManager,
        UserRole.Admin,
        UserRole.HousingDepartmentMember,
        UserRole.Dean,
        UserRole.StudentBoardMember,
    ];
    public static readonly approveMigrationRequestsPass = [
        UserRole.DormitoryBoardMember,
        UserRole.DormitoryManager,
    ];
    public static readonly standardAdministrationPass = [
        UserRole.Admin,
        UserRole.Dean,
        UserRole.DormitoryBoardMember,
        UserRole.DormitoryManager,
        UserRole.Employee,
        UserRole.HousingDepartmentMember,
        UserRole.StudentBoardMember,
    ];
    public static readonly canViewProperties = [
        UserRole.Admin,
        UserRole.Dean,
        UserRole.DormitoryManager,
        UserRole.Employee,
    ];
    public static readonly canViewReports = [
        UserRole.HousingDepartmentMember,
        UserRole.DormitoryBoardMember,
        UserRole.DormitoryManager,
        UserRole.Dean,
        UserRole.Admin,
    ];
    public static readonly canViewLogs = [
        UserRole.Admin,
        UserRole.HousingDepartmentMember,
        UserRole.StudentBoardMember,
        UserRole.Dean,
    ];
}
