import { NgModule } from "@angular/core";

import { PeriodsComponent } from "./containers/periods.component";
import { PeriodsViewComponent } from "./components/periods-view.component";
import { PeriodComponent } from "./components/period/period.component";
import { SharedModule } from "../../shared/shared.module";
import { PageLoaderModule } from "../../core/components/page-loader/page-loader.module";
import { PeriodEditorComponent } from "./components/period-editor/period-editor.component";

@NgModule({
    imports: [
        SharedModule,
        PageLoaderModule,
    ],
    declarations: [
        PeriodComponent,
        PeriodEditorComponent,
        PeriodsComponent,
        PeriodsViewComponent,
    ],
})
export class PeriodsModule {
}
