import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { GetPropertiesRequest } from "./models/get-properties-request.model";
import { PropertyPutRequest } from "./models/property-put-request.model";
import { AbstractHttpService } from "../abstract-http.service";
import { Property } from "./models/property.model";
import { UpdateModeRequest } from "./models/update-mode-request.model";

@Injectable({
    providedIn: "root"
})
export class PropertyHttpService extends AbstractHttpService {

    private readonly path: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        super();

        this.path = `${ this.apiUrl }/properties`;
    }

    public get(request: GetPropertiesRequest)
    : Observable<Property[]> {
        const params = this.buildFlatHttpParams(request);

        return this.httpClient.get(`${this.path}`, { params })
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public create(property: Property)
    : Observable<any> {
        return this.httpClient.post(`${this.path}/`, property)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public update(name: string, value: PropertyPutRequest)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/${name}`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public delete(key)
    : Observable<any> {
        return this.httpClient.delete(`${this.path}/${key}`)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }

    public updateMode(value: UpdateModeRequest)
    : Observable<any> {
        return this.httpClient.put(`${this.path}/mode`, value)
            .pipe(
                catchError<any, never>(this.handleError.bind(this))
            );
    }
}
