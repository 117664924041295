import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "orderType"
})
export class OrderTypePipe implements PipeTransform {

    public transform(value: any)
    : string {
        return value.localizedName;
    }

}
