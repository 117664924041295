import { Component, Input } from "@angular/core";

@Component({
    selector: "app-refresh-button",
    templateUrl: "./refresh-button.component.html",
    styleUrls: [ "./refresh-button.component.scss" ],
})
export class RefreshButtonComponent {

    @Input()
    public loading: boolean;
}

