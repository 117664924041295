import { NamedValue } from "../../../../../shared/models/named-value.model";

export class LogsFilterConstants {

    public static readonly timeOptions: NamedValue<number>[] = [
        {
            name: "00:00",
            value: 0,
        },
        {
            name: "01:00",
            value: 1,
        },
        {
            name: "02:00",
            value: 2,
        },
        {
            name: "03:00",
            value: 3,
        },
        {
            name: "04:00",
            value: 4,
        },
        {
            name: "05:00",
            value: 5,
        },
        {
            name: "06:00",
            value: 6,
        },
        {
            name: "07:00",
            value: 7,
        },
        {
            name: "08:00",
            value: 8,
        },
        {
            name: "09:00",
            value: 9,
        },
        {
            name: "10:00",
            value: 10,
        },
        {
            name: "11:00",
            value: 11,
        },
        {
            name: "12:00",
            value: 12,
        },
        {
            name: "13:00",
            value: 13,
        },
        {
            name: "14:00",
            value: 14,
        },
        {
            name: "15:00",
            value: 15,
        },
        {
            name: "16:00",
            value: 16,
        },
        {
            name: "17:00",
            value: 17,
        },
        {
            name: "18:00",
            value: 18,
        },
        {
            name: "19:00",
            value: 19,
        },
        {
            name: "20:00",
            value: 20,
        },
        {
            name: "21:00",
            value: 21,
        },
        {
            name: "22:00",
            value: 22,
        },
        {
            name: "23:00",
            value: 23,
        },
    ];
}
