import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: "app-switcher",
    templateUrl: "./switcher.component.html",
    styleUrls: ["./switcher.component.scss"]
})
export class SwitcherComponent implements OnInit {
    isActive: boolean = false;

    @Input()
    public isVertical: boolean;

    @Output()
    public activeEvent = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    public onFilterChange()
        : void {
        this.isActive = !this.isActive;
        this.activeEvent.emit(this.isActive);
    }

    public ifIsVertical()
        : string {
        return this.isVertical ? "" : "float-left";
    }
}
