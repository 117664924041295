import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class SwitchRoomRequestService {

    constructor(
        private formBuilder: FormBuilder,
    ) {}

    public buildForm()
    : FormGroup {
        return this.formBuilder.group({
            email: [null, [ Validators.email, Validators.required ]],
        });
    }
}
