import { Component, } from "@angular/core";

@Component({
    selector: "app-notfound",
    templateUrl: "./not-found.component.html",
    styleUrls: [ "./not-found.component.scss" ],
})
export class NotFoundComponent {

}
