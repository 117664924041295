import { Building } from "../../../../../shared/models/building.model";
import { BuildingsEditorStateEnum } from "./buildings-editor-state.enum";

export class BuildingsEditorConfig {

    public state: BuildingsEditorStateEnum;
    public onSave: (value: Building) => void;
    public initialValue: Building;

    constructor(init?: Partial<BuildingsEditorConfig>) {
        Object.assign(this, init);
    }
}
