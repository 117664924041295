import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PaymentsHttpService } from "../../../shared/http-services/payments/payments-http.service";

@Injectable()
export class UserSettlementPaymentCardService {

    constructor(
        private paymentsHttpService: PaymentsHttpService,
        private toastrService: ToastrService,
    ) {
    }

    public async cancelPayment(paymentId: number)
    : Promise<void> {
        try {
            await this.paymentsHttpService.cancel(paymentId).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Платіж успішно скасовано");
    }

    public async markAsPaid(paymentId: number)
    : Promise<void> {
        try {
            await this.paymentsHttpService.markAsPaid(paymentId).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Платіж відмічено як оплачений");
    }

    public async markAsNotPaid(paymentId: number)
    : Promise<void> {
        try {
            await this.paymentsHttpService.markAsCreated(paymentId).toPromise();
        } catch (error) {
            this.toastrService.error(error.message);
            throw Error(error.message);
        }

        this.toastrService.success("Платіж відмічено як не оплачений");
    }
}
