import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { MigrationsComponent } from "./migrations.component";
import { MigrationApprovalModule } from "../../features/migration-approval/migration-approval.module";
import { MigrationsListModule } from "../../features/migrations-list/migrations-list.module";

@NgModule({
    imports: [
        SharedModule,
        MigrationApprovalModule,
        MigrationsListModule,
    ],
    declarations: [
        MigrationsComponent,
    ],
    exports: [
        MigrationsComponent,
    ],
})
export class MigrationsModule {}
