import { ToastrService } from "ngx-toastr";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { finalize } from "rxjs/operators";
import * as _ from "lodash";
import { Period } from "../../../shared/http-services/periods/models/period.model";
import { PeriodsHttpService } from "../../../shared/http-services/periods/periods-http.service";
import { RoomRequestHttpService } from "../../../shared/http-services/room-request/room-request-http.service";
import { PropertiesHttpService } from "../../../shared/http-services/properties/properties-http.service";
import { WrappedError } from "../../../shared/http-services/models/wrapped-error.model";
import { RoomRequest } from "../../../shared/http-services/models/room-request.model";
import { OptionsHttpService } from "../../../shared/http-services/options/options-http.service";
import { LocalizedMessage } from "../../../shared/models/privilege-status.model";
import { Profile } from "../../../shared/http-services/profile/models/profile.model";
import { ActionsHttpService } from "../../../shared/http-services/actions/actions-http.service";
import { MigrationRequestHttpService } from "../../../shared/http-services/migration-request/migration-request-http.service";
import { DateHelperService } from "../../../shared/services/helpers/date-helper.service";
import { MigrationRequestCase } from "../../migration-approval/models/migration-request-case.model";

@Component({
    selector: "app-room-request-board-container",
    templateUrl: "./room-request-board-container.component.html",
})
export class RoomRequestBoardContainerComponent {

    @Input()
    public profile: Profile;
    @Input()
    public roomRequests: RoomRequest[];
    @Output()
    public reloadProfile: EventEmitter<void>;

    public changingMigrationStatus: boolean;
    public canCreateRoomRequest = false;
    public canCreateMigrationRequest = false;
    public roomRequestStatuses: LocalizedMessage[];
    public settlementExtensionPeriod: Period;

    constructor(
        private optionsHttpService: OptionsHttpService,
        private migrationRequestHttpService: MigrationRequestHttpService,
        private propertiesHttpService: PropertiesHttpService,
        private roomRequestHttpService: RoomRequestHttpService,
        private actionsHttpService: ActionsHttpService,
        private periodsHttpService: PeriodsHttpService,
        private toastrService: ToastrService,
    ) {
        this.reloadProfile = new EventEmitter<void>();

        this.onReloadProfile();
        this.loadRoomRequestStatuses();
        this.loadRoomRequestActions();

        this.setSettlementExtensionPeriod();
    }

    public onApproveMigrationRequest(request: MigrationRequestCase)
    : void {
        if (!confirm(
            `Ви впевнені що хочете прийняти пропозицію переселення користувача(-ки) ${request.initiator.username} ` +
            `в гуртожиток ${request.targetRoomRequest.room.dormitory}, в кімнату ${request.targetRoomRequest.room.number}?`)) {
            return;
        }

        this.changingMigrationStatus = true;

        this.migrationRequestHttpService.approveMigrationRequest(request.id)
            .pipe(
                finalize(() => this.changingMigrationStatus = false)
            )
            .subscribe(
                () => {
                    this.toastrService.success("Пропозиція підтверджена, очікуйте на підтвердження комендентів та голів гуртожитків");
                    this.onReloadProfile();
                },
                (error) => this.toastrService.error(error.message)
            );
    }

    public onCancelMigrationRequest(request: MigrationRequestCase)
    : void {
        if (!confirm(
            `Ви впевнені що хочете скасувати запит на переселення в гуртожиток ${request.targetRoomRequest.room.dormitory},
            в кімнату ${request.targetRoomRequest.room.number}?`
        )) {
            return;
        }

        this.changingMigrationStatus = true;

        this.rejectMigrationRequest(request);
    }

    public onRejectMigrationRequest(request: MigrationRequestCase)
    : void {
        if (!confirm(
            `Ви впевнені що хочете відхилити пропозицію переселення користувача(-ки) ${request.initiator.username} ` +
            `в гуртожиток ${request.targetRoomRequest.room.dormitory}, в кімнату ${request.targetRoomRequest.room.number}?`)) {
            return;
        }

        this.changingMigrationStatus = true;

        this.rejectMigrationRequest(request);
    }

    public onRequestsChanged()
    : void {
        this.onReloadProfile();
        this.loadRoomRequestActions();
    }

    private loadRoomRequestStatuses()
    : void {
        this.optionsHttpService.getRoomRequestStatuses()
            .subscribe(
                (statuses) => {
                    this.roomRequestStatuses = statuses;
                },
                (err: WrappedError) => this.toastrService.error(err.message)
            );
    }

    private loadRoomRequestActions()
    : void {
        this.actionsHttpService.getDashboardActions()
            .subscribe(
                (res) => {
                    this.canCreateRoomRequest = res.canCreateRoomRequest;
                    this.canCreateMigrationRequest = res.canCreateMigrationRequest || false;
                },
                (err: WrappedError) => this.toastrService.error(err.message)
            );
    }

    private rejectMigrationRequest(request: MigrationRequestCase)
    : void {
        this.migrationRequestHttpService.rejectMigrationRequest(request.id)
            .pipe(
                finalize(() => this.changingMigrationStatus = false),
            )
            .subscribe(
                () => {
                    this.toastrService.success("Запит на переселення скасований");
                    this.onRequestsChanged();
                },
                (error) => this.toastrService.error(error.message)
            );
    }

    public async extendRoomRequest($event: number)
    : Promise<void> {
        this.roomRequestHttpService.extend($event)
            .pipe(
                finalize(() => this.onReloadProfile())
            )
            .subscribe(
                () => {
                    this.toastrService.success(`Прийнято запит на продовження проживання на період
                    ${this.settlementExtensionPeriod.startDate} - ${this.settlementExtensionPeriod.endDate}`);
                },
                (error) => this.toastrService.error(error.message)
            );
    }

    private onReloadProfile()
    : void {
        this.reloadProfile.emit();
    }

    private async setSettlementExtensionPeriod()
    : Promise<void> {
        const periods = await this.periodsHttpService.getCurrentAndFuture().toPromise();

        this.settlementExtensionPeriod = periods.find(period => period.settlementProlongationEnabled);
        this.settlementExtensionPeriod = _.cloneDeep(this.settlementExtensionPeriod);

        if (!this.settlementExtensionPeriod) {
            return ;
        }

        this.settlementExtensionPeriod.startDate = DateHelperService.formatDate(this.settlementExtensionPeriod.startDate);
        this.settlementExtensionPeriod.endDate = DateHelperService.formatDate(this.settlementExtensionPeriod.endDate);
    }
}

