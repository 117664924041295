import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Order } from "../../../../shared/models/order.model";
import { CarTreatment } from "./models/car-treatment.model";

@Component({
    selector: "app-info-card",
    templateUrl: "./info-card.component.html",
    styleUrls: ["./info-card.component.scss"]
})
export class InfoCardComponent {

    @Input()
    public info: Order;

    @Output()
    public cardTreatment: EventEmitter<CarTreatment>;

    constructor() {
        this.cardTreatment = new EventEmitter<CarTreatment>();
    }

    public acceptOrder(id: number)
    : void {
        this.cardTreatment.emit({accept: true, id});
    }

    public denyOrder(id: number)
    : void {
        this.cardTreatment.emit({accept: false, id});
    }
}
