import { SettlementDocument } from "../models/settlement-document.enum";

export class SettlementDocumentsHelper {

    public static getGoogleDocumentId(document: SettlementDocument)
        : string {
        switch (document) {
            case SettlementDocument.State:
                return "1XQFkdd07LMIkyGjDThowDClRmlDH0pFW";
            case SettlementDocument.SettlementAgreement:
                return "1HjW9Lwe68gH3q3qlEUwEZ0tsPMs-EiHx";
            case SettlementDocument.SettlementOrder:
                return "1QZEJg38XQTsx3sqx0FkzR8HewSolyMLf";
            case SettlementDocument.SettlementApplication:
                return "1FmbQJ717k4B2XuHq_g5aBJE1eL4CTEJF";
            case SettlementDocument.SettlementApplicationPrivileges:
                return "1-99WMOPkjerBDwpjhTQR7qKhpIZpbNBmLAOMP5_LbI4";
        }
    }

    public static getDocumentDisplayUrl(document: SettlementDocument)
    : string {
        const documentId = SettlementDocumentsHelper.getGoogleDocumentId(document);
        return "https://drive.google.com/file/d/" + documentId + "/preview";
    }

    public static getDocumentDownloadUrl(document: SettlementDocument)
        : string {
        const documentId = SettlementDocumentsHelper.getGoogleDocumentId(document);
        const downloadPDF = "https://drive.google.com/uc?export=download&id=" + documentId;
        const downloadDOC = "https://docs.google.com/document/d/" + documentId + "/export?format=doc";
        switch (document) {
            case SettlementDocument.State:
                return downloadPDF;
            case SettlementDocument.SettlementAgreement:
                return downloadPDF;
            case SettlementDocument.SettlementOrder:
                return downloadPDF;
            case SettlementDocument.SettlementApplication:
                return downloadPDF;
            case SettlementDocument.SettlementApplicationPrivileges:
                return downloadDOC;
        }
    }
}
