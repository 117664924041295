import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UserDetails } from "../../shared/http-services/users/models/user-details.model";
import { LocalizedMessage } from "../../shared/models/privilege-status.model";
import { UserRolesEditorInterface } from "../user-roles-editor/models/user-roles-editor.interface";
import { UserRolesEditorComponent } from "../user-roles-editor/user-roles-editor.component";
import { UserRolesCardService } from "./services/user-roles-card.service";

@Component({
    selector: "app-user-roles-card",
    templateUrl: "user-roles-card.component.html",
    styleUrls: ["user-roles-card.component.scss"],
    providers: [
        UserRolesCardService,
    ]
})
export class UserRolesCardComponent {

    @ViewChild(UserRolesEditorComponent)
    public editor: UserRolesEditorInterface;

    @Input()
    public userDetails: UserDetails;

    @Output()
    public rolesUpdated: EventEmitter<void>;

    public roleOptions: LocalizedMessage[];

    constructor(
        private userRolesCardService: UserRolesCardService,
    ) {
        this.rolesUpdated = new EventEmitter<void>();

        this.loadRoleOptions();
    }

    public onEdit()
    : void {
        this.editor.open(this.userDetails);
    }

    public onRolesUpdated()
    : void {
        this.rolesUpdated.emit();
    }

    private async loadRoleOptions()
    : Promise<void> {
        this.roleOptions = await this.userRolesCardService.loadRoleOptions();
    }
}
